import React, { useContext, useEffect } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { ReactComponent as InsightsMenuIcon } from './MenuIcons/InsightsMenuIcon.svg';
import { ReactComponent as MySessionsMenuIcon } from './MenuIcons/MySessionsMenuIcon.svg';
import { ReactComponent as LogoutMenuIcon } from './MenuIcons/LogoutMenuIcon.svg';
import { ReactComponent as ProfilePicMenuIcon } from './MenuIcons/ProfilePicMenuIcon.svg';
import { ReactComponent as MapExploreIcon } from './MenuIcons/MapMenuIcon.svg';
import { ReactComponent as FeedbackIcon } from './MenuIcons/FeedbackMenuIcon.svg';
import { ReactComponent as DashboardIcon } from '../../ReusableComponents/Icons/DashboardIcon.svg';
import WhiteEmblem from '../../ReusableComponents/Logos/EmblemBeta.svg';
import { supabase } from '../../Auth/SupabaseAuth/SupabaseClient';
import { SessionContext } from '../../Auth/UserAuth/SessionContext';
import { UserContext } from '../../Auth/UserAuth/UserContext';
import { MapSessionContext } from '../../Auth/UserAuth/MapSessionContext';
import { useDemo } from '../../Auth/UserAuth/DemoContext';

import './SidePanel.css';

const SidePanel = () => {
    const { handleMenuItemClick, clearDocIntelSession } = useContext(SessionContext);
    const { clearMapSession } = useContext(MapSessionContext);
    const { avatarURL } = useContext(UserContext);
    const { isDemo, setIsDemo } = useDemo();
    const location = useLocation();
    const navigate = useNavigate();

    const isActive = (path) => {
        return location.pathname === path;
    };

   

  
    const handleLogoutClick = async (event) => {
    try {
        // Prevent any default action if this is tied to a button or link
        if (event) {
            event.preventDefault();
            event.stopPropagation();
        }

        // Debugging: Log to check if the function is being triggered
        console.log('Logout button clicked, starting logout process...');

        // Execute the logout process
        const { error } = await supabase.auth.signOut();
        if (error) throw error;

        // Clear session storage and other local data
        sessionStorage.clear();
        localStorage.removeItem('sessionId');

        clearMapSession();
        clearDocIntelSession();

        // Debugging: Confirm logout has processed
        console.log('Logout successful, navigating to login page...');

        // Navigate to the login page
        navigate('/login');

        // Force a reload after navigating to ensure a fresh session
        setTimeout(() => {
            window.location.reload();
        }, 100);

    } catch (error) {
        console.error('Error logging out:', error.message);
        alert('There was an issue logging you out. Please try again.');
    }
};



   /* backup logout
    const handleLogoutClick = async () => {
        try {
            const { error } = await supabase.auth.signOut();
            if (error) throw error;

            sessionStorage.clear();
            localStorage.removeItem('sessionId');

            clearMapSession();
            clearDocIntelSession();

            navigate('/login');
        } catch (error) {
            console.error('Error logging out:', error.message);
        }
    };
  */





    const handleProfileClick = () => {
        handleMenuItemClick('Profile');
        navigate('/dashboard/account');
    };

    function ProfileAvatar({ avatarURL }) {
        return (
            avatarURL ?
                <img src={avatarURL} alt="Profile Avatar" className="menu-icon profile-icon user-uploaded-avatar" />
                :
                <ProfilePicMenuIcon className="menu-icon profile-icon default-profile-icon" />
        );
    }

    // Check if the current route should enable demo mode
    useEffect(() => {
        if (location.pathname.includes('/doc-intel-demo')) {
            setIsDemo(true);
            localStorage.setItem('previousPath', '/doc-intel-demo');
        } else {
            const previousPath = localStorage.getItem('previousPath');
            if (previousPath === '/doc-intel-demo') {
                setIsDemo(true);
            } else {
                setIsDemo(false);
            }
        }
    }, [location, setIsDemo]);

    return (
        <div className="side-panel">
            <img src={WhiteEmblem} alt="Logo" className="side-panel-logo" />

            <div className="first-menu-container">
                {/* Conditional rendering based on demo mode */}
                {!isDemo && (
                  
                  
                  <div className={`menu-item ${isActive('/dashboard') ? 'menu-item-active' : ''}`} onClick={() => handleMenuItemClick('Dashboard')}>
                        <Link to="/dashboard">
                            <DashboardIcon className="dashboard-menu-icon" />
                        </Link>
                    </div>

                    
                )}

                <div className="menu-group">
                    <div className={`menu-item ${isActive('/doc-intel') || isActive('/doc-intel-demo') ? 'menu-item-active' : ''}`} onClick={() => handleMenuItemClick('Insights')}>
                        <Link to={isDemo ? "/doc-intel-demo" : "/doc-intel"}>
                            <InsightsMenuIcon />
                        </Link>
                    </div>
                </div>

                {/* Conditional rendering for other menu items based on demo mode */}
                {!isDemo && (
                    <>
                      

                        <div className={`menu-item ${isActive('/my-sessions') ? 'menu-item-active' : ''}`} onClick={() => handleMenuItemClick('Sessions')}>
                            <Link to="/my-sessions">
                                <MySessionsMenuIcon />
                            </Link>
                        </div>
                    </>
                )}
            </div>

            <div className="menu-spacer"></div>

            {/* Conditional rendering based on demo mode */}
            {!isDemo && (
                <>
                    <div className="second-menu-container">
                        <div className={`menu-item ${isActive('/dashboard/account') ? 'menu-item-active' : ''}`} onClick={handleProfileClick}>
                            <ProfileAvatar avatarURL={avatarURL} />
                        </div>

                        <div className="menu-group">
                            <div className={`menu-item ${isActive('/dashboard/feedback') ? 'menu-item-active' : ''}`} onClick={() => handleMenuItemClick('Feedback')}>
                                <Link to="/dashboard/feedback">
                                    <FeedbackIcon />
                                </Link>
                            </div>

                            <div className="menu-item" onClick={handleLogoutClick}>
                                <LogoutMenuIcon />
                            </div>
                        </div>
                    </div>
                </>
            )}
        </div>
    );
};

export default SidePanel;



/* Epxlore Icon
  <div className={`menu-item ${isActive('/explore') ? 'menu-item-active' : ''}`} onClick={() => handleMenuItemClick('MapExplore')}>
                            <Link to="/explore">
                                <MapExploreIcon />
                            </Link>
                        </div>
*/