

import { useEffect, useState } from 'react';
import { supabase } from '../../Auth/SupabaseAuth/SupabaseClient';
import { useNavigate } from 'react-router-dom';
import logo from '../../ReusableComponents/Logo.svg';
import Button from '../../ReusableComponents/Buttons/Button';
import './PasswordReset.css';

function PasswordReset() {
    const [newPassword, setNewPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const navigate = useNavigate();





    useEffect(() => {
        const params = new URLSearchParams(window.location.search);
        if (!params.has('type') || params.get('type') !== 'recovery') {
            alert('Invalid password reset link.');
            navigate('/login');
        }
    }, [navigate]);


const handlePasswordChange = async () => {
    // 1. Validation:
    if (!newPassword || !confirmPassword) {
        alert("Please fill out both password fields.");
        return;
    }

    if (newPassword !== confirmPassword) {
        alert("Passwords do not match. Please try again.");
        return;
    }

    // Retrieve access_token from URL
    const params = new URLSearchParams(window.location.search);
    const access_token = params.get('access_token');

    if (!access_token) {
        alert("Invalid password reset link.");
        return;
    }

     

    // 2. Send the password to the server:
    try {
        const { data, error } = await supabase.auth.api.updateUser(access_token, { password: newPassword });
        if (error) {
            throw error;
        }

        // 3. Provide feedback to the user:
        if (data) {
            alert("Password updated successfully! Please login with your new password.");
            navigate('/login');
        }
    } catch (error) {
        alert(`Failed to update password: ${error.message}`);
    }
}





    return (
        <div className="password-reset-page">
            <img src={logo} alt="Logo" className="password-reset-logo" />

            <p className="password-reset-instructions">
                Please enter your new password below. After your password is changed successfully, you will be diverted back to the login page.
            </p>

            <div className="password-reset-form">
                <label className="password-reset-input-label">
                    Enter new password
                    <input
                        className="password-reset-input-field"
                        type="password"
                        placeholder="Enter new password"
                        value={newPassword}
                        onChange={(e) => setNewPassword(e.target.value)}
                    />
                </label>
                <label className="password-reset-input-label">
                    Confirm new password
                    <input
                        className="password-reset-input-field"
                        type="password"
                        placeholder="Confirm new password"
                        value={confirmPassword}
                        onChange={(e) => setConfirmPassword(e.target.value)}
                    />
                </label>
                <Button text="Confirm" className="password-reset-confirm-button" onClick={handlePasswordChange} />
            </div>
        </div>
    );
}

export default PasswordReset;















