import React, { useState, useEffect, useContext } from 'react';
import './DashboardHome.css';
import IntroModal from '../../Auth/Onboarding/MainIntroModal';
import { supabase } from '../../Auth/SupabaseAuth/SupabaseClient';
import { UserContext } from '../../Auth/UserAuth/UserContext';
import FileUploadWidget from './Widgets/FileUploadWidget';
import RecentSessionsWidget from './Widgets/RecentSessionsWidget';
import DraftEmailAI from './Widgets/DraftEmailAI';
import StatsInsightsWidget from './Widgets/StatsInsights';
import { Upload, Map, Settings, Clock, Mail, BarChart2 } from 'lucide-react';
import VesselMapComingSoon from './Widgets/VesselMapWidget';
import AccountStatus from './Widgets/AccountStatusWidget';

function DashboardHome({ isModalOpen, closeModal }) {
    const [firstName, setFirstName] = useState(localStorage.getItem('firstName') || '');
    const [accountType, setAccountType] = useState('');
    const [lastPayment, setLastPayment] = useState('');
    const [dateJoined, setDateJoined] = useState('');
    const [statsData, setStatsData] = useState({
        sessions: 0,
        emails: 0,
        exports: 0
    });
    const { session } = useContext(UserContext);

    useEffect(() => {
        if (session) {
            async function fetchUserData() {
                const { data, error } = await supabase
                    .from('userprofiles')
                    .select('first_name, account_type, last_payment, created_at')
                    .eq('user_id', session.user.id)
                    .single();

                if (error) {
                    console.error('Error fetching user data:', error);
                } else {
                    setFirstName(data.first_name);
                    setAccountType(data.account_type || 'Standard');
                    setLastPayment(data.last_payment || 'N/A');
                    setDateJoined(new Date(data.created_at).toLocaleDateString());
                    localStorage.setItem('firstName', data.first_name);
                }
            }

            async function fetchStatsData() {
                // Replace this with actual API call to fetch stats data
                const mockData = {
                    sessions: 125,
                    emails: 47,
                    exports: 83
                };
                setStatsData(mockData);
            }

            fetchUserData();
            fetchStatsData();
        }
    }, [session]);

    const getGreeting = () => {
        const currentHour = new Date().getHours();
        let greeting = '';
        if (currentHour < 12) {
            greeting = `Good morning`;
        } else if (currentHour < 18) {
            greeting = `Good afternoon`;
        } else {
            greeting = `Good evening`;
        }
        return `${greeting}, ${firstName}`;
    };

    return (
        <div className="dashboard-home-container">
            <h1 className="greeting-style">{getGreeting()}</h1>
            <div className="dashboard-widgets">
                <div className="widget">
                    <h2><Upload className="widget-icon" /> Start a new Session</h2>
                    <p>Upload to auto-categorise, extract and get instant insights.</p>
                    <FileUploadWidget />
                </div>

                  <div className="widget">
                    <h2><Mail className="widget-icon" /> Generate an AI Email with Context</h2>
                    <p>Give us the context, and we'll craft a ready-to-send email in seconds.</p>
                    <DraftEmailAI />
                </div>

               
                <div className="widget account-status">
                    <h2><Settings className="widget-icon" /> Check your Account Status</h2>
                    <AccountStatus />
                 
                </div>
            </div>
            <div className="dashboard-widgets">
                <div className="widget">
                    <h2><Clock className="widget-icon" /> Access Recent Sessions</h2>
                    <p>Restore your recent sessions. Click a session to restore.</p>
                    <RecentSessionsWidget />
                </div>

             
              
                <div className="widget">
                      <h2><BarChart2 className="widget-icon" />   Stats & Insights</h2>
                     
      
         <p>View your weekly usage stats. Data is not currently live.</p>
                    
                    <StatsInsightsWidget 
                    
                        sessions={statsData.sessions}
                        emails={statsData.emails}
                        exports={statsData.exports}
                    />
                    
                </div>

                    <div className="widget">
                    <h2><Map className="widget-icon" /> Vessel & Map Activity</h2>
                    <p>Overview of latest map interactions or sessions.</p>
                     <VesselMapComingSoon />

                </div>
            </div>
            <IntroModal isOpen={isModalOpen} onClose={closeModal} />
        </div>
    );
}

export default DashboardHome;