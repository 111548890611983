import React from 'react';
import UserAvatarDude from '../../ReusableComponents/Icons/UserAvatarDude.svg';
import './PersonaIcon.css';

const PersonaIcon = ({ persona }) => {
    const personaStyles = {
        "ship broker": { backgroundColor: '#DEF8EE', color: '#00311F' },
        "compliance officer": { backgroundColor: '#DEF8EE', color: '#00311F' },
        "operations manager": { backgroundColor: '#DEF8EE', color: '#00311F' },
        "trader": { backgroundColor: '#DEF8EE', color: '#00311F' },
        "freight forwarder": { backgroundColor: '#DEF8EE', color: '#00311F' },
        "analyst": { backgroundColor: '#DEF8EE', color: '#00311F' },
        "general": { backgroundColor: '#DEF8EE', color: '#00311F' },
        "personal": { backgroundColor: '#DEF8EE', color: '#00311F' },
    };

    const iconStyles = personaStyles[persona] || personaStyles['general'];

    // Capitalize the first letter of each word
    const capitalizeWords = (str) => {
        return str.replace(/_/g, ' ').replace(/\b\w/g, char => char.toUpperCase());
    };

    return (
        <div className="persona-icon" style={iconStyles}>
            <img src={UserAvatarDude} alt={persona} />
            <span className="persona-text">{capitalizeWords(persona)}</span>
        </div>
    );
};

export default PersonaIcon;
