
import { WelcomeEmail } from './WelcomeEmail';
import { render } from '@react-email/render';
import axios from 'axios'; 


export const triggerWelcomeEmail = async (email, accessToken) => {
    //console.log("triggerWelcomeEmail called"); 
    //console.log(`Received email argument as: ${email}`);
    //console.log(`Received accessToken argument as: ${accessToken}`)
    
    const html = render(<WelcomeEmail />, {
        pretty: true,
    });

    try {
        await axios.post('http://localhost:5001/triggerWelcomeEmail', 
            { email, html },
            {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${accessToken}`
                }
            }
        );
        console.log("Welcome email sent successfully");
    } catch (error) {
        console.error("Failed to send welcome email:", error);
    }
};
