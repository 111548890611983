import React, { useState, useContext } from 'react';
import { ReactComponent as BusinessIcon } from '../../ReusableComponents/Icons/BusinessUser.svg';
import { ReactComponent as PersonalIcon } from '../../ReusableComponents/Icons/PersonalUser.svg';
import Button from '../../ReusableComponents/Buttons/Button';
import { supabase } from '../../Auth/SupabaseAuth/SupabaseClient';
import { UserContext } from '../../Auth/UserAuth/UserContext';
import { trackEvent } from '../UserAnalytics/MixPanelConfig';
import WhiteLogo from '../../ReusableComponents/Logos/Logo.svg';
import './SharedModalStyles.css'; // Import the shared styles
import './OnboardingStep1.css';

const OnboardingStep1 = ({ onNext }) => {
    const [isLoading, setLoading] = useState(false);
    const { session, userType, setUserType } = useContext(UserContext);

    const handleSelectBusiness = () => {
        console.log("Selected Business");
        setUserType('business');
    };

    const handleSelectPersonal = () => {
        console.log("Selected Personal");
        setUserType('personal');
    };

    const handleNext = async () => {
        setLoading(true);
        console.log("Handle Next clicked, loading state set to true");

        if (userType && session) {
            const user = session.user;
            console.log("User type and session available", { userType, user });

            try {
                const { data: existingProfiles, error: existingError } = await supabase
                    .from('userprofiles')
                    .select('id')
                    .eq('user_id', user.id);

                console.log("Supabase select result:", { existingProfiles, existingError });

                if (existingError) {
                    console.error('Error fetching existing profiles:', existingError);
                    setLoading(false);
                    console.log("Loading state set to false due to error fetching profiles");
                    return;
                }

                console.log("Existing profiles fetched", existingProfiles);

                if (existingProfiles.length > 0) {
                    console.log("Existing profile found, updating profile");
                    const { error: updateError } = await supabase
                        .from('userprofiles')
                        .update({ user_type: userType })
                        .eq('user_id', user.id);

                    console.log("Supabase update result:", updateError);

                    if (!updateError) {
                        console.log("Profile updated successfully");
                        trackEvent('Onboarding_Step1_Completed', {
                            userId: user.id,
                            email: user.email,
                            userType: userType,
                        });
                    } else {
                        console.error('Error updating user profile:', updateError);
                    }
                } else {
                    console.log("No existing profile found, inserting new profile");
                    const { error: insertError } = await supabase
                        .from('userprofiles')
                        .insert([{ user_id: user.id, user_type: userType }]);

                    console.log("Supabase insert result:", insertError);

                    if (!insertError) {
                        console.log("Profile inserted successfully");
                        trackEvent('Onboarding_Step1_Completed', {
                            userId: user.id,
                            email: user.email,
                            userType: userType,
                        });
                    } else {
                        console.error('Error inserting new user profile:', insertError);
                    }
                }

                console.log("Moving to the next step");
                onNext();
            } catch (error) {
                console.error('Unexpected error:', error);
            }
        } else {
            console.log('User type not selected or session not available.');
        }

        setLoading(false);
        console.log("Loading state set to false after handling next");
    };

    return (
        <div className="onboarding-full-page-overlay">
            <div className="onboarding-modal-box">
                <img src={WhiteLogo} alt="Logo" className="onboarding-modal-logo" />
                <div className="onboarding-modal-title">How do you intend to use Comovis?</div>
                <div className="onboarding-step-1-container">
                    <div className="onboarding-step-1-selections">
                        <div className={`selection-container ${userType === 'business' ? 'selected' : ''}`} onClick={handleSelectBusiness}>
                            <BusinessIcon />
                            <h3>Business</h3>
                            <p>Access to business specific AI analytical features, user-persona focused, security & more</p>
                        </div>
                        <div className={`selection-container ${userType === 'personal' ? 'selected' : ''}`} onClick={handleSelectPersonal}>
                            <PersonalIcon />
                            <h3>Personal</h3>
                            <p>Perfect for personal solo projects, ad-hoc research & general document related tasks</p>
                        </div>
                    </div>
                    <div className="onboarding-step-1-button">
                        <Button text="Next" onClick={handleNext} isLoading={isLoading} />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default OnboardingStep1;
