export const formatText = (text, notes) => {
    // Apply bold formatting
    let formattedText = text.replace(/\*\*(.*?)\*\*/g, '<strong>$1</strong>');

    // Apply heading formatting for #, ##, ### levels
    formattedText = formattedText.replace(/^# (.*?)$/gm, '<h1>$1</h1>');
    formattedText = formattedText.replace(/^## (.*?)$/gm, '<h2>$1</h2>');
    formattedText = formattedText.replace(/^### (\d+\.\s*.*)$/gm, '<h3>$1</h3>');
    formattedText = formattedText.replace(/^### (.*?)$/gm, '<h3>$1</h3>');

    // Handle page references of the format (pg 2, 12, 14)
    formattedText = formattedText.replace(/\(pg (\d+(?:, \d+)*)\)/g, (match, p1) => {
        const pages = p1.split(', ').map(page => `<span class="page-number" data-page="${page}">${page}</span>`).join(', ');
        return `(${pages})`;
    });

    // Handle individual page references of the format (pg 2)
    formattedText = formattedText.replace(/\(pg\s*(\d+)\)/g, (match, p1) => {
        return `<span class="page-number" data-page="${p1}">${p1}</span>`;
    });

    // Handle references of the format pg 2, 5, 9, 14, 20
    formattedText = formattedText.replace(/pg (\d+(?:, \d+)*)/g, (match, p1) => {
        const pages = p1.split(', ').map(page => `<span class="page-number" data-page="${page}">${page}</span>`).join(', ');
        return pages;
    });

    // Handle references of the format pg: 2, 4, 7, 10, 12, 15, 20, 21, 22
    formattedText = formattedText.replace(/pg:\s*(\d+(?:, \d+)*)/g, (match, p1) => {
        const pages = p1.split(', ').map(page => `<span class="page-number" data-page="${page}">${page}</span>`).join(', ');
        return pages;
    });



    

    // Highlight the text that has notes attached
    notes.forEach(note => {
        // Escape special characters in the quoted text for regex safety
        const escapedText = note.quoted_text.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');

        // Build the regex to match the quoted text exactly
        const regex = new RegExp(escapedText, 'g');

        // Ensure that the quoted text is not split across multiple lines
        const checkText = text.replace(/\n/g, ' '); // Remove newlines from the input text

        if (checkText.includes(note.quoted_text)) {
            formattedText = formattedText.replace(regex, `<span class="highlighted-text" data-note-id="${note.notesMessageId}">${note.quoted_text}</span>`);
        }
    });





    // Adjust the paragraph splitting logic based on the new approach
    // Split on double newlines or specific bullet points/numbers
    const paragraphs = formattedText.split(/\n\n|\d+\.\s/).map(paragraph => paragraph.trim());

    return paragraphs.map((paragraph, index) => (
        <p key={index} dangerouslySetInnerHTML={{ __html: paragraph }} />
    ));
};



