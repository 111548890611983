//Login Page

import React from 'react';
import logo from '../../ReusableComponents/Logo.svg';
import LoginFormField from './LoginFormField';

import './LoginPage.css';

function LoginPage() {
    return (
        <div className="login-page">
            <img src={logo} alt="Logo" className="logo" />
            <LoginFormField />
           
            
            
        </div>
    );
}

export default LoginPage;




