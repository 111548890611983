import React, { useEffect, useRef } from 'react';
import L from 'leaflet';
import { useMap } from 'react-leaflet';
import vesselIconUrl from '../../../ReusableComponents/Icons/Map/DefaultVesselIcon.svg';


//This version works but Verceld oe snot allow it, figure a soluton. import vesselIconUrl from '/Applications/Comovis/frontend/src/ReusableComponents/Icons/Map/DefaultVesselIcon.svg';

const RotatingMarker = ({ position, angle, popupContent }) => {
    const markerRef = useRef(null);
    const map = useMap();

    useEffect(() => {
        // Update rotation
        if (markerRef.current) {
            const iconElement = markerRef.current._icon.querySelector('img');
            if (iconElement) {
                iconElement.style.transform = `rotate(${angle}deg)`;
                iconElement.style.transformOrigin = 'center center';
            }
        }
    }, [angle]);

    useEffect(() => {
        
        // Handle adding or updating the marker
        if (position && Array.isArray(position) && position.length === 2) {
            if (markerRef.current) {
                markerRef.current.setLatLng(position);
            } else {
                const icon = L.divIcon({
                    className: 'custom-icon',
                    html: `<img src="${vesselIconUrl}" style="width: 100%; height: 100%;" />`,
                    iconSize: [12, 15],
                    iconAnchor: [6, 7.5]
                });

                markerRef.current = L.marker(position, { icon }).addTo(map);
                if (popupContent) {
                    markerRef.current.bindPopup(popupContent);
                }
            }
        } else if (markerRef.current) {
            // Explicitly handle removal if position is invalid
          
            map.removeLayer(markerRef.current);
            markerRef.current = null;
        }
    }, [map, position, popupContent]);

    return null;
};

export default RotatingMarker;
