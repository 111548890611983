import React, { createContext, useState, useEffect } from 'react';

export const ChatContext = createContext();

export function ChatProvider({ children }) {
    const [messages, setMessages] = useState(() => {
        const savedMessages = sessionStorage.getItem('messages');
        return savedMessages ? JSON.parse(savedMessages) : [];
    });

    const [inputText, setInputText] = useState('');
    const [isEmailPrompt, setIsEmailPrompt] = useState(false);  // New state for email prompt flag

    const [showIntro, setShowIntro] = useState(() => {
        const savedShowIntro = localStorage.getItem('showIntroModal');
        return savedShowIntro !== 'false';
    });

    useEffect(() => {
        sessionStorage.setItem('messages', JSON.stringify(messages));
    }, [messages]);

    useEffect(() => {
        localStorage.setItem('showIntroModal', JSON.stringify(showIntro));
    }, [showIntro]);

    return (
        <ChatContext.Provider value={{ messages, setMessages, inputText, setInputText, isEmailPrompt, setIsEmailPrompt, showIntro, setShowIntro }}>
            {children}
        </ChatContext.Provider>
    );
}
