import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useDocument } from '../../Auth/UserAuth/DocumentContext';
import Button from '../../ReusableComponents/Buttons/Button'; 
import ChatCircleWhiteIcon from '../../ReusableComponents/Icons/ChatCircleWhite.svg'; 
import ExtractedDataIcon from '../../ReusableComponents/Icons/ExtractedData.svg'; 
import './ChatPanelHeaderBar.css'; 

function ChatPanelHeaderBar({ isBolPanelOpen, forceShowChat }) {  // Add a new prop: forceShowChat
    const { showProcessedDocPanel, setShowProcessedDocPanel, bolDetails } = useDocument();
    const [scrolled, setScrolled] = useState(false);

    useEffect(() => {
        if (forceShowChat) {
            setShowProcessedDocPanel(false);  // Force show Chat & Insights if prop is true
        }
    }, [forceShowChat]);

    const toggleDocVisibility = () => {
        setShowProcessedDocPanel(prev => !prev);
    };

    if (!bolDetails) {
        return null; // Do not render if bolDetails is not available
    }

    return (
        <div className={`chat-panel-header-bar ${isBolPanelOpen ? 'bol-panel-open' : ''} ${scrolled ? 'scrolled' : ''}`}>
            <Button
                text={showProcessedDocPanel ? (
                    <>
                        <img src={ChatCircleWhiteIcon} alt="Chat & Insights Icon" className="BOL-button-icon" />
                        Chat & Insights
                    </>
                ) : (
                    <>
                        <img src={ExtractedDataIcon} alt="Show Extracted Data Icon" className="BOL-button-icon" />
                        Show Extracted Data
                    </>
                )}
                onClick={toggleDocVisibility}
                color="black"
                className="toggle-doc-visibility-btn"
                isLoading={false}
                size="small"
            />
        </div>
    );
}

ChatPanelHeaderBar.propTypes = {
    isBolPanelOpen: PropTypes.bool,
    forceShowChat: PropTypes.bool  // Define the prop type
};

ChatPanelHeaderBar.defaultProps = {
    isBolPanelOpen: false,
    forceShowChat: false  // Default to false
};

export default ChatPanelHeaderBar;
