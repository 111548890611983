import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import './SubtleModal.css';
import { ReactComponent as SparklesIcon } from '../../../ReusableComponents/Icons/SparklesIcon.svg'
const SubtleModal = ({ isVisible, title, content, onClose }) => {
    useEffect(() => {
        if (isVisible) {
            const timer = setTimeout(() => {
                onClose();
            }, 5000); // Close modal after 5 seconds

            return () => clearTimeout(timer); // Cleanup timeout if the component unmounts
        }
    }, [isVisible, onClose]);

    if (!isVisible) return null;

    return (
        <div className="subtle-modal">
            <span className="subtle-modal-close-text" onClick={onClose}>X</span> {/* Plain "X" */}
            <div className="subtle-modal-header">
                <SparklesIcon className="subtle-modal-icon" />
                <h3 className="subtle-modal-title">{title}</h3>
            </div>
            <div className="subtle-modal-content">
                {content}
            </div>
        </div>
    );
};

SubtleModal.propTypes = {
    isVisible: PropTypes.bool.isRequired,
    title: PropTypes.string.isRequired,
    content: PropTypes.string.isRequired,
    onClose: PropTypes.func.isRequired,
};

export default SubtleModal;
