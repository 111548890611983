import React, { createContext, useState, useEffect } from 'react';
import { v4 as uuidv4 } from 'uuid';
import NoteModal from '../../Components/TextInteraction/NotesModal/NotesModal';

const NotesContext = createContext();

export const NotesProvider = ({ children }) => {
    const [isNoteModalOpen, setNoteModalOpen] = useState(false);
    const [currentText, setCurrentText] = useState('');
    const [quotedText, setQuotedText] = useState('');
    const [notes, setNotes] = useState([]);
    const [modalPosition, setModalPosition] = useState({ top: '50%', left: '50%' });
    const [currentNote, setCurrentNote] = useState(null);
    const [notesMessageId, setNotesMessageId] = useState(null);

    useEffect(() => {
        const savedNotes = sessionStorage.getItem('sessionNotes');
        if (savedNotes) {
            setNotes(JSON.parse(savedNotes));
        }
    }, []);

    useEffect(() => {
        if (notes.length > 0) {
            sessionStorage.setItem('sessionNotes', JSON.stringify(notes));
        }
    }, [notes]);

    const openNoteModal = (text, position = { top: '50%', left: '50%' }, quote, noteId = null) => {
        setCurrentText(text);
        setModalPosition(position);

        if (noteId) {
            const noteToEdit = notes.find(note => note.notesMessageId === noteId);
            setCurrentNote(noteToEdit || null);
            setNotesMessageId(noteId);
            setQuotedText(noteToEdit?.quoted_text || quote || '');
        } else {
            const newNotesMessageId = uuidv4();
            setNotesMessageId(newNotesMessageId);
            setCurrentNote(null);
            setQuotedText(quote || '');
        }
        setNoteModalOpen(true);
    };

    const closeNoteModal = () => {
        setNoteModalOpen(false);
        setCurrentText('');
        setQuotedText('');
        setCurrentNote(null);
        setNotesMessageId(null);
    };



    const saveNoteToState = (newNote) => {
    //console.log('saveNoteToState called with newNote:', newNote);
    
    setNotes(prevNotes => {
        // Check if the note already exists in the state
        const noteExists = prevNotes.some(n => n.notesMessageId === newNote.notesMessageId);

        if (noteExists) {
            //console.log('Note with this ID already exists. Checking for changes...');
            // If the note exists, we should check if it's the same or needs updating
            const existingNote = prevNotes.find(n => n.notesMessageId === newNote.notesMessageId);

            // If the content is different, update the note
            if (existingNote.quoted_text !== newNote.quoted_text || existingNote.note !== newNote.note) {
                //console.log('Note content has changed. Updating state.');
                const updatedNotes = prevNotes.map(n => 
                    n.notesMessageId === newNote.notesMessageId ? newNote : n
                );
                //console.log('Updated notes array:', updatedNotes);
                return updatedNotes;
            } else {
                //console.log('Note content is unchanged. No state update needed.');
                return prevNotes;
            }
        } else {
            //console.log('New note detected. Adding to state.');
            // If the note is new, add it to the state
            const newNotesArray = [...prevNotes, newNote];
            //console.log('Updated notes array:', newNotesArray);
            return newNotesArray;
        }
    });
};





    return (
        <NotesContext.Provider value={{ 
            isNoteModalOpen, 
            openNoteModal, 
            closeNoteModal, 
            notes, 
            currentNote, 
            quotedText, 
            notesMessageId,
            saveNoteToState
        }}>
            {children}
            {isNoteModalOpen && <NoteModal top={modalPosition.top} left={modalPosition.left} />}
        </NotesContext.Provider>
    );
};

export { NotesContext };
