import React from 'react';
import './ExportModal.css';

import { ReactComponent as ExportPDFIcon } from '../../ReusableComponents/Icons/ExportPDF.svg';
import { ReactComponent as ExportExcelIcon } from '../../ReusableComponents/Icons/ExportExcel.svg';
import { ReactComponent as ExportJSONIcon } from '../../ReusableComponents/Icons/ExportJSON.svg';
import { ReactComponent as ExportCloudIcon } from '../../ReusableComponents/Icons/ExportCloud.svg';
import { ReactComponent as ExportERPIcon } from '../../ReusableComponents/Icons/ExportERP.svg';
import { ReactComponent as ExportCRMIcon } from '../../ReusableComponents/Icons/ExportCRM.svg';
import { ReactComponent as ExportShareURLIcon } from '../../ReusableComponents/Icons/ExportShareURL.svg';

import Button from '../../ReusableComponents/Buttons/Button';  // Import the reusable Button component

const ExportModal = ({ isOpen, onClose, onExportPDF, onExportCSV, onExportXLSX, onExportJSON, onExportXML }) => {
    if (!isOpen) return null;

    return (
        <div className="export-modal-overlay" onClick={onClose}>
            <div className="export-modal-content" onClick={e => e.stopPropagation()}>
                <h2 className="export-modal-title">Export Options</h2>
                <ul className="export-options-list">
                    <li className="export-option-item" onClick={onExportPDF}>
                        <ExportPDFIcon className="export-option-icon" />
                        Export to PDF
                    </li>
                    <li className="export-option-item export-option-has-submenu">
                        <ExportExcelIcon className="export-option-icon" />
                        Export to Excel/CSV
                        <ul className="export-submenu">
                            <li className="export-submenu-item" onClick={onExportCSV}>
                                CSV
                            </li>
                            <li className="export-submenu-item" onClick={onExportXLSX}>
                                XLSX
                            </li>
                            <li className="export-submenu-item">
                                Google Sheets (Coming Soon)
                            </li>
                        </ul>
                    </li>
                    <li className="export-option-item export-option-has-submenu">
                        <ExportJSONIcon className="export-option-icon" />
                        Export to JSON/XML
                        <ul className="export-submenu">
                            <li className="export-submenu-item" onClick={onExportJSON}>
                                JSON
                            </li>
                            <li className="export-submenu-item" onClick={onExportXML}>
                                XML
                            </li>
                        </ul>
                    </li>
                    <li className="export-option-item export-option-has-submenu">
                        <ExportCloudIcon className="export-option-icon" />
                        Export to Cloud Storage
                        <ul className="export-submenu">
                            <li className="export-submenu-item" onClick={() => console.log('Export to Google Drive')}>
                                Google Drive (Coming Soon)
                            </li>
                            <li className="export-submenu-item" onClick={() => console.log('Export to Dropbox')}>
                                Dropbox (Coming Soon)
                            </li>
                            <li className="export-submenu-item" onClick={() => console.log('Export to OneDrive')}>
                                OneDrive (Coming Soon)
                            </li>
                        </ul>
                    </li>
                    <li className="export-option-item" onClick={() => console.log('Export to ERP')}>
                        <ExportERPIcon className="export-option-icon" />
                        Export to ERP
                    </li>
                    <li className="export-option-item" onClick={() => console.log('Export to CRM')}>
                        <ExportCRMIcon className="export-option-icon" />
                        Export to CRM
                    </li>
                    <li className="export-option-item" onClick={() => console.log('Share as URL')}>
                        <ExportShareURLIcon className="export-option-icon" />
                        Share as URL (Coming Soon)
                    </li>
                </ul>
                <Button
                    text="Close"
                    color="black" // Assuming 'primary' is a color option you have in your button styles
                    size="small"
                    onClick={onClose}
                    className="export-modal-close-button"  // You can add this class for any specific styling
                />
            </div>
        </div>
    );
};

export default ExportModal;
