import React, { useContext } from 'react';
import { UserContext } from '../../Auth/UserAuth/UserContext';
import './UpgradePlanPage.css';
import { ReactComponent as UpgradePlanTick } from './UpgradePlanTick.svg';
import { ReactComponent as PremiumConfirmation } from './PremiumConfirmation.svg';
import { ReactComponent as FreeConfirmation } from './FreeConfirmation.svg';
import { initiateUpgrade } from './PaymentsLogic/InitiateUpgrade';
import Button from '../../ReusableComponents/Buttons/Button';

const UpgradePlanPage = () => {
    const { planName, session } = useContext(UserContext);

    const handleUpgradeClick = () => {
        if (session && session.user) {
            const userId = session.user.id;
            initiateUpgrade(userId, session);
        } else {
            console.error('User session not available');
        }
    };

    return (
        <div className="upgrade-plan-main-container">
            <div className="upgrade-plan-header-container">
                <h1>Upgrade Your Plan</h1>
            </div>
            <div className="upgrade-plan-listing-container">
                <div className="upgrade-plan-single">
                    <h2>Free</h2>
                    <p>*Free for 14 days. Try Comovis to turbocharge your workflow.</p>
                    <div className="price-display">
                        <div className="price-circle">
                            £0
                        </div>
                        <div className="price-period">
                            per month, per user
                        </div>
                    </div>
                    <ul className="custom-list">
                        <li><UpgradePlanTick /> <strong>Limited Insights:&nbsp;</strong> 7 chat insights per week. Renews weekly.</li>
                        <li><UpgradePlanTick /> <strong>Auto-Queries:&nbsp;</strong> Questions to guide your analysis into each document.</li>
                        <li><UpgradePlanTick /> <strong>Limited Document Uploads:&nbsp;</strong> One document upload per week.</li>
                        <li><UpgradePlanTick /> <strong>Advanced Persona:&nbsp;</strong> AI models for targeted role-based insights.</li>
                        <li><UpgradePlanTick /> <strong>Secure Cloud:&nbsp;</strong> Instant retrieval of stored documents and chat insights.</li>
                        <li><UpgradePlanTick /> <strong>Basic Support:&nbsp;</strong> Email support and documentation assistance.</li>
                        <li><UpgradePlanTick /> <strong>Security & Privacy:&nbsp;</strong> EU data centres and GDPR compliance.</li>
                        <p>*Core features will be limited after 14 days.</p>
                        <p>After the trial ends, you'll be limited to one session and 7 chat messages every 7 days.</p>
                    </ul>
                    <div className="upgrade-page-buttons-container">
                        {planName === 'Trial' ? (
                            <FreeConfirmation className="free-confirmation-icon" />
                        ) : null}
                    </div>
                </div>
                <div className="upgrade-plan-single">
                    <h2>Premium</h2>
                    <p>Gain insights, fast. Analyse. Act.</p>
                    <div className="price-display">
                        <div className="price-circle">
                            £69
                        </div>
                        <div className="price-period">
                            per month, per user
                        </div>
                    </div>
                    <ul className="custom-list">
                        <li><UpgradePlanTick /> <strong>Unlimited Insights:&nbsp;</strong> No limits. Real-time, contextually accurate answers.</li>
                        <li><UpgradePlanTick /> <strong>Auto-Queries:&nbsp;</strong> Questions to guide your analysis into each document.</li>
                        <li><UpgradePlanTick /> <strong>Unlimited Document Uploads:&nbsp;</strong> Pre-processed with your user-persona.</li>
                        <li><UpgradePlanTick /> <strong>Advanced Persona:&nbsp;</strong> Fine-tuned AI models for role-based insights.</li>
                        <li><UpgradePlanTick /> <strong>Secure Cloud:&nbsp;</strong> Instant retrieval of stored documents and chat insights.</li>
                        <li><UpgradePlanTick /> <strong>Contextual AI:&nbsp;</strong> Advanced one-click query options for in-depth analysis.</li>
                        <li><UpgradePlanTick /> <strong>Priority Support:&nbsp;</strong> Multi-channel, and fast turn-around assistance.</li>
                        <li><UpgradePlanTick /> <strong>Early Access:&nbsp;</strong> Be the first to use new integrations and features.</li>
                        <li><UpgradePlanTick /> <strong>Security & Privacy:&nbsp;</strong> EU data centres and GDPR compliance.</li>
                        <p>*Cancel anytime, no strings attached.</p>
                    </ul>
                    <div className="upgrade-page-buttons-container">
                        {planName === 'Premium' ? (
                            <PremiumConfirmation className="premium-confirmation-icon" />
                        ) : (
                            <Button 
                                text="Upgrade to Premium" 
                                color="green" 
                                size="medium" 
                                onClick={handleUpgradeClick} 
                                className="button-to-upgrade" 
                            />
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default UpgradePlanPage;
