import React, { useState, useEffect } from 'react';
import WelcomeModal from './WelcomeModal';
import OnboardingStep1 from './OnboardingStep1';
import OnboardingStep2 from './OnboardingStep2';
import OnboardingStep3 from './OnboardingStep3';
import OnboardingStep4 from './OnboardingStep4';
import './SharedModalStyles.css'; // Import shared styles

const IntroModal = ({ isOpen, onClose }) => {
    const [currentStep, setCurrentStep] = useState(0); // Start with the WelcomeModal
    const [showModal, setShowModal] = useState(isOpen);

    useEffect(() => {
        setShowModal(isOpen);
    }, [isOpen]);

    const handleNextStep = () => setCurrentStep(currentStep + 1);
    const handlePreviousStep = () => setCurrentStep(currentStep - 1);
    const handleComplete = () => {
        setShowModal(false);
        onClose();
    };

    return (
        <>
            {showModal && (
                <div className="onboarding-full-page-overlay">
                    <div className="onboarding-modal-box">
                        {currentStep === 0 && <WelcomeModal onNext={handleNextStep} />}
                        {currentStep === 1 && <OnboardingStep1 onNext={handleNextStep} />}
                        {currentStep === 2 && <OnboardingStep2 onBack={handlePreviousStep} onNext={handleNextStep} />}
                        {currentStep === 3 && <OnboardingStep3 onBack={handlePreviousStep} onNext={handleNextStep} />}
                        {currentStep === 4 && <OnboardingStep4 onBack={handlePreviousStep} onComplete={handleComplete} />}
                    </div>
                </div>
            )}
        </>
    );
};

export default IntroModal;
