import React, { useState, useContext } from 'react';
import Button from '../../ReusableComponents/Buttons/Button';
import { supabase } from '../../Auth/SupabaseAuth/SupabaseClient';
import { UserContext } from '../../Auth/UserAuth/UserContext';
import { trackEvent } from '../UserAnalytics/MixPanelConfig';
import WhiteLogo from '../../ReusableComponents/Logos/Logo.svg';
import './SharedModalStyles.css'; // Import the shared styles
import './OnboardingStep2.css';

const OnboardingStep2 = ({ onNext, onBack }) => {
    const [isLoading, setLoading] = useState(false);
    const { session, userType } = useContext(UserContext);
    const [formData, setFormData] = useState({
        firstName: '',
        lastName: '',
        companyName: '',
        role: '',
    });
    const [errors, setErrors] = useState({});
    const [formTouched, setFormTouched] = useState(false);

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
        setErrors({ ...errors, [name]: '' });
    };

    const handleNext = async () => {
        setFormTouched(true);
        const newErrors = validateForm();

        if (Object.keys(newErrors).length > 0) {
            setErrors(newErrors);
            return;
        }

        setLoading(true);

        if (session) {
            const user = session.user;

            const { data: existingProfiles, error: existingError } = await supabase
                .from('userprofiles')
                .select('id')
                .eq('user_id', user.id);

            if (existingError) {
                console.error('Error fetching existing profiles:', existingError);
                setLoading(false);
                return;
            }

            const profileData = {
                first_name: formData.firstName,
                last_name: formData.lastName,
            };

            if (userType === 'business') {
                profileData.company_name = formData.companyName;
                profileData.role = formData.role;
            }

            if (existingProfiles.length > 0) {
                const { error: updateError } = await supabase
                    .from('userprofiles')
                    .update(profileData)
                    .eq('user_id', user.id);

                if (!updateError) {
                    trackEvent('Onboarding_Step2_Completed', {
                        userId: user.id,
                        email: user.email,
                        ...profileData,
                    });
                } else {
                    console.error('Error updating user profile:', updateError);
                }
            } else {
                const { error: insertError } = await supabase
                    .from('userprofiles')
                    .insert([{ user_id: user.id, ...profileData }]);

                if (!insertError) {
                    trackEvent('Onboarding_Step2_Completed', {
                        userId: user.id,
                        email: user.email,
                        ...profileData,
                    });
                } else {
                    console.error('Error inserting new user profile:', insertError);
                }
            }

            onNext();
        } else {
            console.log('Session not available.');
        }

        setLoading(false);
    };

    const validateForm = () => {
        const newErrors = {};
        if (formData.firstName.trim() === '') {
            newErrors.firstName = 'First Name is required';
        }
        if (formData.lastName.trim() === '') {
            newErrors.lastName = 'Last Name is required';
        }
        if (userType === 'business') {
            if (formData.companyName.trim() === '') {
                newErrors.companyName = 'Company Name is required';
            }
            if (formData.role.trim() === '') {
                newErrors.role = 'Role is required';
            }
        }
        return newErrors;
    };

    return (
        <div className="onboarding-full-page-overlay">
            <div className="onboarding-modal-box">
                <img src={WhiteLogo} alt="Logo" className="onboarding-modal-logo" />
                <div className="onboarding-modal-title">Tell us about yourself</div>
                <div className="onboarding-step-2-form">
                    <div className="form-row">
                        <div className="form-group">
                            <input
                                type="text"
                                name="firstName"
                                value={formData.firstName}
                                onChange={handleInputChange}
                                placeholder="First Name"
                            />
                            {errors.firstName && <div className="error">{errors.firstName}</div>}
                        </div>
                        <div className="form-group">
                            <input
                                type="text"
                                name="lastName"
                                value={formData.lastName}
                                onChange={handleInputChange}
                                placeholder="Last Name"
                            />
                            {errors.lastName && <div className="error">{errors.lastName}</div>}
                        </div>
                    </div>
                    {userType === 'business' && (
                        <div className="form-row">
                            <div className="form-group">
                                <input
                                    type="text"
                                    name="companyName"
                                    value={formData.companyName}
                                    onChange={handleInputChange}
                                    placeholder="Company Name"
                                />
                                {errors.companyName && <div className="error">{errors.companyName}</div>}
                            </div>
                            <div className="form-group">
                                <input
                                    type="text"
                                    name="role"
                                    value={formData.role}
                                    onChange={handleInputChange}
                                    placeholder="Role"
                                />
                                {errors.role && <div className="error">{errors.role}</div>}
                            </div>
                        </div>
                    )}
                </div>
                <div className="onboarding-step-2-buttons">
                    <Button text="Back" onClick={onBack} />
                    <Button text="Next" onClick={handleNext} isLoading={isLoading} />
                    
                </div>
            </div>
        </div>
    );
};

export default OnboardingStep2;
