import React from 'react';
import logo from '../../ReusableComponents/Logo.svg'; // Ensure correct path
import FormFieldsSignup from './FormFieldsSignup'; // Import the new form fields component
import './SignupPage.css'; // Import styles

function SignupPage() {
    return (
        <div className="signup-page">
            <img src={logo} alt="Logo" className="logo" />
            <FormFieldsSignup />
        </div>
    );
}

export default SignupPage;
