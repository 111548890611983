

//This component listens for text selection and triggers the context menu.

// TextInteractionHandler.js
import React, { useEffect } from 'react';

const TextInteractionHandler = ({ onTextSelected, containerRef }) => {

    useEffect(() => {
        const handleContextMenu = (e) => {
            let selectedText = window.getSelection().toString().trim();
            if (selectedText) {
                e.preventDefault();

                const rect = {
                    top: e.clientY,
                    left: e.clientX,
                    width: 0,
                    height: 0
                };

                // Include "Add notes" in the options array
                onTextSelected(selectedText, rect, ['Tell me more', 'Give me insights', 'Give me references', 'Add notes']);
            }
        };

        if (containerRef.current) {
            containerRef.current.addEventListener('contextmenu', handleContextMenu);
        }

        return () => {
            if (containerRef.current) {
                containerRef.current.removeEventListener('contextmenu', handleContextMenu);
            }
        };
    }, [onTextSelected, containerRef]);

    return null;
};

export default TextInteractionHandler;
