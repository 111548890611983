import React, { useContext, useEffect, useState } from 'react';
import { BrowserRouter as Router, Routes, Route, useNavigate, useLocation, Navigate } from 'react-router-dom';
import { supabase } from './Auth/SupabaseAuth/SupabaseClient';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './ReusableComponents/Toast/DefaultToastStyles.css';
import LandingPage from './Auth/LandingPage/LandingPage';
import LoginPage from './Auth/Login/LoginPage';
import SignupPage from './Auth/Signup/SignupPage';
import ConfirmEmailPage from './Auth/ConfirmEmail/ConfirmEmailPage';
import PasswordReset from './Auth/PasswordAndEmailReset/PasswordReset';
import ResetPasswordEmail from './Auth/PasswordAndEmailReset/PasswordResetEmail';
import Dashboard from './MainApp/Dashboard';
import DocumentIntelligence from './MainApp/DocumentIntelligence/DocIntel';
import MainMapExplorePage from './MainApp/MapExplore/MainMapExplorePage';
import UploadSessions from './MainApp/UserAppSessions/UploadSessions';
import { UserContext, UserProvider } from './Auth/UserAuth/UserContext';
import { SessionProvider } from './Auth/UserAuth/SessionContext';
import { MapSessionProvider } from './Auth/UserAuth/MapSessionContext';
import './Auth/UserAnalytics/MixPanelConfig';
import UpgradeSuccessPage from './MainApp/UpgradePlan/PaymentsLogic/UpgradeSuccessPage';
import UpgradeCancelPage from './MainApp/UpgradePlan/PaymentsLogic/UpgradeCancelPage';
import { ResetProvider } from './Auth/UserAuth/ResetContext';
import { DemoProvider, useDemo } from './Auth/UserAuth/DemoContext';
import { DocumentProvider } from './Auth/UserAuth/DocumentContext';
import { ChatProvider } from './Auth/UserAuth/ChatContext'; 
import LoadingComponent from './ReusableComponents/LoadingComponent'; 
import { NotesProvider } from './Auth/UserAuth/NotesContext'; 
import { ExportAndShareProvider } from './Auth/UserAuth/ExportShareContext'; 
import LoadSession from './MainApp/UserAppSessions/LoadSessions';

function PrivateRoute({ children }) {
    const { session, setSession, loading, onboardingComplete } = useContext(UserContext);
    const navigate = useNavigate();
    const location = useLocation();
    const [initialRedirectDone, setInitialRedirectDone] = useState(false);

    useEffect(() => {
        const { data, error } = supabase.auth.onAuthStateChange(
            (event, session) => {
                setSession(session);
                if (event === "SIGNED_IN" && !initialRedirectDone) {
                    if (!onboardingComplete) {
                        navigate('/dashboard');
                    }
                    setInitialRedirectDone(true);
                }
            }
        );

        if (error) {
            console.error("Error subscribing to auth changes:", error);
        }

        return () => {
            data?.subscription?.unsubscribe();
        };
    }, [setSession, navigate, initialRedirectDone, onboardingComplete]);

    useEffect(() => {
        if (!loading && !initialRedirectDone) {
            if (session) {
                if (!onboardingComplete) {
                    navigate('/dashboard');
                }
                setInitialRedirectDone(true);
            } else {
                navigate('/login');
                setInitialRedirectDone(true);
            }
        }
    }, [loading, session, navigate, initialRedirectDone, onboardingComplete]);

    if (loading) {
        return <LoadingComponent loading={true} />;
    }

    if (!session && location.pathname !== '/') {
        return <Navigate to="/login" replace />;
    }

    return session ? children : null;
}

function DemoRoute({ children }) {
    const { setIsDemo } = useDemo();
    const { setSession } = useContext(UserContext);
    const navigate = useNavigate();

    useEffect(() => {
        // Automatically authenticate as guest user
        const authenticateGuestUser = async () => {
            const { data: { session }, error } = await supabase.auth.signInWithPassword({
                email: process.env.REACT_APP_GUEST_EMAIL,
                password: process.env.REACT_APP_GUEST_PASSWORD,
            });
            if (error) {
                console.error('Error authenticating guest user:', error);
                return;
            }
            setSession(session);
            setIsDemo(true);
        };

        authenticateGuestUser();
    }, [setSession, setIsDemo]);

    return children;
}

function App() {
    return (
        <div className="App">
            <UserProvider>
                <SessionProvider>
                    <MapSessionProvider>
                        <ResetProvider>
                            <ExportAndShareProvider> {/* Placed here */}
                                <DemoProvider>
                                    <DocumentProvider>
                                        <ChatProvider>
                                            <NotesProvider>
                                                <Router>
                                                    <Routes>
                                                        <Route path="/login" element={<LoginPage />} />
                                                        <Route path="/signup" element={<SignupPage />} />
                                                        <Route path="/confirm-email" element={<ConfirmEmailPage />} />
                                                        <Route path="/forgot-password" element={<PasswordReset />} />
                                                        <Route path="/reset-password-trigger" element={<ResetPasswordEmail />} />
                                                        <Route path="/dashboard/*" element={<PrivateRoute><Dashboard /></PrivateRoute>} />
                                                        <Route path="/upgrade-success" element={<UpgradeSuccessPage />} />
                                                        <Route path="/upgrade-cancel" element={<UpgradeCancelPage />} />
                                                        <Route path="/doc-intel/*" element={<PrivateRoute><DocumentIntelligence /></PrivateRoute>} />
                                                        <Route path="/explore/*" element={<PrivateRoute><MainMapExplorePage /></PrivateRoute>} />
                                                        <Route path="/my-sessions/*" element={<PrivateRoute><UploadSessions /></PrivateRoute>} />
                                    
                                                        <Route 
                                                            path="/doc-intel-demo" 
                                                            element={
                                                                <DemoRoute>
                                                                    <DocumentIntelligence />
                                                                </DemoRoute>
                                                            } 
                                                        />

                                                         <Route path="/load-session/:sessionId" element={<PrivateRoute><LoadSession /></PrivateRoute>} />



                                                        <Route path="/*" element={<PrivateRoute><LandingPage /></PrivateRoute>} />
                                                    </Routes>
                                                </Router>
                                            </NotesProvider>
                                        </ChatProvider>
                                    </DocumentProvider>
                                </DemoProvider>
                            </ExportAndShareProvider>
                        </ResetProvider>
                        <ToastContainer />
                    </MapSessionProvider>
                </SessionProvider>
            </UserProvider>
        </div>
    );
}

export default App;
