import React, { createContext, useState, useCallback, useEffect, useMemo } from 'react';
import { trackEvent } from '../UserAnalytics/MixPanelConfig';
import { SessionClearedToast } from '../../ReusableComponents/Toast/ToastMessages';
import LoadingComponent from '../../ReusableComponents/LoadingComponent';

export const SessionContext = createContext();

export function SessionProvider({ children }) {
    const [sessionId, setSessionId] = useState(null);
    const [filePath, setFilePath] = useState(null);
    const [clearSession, setClearSession] = useState(false);
    const [documentUploaded, setDocumentUploaded] = useState(false);
    const [questionSuggestions, setQuestionSuggestions] = useState([]);
    const [showQuestions, setShowQuestions] = useState(false);
    const [isRestoringSession, setIsRestoringSession] = useState(false);
    const [justCleared, setJustCleared] = useState(false);
    const [activeMenuItem, setActiveMenuItem] = useState('Insights');
    const [selectedMenuText, setSelectedMenuText] = useState('');
    const [selectedMenuOption, setSelectedMenuOption] = useState('');
    const [isContextMenuSubmission, setIsContextMenuSubmission] = useState(false);
    const [searchText, setSearchText] = useState("");
    const [headerSearchText, setHeaderSearchText] = useState('');
    const [documentTitle, setDocumentTitle] = useState('No current session, upload to get started.');
    const [isProcessing, setIsProcessing] = useState(false);
    const [showLoader, setShowLoader] = useState(false);
    const [isClearingSession, setIsClearingSession] = useState(false);

    // New state for bolDetails, not exposed in the context value. used to clear the session storage when executing ClearSession.
    const [bolDetails, setBolDetails] = useState(null);

    const [messages, setMessages] = useState(() => {
        const savedMessages = sessionStorage.getItem('messages');
        return savedMessages ? JSON.parse(savedMessages) : [];
    });

    const handleMenuItemClick = useCallback((menuItemName) => {
        setActiveMenuItem(menuItemName);
    }, []);

    const clearQuestionSuggestions = useCallback(() => {
        setQuestionSuggestions([]);
    }, []);



    
    const clearDocIntelSession = useCallback(() => {
    trackEvent("Clear Session Clicked");

    // Immediately stop any ongoing processing and mark session as being cleared
    setIsProcessing(false);
    setClearSession(true);
    setJustCleared(true);

    // Revoke any existing object URLs to free up memory
    const oldUrl = sessionStorage.getItem('documentUrl');
    if (oldUrl) {
        URL.revokeObjectURL(oldUrl);
    }

    // Explicitly remove 'bolDetails' before clearing all session storage items
    sessionStorage.removeItem('bolDetails');
    sessionStorage.clear();

    // Clear specific UI elements like file input
    const fileInput = document.getElementById("file-upload");
    if (fileInput) {
        fileInput.value = "";
    }

    // Trigger a toast notification to inform the user that the session has been cleared
    SessionClearedToast();

    // Reset all relevant state variables to ensure the UI reflects the cleared session
    setSessionId(null);
    setFilePath(null);
    setDocumentTitle('No current session, upload to get started.');
    setDocumentUploaded(false);
    setMessages([]);
    clearQuestionSuggestions();

    // Reset bolDetails state (already removed from session storage above)
    setBolDetails(null);

    // Optionally, reinitialize specific session storage items as needed
    sessionStorage.setItem('showQuestions', 'true');

    setIsClearingSession(true);

    // Refresh the page after a short delay, with another check to remove 'bolDetails' right before reload
    setTimeout(() => {
        sessionStorage.removeItem('bolDetails'); // Ensure it is removed right before reload
        window.location.reload(); 
    }, 500);
}, [clearQuestionSuggestions]);

    

    useEffect(() => {
        const storedSessionId = sessionStorage.getItem('sessionId');
        const storedFilePath = sessionStorage.getItem('filePath');

        if (storedSessionId && storedSessionId !== sessionId) {
            setSessionId(storedSessionId);
        }

        if (storedFilePath && storedFilePath !== filePath) {
            setFilePath(storedFilePath);
            setIsRestoringSession(true);
        }
    }, [sessionId, filePath]);

    useEffect(() => {
        if (sessionId) {
            sessionStorage.setItem('sessionId', sessionId);
        } else {
            sessionStorage.removeItem('sessionId');
        }

        if (filePath) {
            sessionStorage.setItem('filePath', filePath);
        } else {
            sessionStorage.removeItem('filePath');
        }
    }, [sessionId, filePath]);

    const contextValue = useMemo(() => ({
        sessionId, setSessionId, filePath, setFilePath, isRestoringSession, setIsRestoringSession, clearSession, setClearSession, clearDocIntelSession, documentUploaded,
        setDocumentUploaded, questionSuggestions, setQuestionSuggestions, isContextMenuSubmission, setIsContextMenuSubmission,
        showQuestions, setShowQuestions, justCleared, setJustCleared, handleMenuItemClick, activeMenuItem, selectedMenuText, setSelectedMenuText,
        selectedMenuOption, setSelectedMenuOption, searchText, setSearchText, headerSearchText, setHeaderSearchText, documentTitle, setDocumentTitle, isProcessing, setIsProcessing, showLoader, setShowLoader
    }), [
        sessionId, filePath, isRestoringSession, clearSession, documentUploaded, questionSuggestions, isContextMenuSubmission,
        showQuestions, justCleared, handleMenuItemClick, activeMenuItem, selectedMenuText, selectedMenuOption, searchText, headerSearchText, documentTitle, isProcessing, showLoader
    ]);

    return (
        <SessionContext.Provider value={contextValue}>
            {isClearingSession ? <LoadingComponent loading={true} /> : children}
        </SessionContext.Provider>
    );
}
