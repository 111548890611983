import React, { useState, useContext } from 'react';
import { useDocument } from '../../Auth/UserAuth/DocumentContext';
import './ProcessedDocPanel.css';
import DataBOLExtract from './DataBOLExtract';
import ChatPanelHeaderBar from '../ChatPanel/ChatPanelHeaderBar';
import { UserContext } from '../../Auth/UserAuth/UserContext';

import { ReactComponent as ChatPositiveFeedback } from '../../ReusableComponents/Icons/ChatPositiveFeedback.svg';
import { ReactComponent as ChatNegativeFeedback } from '../../ReusableComponents/Icons/ChatNegativeFeedback.svg';
import { ReactComponent as ChatShareIcon } from '../../ReusableComponents/Icons/ChatShareIcon.svg';
import { ReactComponent as ChatEmailIcon } from '../../ReusableComponents/Icons/PromptEmailIcon.svg';

import TooltipWrapper from '../../ReusableComponents/Tooltip/TooltipWrapper';
import ExportModal from '../ExportAndShare/ExportModal';
import ComovisDispatchModal from '../ComovisDispatch/ComovisDispatchModal';  // Import the new modal
import {
    handleExportToPDF,
    handleExportToCSV,
    handleExportToXLSX,
    handleExportToJSON,
    handleExportToXML
} from '../ExportAndShare/ExportOptions';

const ProcessedDocPanel = () => {
    const { bolDetails } = useDocument();  // Use DocumentContext
    const { session } = useContext(UserContext);       // Get session from UserContext
    const [isExportModalOpen, setIsExportModalOpen] = useState(false);
    const [isComovisDispatchModalOpen, setIsComovisDispatchModalOpen] = useState(false);
    const [forceShowChat, setForceShowChat] = useState(false);  // State to force toggle Chat & Insights

    const handleFeedbackClick = (type) => {
        console.log(`${type} feedback clicked`);
    };

    const handleExportClick = () => {
        setIsExportModalOpen(true);
    };

    const handleCloseExportModal = () => {
        setIsExportModalOpen(false);
    };

    const handleEmailClick = () => {
        setIsComovisDispatchModalOpen(true);
    };

    const handleCloseComovisDispatchModal = () => {
        setIsComovisDispatchModalOpen(false);
        setForceShowChat(true);  // Set this to true when closing the dispatch modal
    };

    return (
       <div className="processed-doc-panel">
            <ChatPanelHeaderBar forceShowChat={forceShowChat} />
            <div className="extracted-data-title-container">
                <h2 className="extracted-data-title">Extracted Document Data</h2>
                <div className="BOL-icon-container-custom">
                    <TooltipWrapper id="positive-feedback" content="Give good feedback">
                        <ChatPositiveFeedback className="BOL-icon-custom icon-positive-feedback-custom" onClick={() => handleFeedbackClick('positive')} />
                    </TooltipWrapper>
                    <TooltipWrapper id="negative-feedback" content="Give bad feedback">
                        <ChatNegativeFeedback className="BOL-icon-custom icon-negative-feedback-custom" onClick={() => handleFeedbackClick('negative')} />
                    </TooltipWrapper>
                    <TooltipWrapper id="email-icon" content="Comovis Intel Dispatch">
                        <ChatEmailIcon className="BOL-icon-custom icon-email-custom" onClick={handleEmailClick} />
                    </TooltipWrapper>
                    <TooltipWrapper id="share-icon" content="Export & share">
                        <ChatShareIcon className="BOL-icon-custom icon-share-custom" onClick={handleExportClick} />
                    </TooltipWrapper>
                </div>
            </div>
            {bolDetails ? (
                <DataBOLExtract data={bolDetails} />
            ) : (
                <div>No BOL data available</div>
            )}
            <ExportModal
                isOpen={isExportModalOpen}
                onClose={handleCloseExportModal}
                onExportPDF={() => handleExportToPDF(bolDetails)}
                onExportCSV={() => handleExportToCSV(bolDetails)}
                onExportXLSX={() => handleExportToXLSX(bolDetails)}
                onExportJSON={() => handleExportToJSON(bolDetails)}
                onExportXML={() => handleExportToXML(bolDetails)}
            />
            <ComovisDispatchModal
                isOpen={isComovisDispatchModalOpen}
                onClose={handleCloseComovisDispatchModal}  // Ensure this triggers the toggle
            />
        </div>
    );
};

export default ProcessedDocPanel;
