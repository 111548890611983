import React, { useState, createContext, useEffect, useRef } from 'react';
import { supabase } from '../SupabaseAuth/SupabaseClient';
import { triggerPremiumUpgradeEmail } from '../../Components/EmailTemplates/TriggerUserUpgradedEmail'; 

export const UserContext = createContext();

const planIdToName = {
    'd4857aff-031e-4b17-a969-3c256b95b9e3': 'Trial',
    'b64757bf-70dd-43f4-835a-d221c754db1a': 'Premium',
};

function getPlanName(planId) {
    return planIdToName[planId] || 'Unknown';
}

export function UserProvider({ children }) {
    const storedAvatarURL = localStorage.getItem('avatarURL');
    const storedAccessToken = localStorage.getItem('accessToken');
    const storedOnboardingComplete = localStorage.getItem('onboardingComplete') === 'true';
    const storedUserPersona = localStorage.getItem('userPersona'); // Retrieve stored userPersona

    const [session, setSession] = useState(null);
    const [loading, setLoading] = useState(true);
    const [userType, setUserType] = useState(null);
    const [userPersona, setUserPersona] = useState(storedUserPersona); // Initialize with storedUserPersona
    const [avatarURL, setAvatarURL] = useState(storedAvatarURL);
    const [onboardingComplete, setOnboardingComplete] = useState(storedOnboardingComplete);
    const [planId, setPlanId] = useState(null);
    const [trialEndDate, setTrialEndDate] = useState(null);
    const [planName, setPlanName] = useState(null);
    const [showUpgradeModal, setShowUpgradeModal] = useState(false);
    const [lastUploadDate, setLastUploadDate] = useState(null);
    const [messageCount, setMessageCount] = useState(0);
    const [lastMessageDate, setLastMessageDate] = useState(null);
    const lastKnownPlan = useRef(null);
    const emailTriggered = useRef(false); // Track if the email has already been triggered
    const [accessToken, setAccessToken] = useState(storedAccessToken);

    const isPlanPremium = (planName) => planName === 'Premium';

    useEffect(() => {
        const { data, error } = supabase.auth.onAuthStateChange(async (event, session) => {
            setSession(session);
            setLoading(false);

            if (session) {
                localStorage.setItem('sessionId', session.user.id);
                localStorage.setItem('accessToken', session.access_token);
                setAccessToken(session.access_token);

                const { data: profiles, error: profileError } = await supabase
                    .from('userprofiles')
                    .select('persona, avatar_path, onboarding_complete, plan_id, trial_end_date, last_upload_date, last_message_date, message_count, premium_email_sent, user_type') // Include user_type in the selection
                    .eq('user_id', session.user.id);

                if (profileError) {
                    console.error('Error fetching user profile:', profileError);
                } else if (profiles.length > 0) {
                    const profile = profiles[0];
                    setUserPersona(profile.persona);
                    if (profile.persona) {
                        localStorage.setItem('userPersona', profile.persona); // Store userPersona in local storage
                    }

                    setUserType(profile.user_type); // Set and store userType
                    if (profile.user_type) {
                        localStorage.setItem('userType', profile.user_type); // Store userType in local storage
                    }

                    setMessageCount(profile.message_count || 0);
                    setLastMessageDate(profile.last_message_date || null);
                    setLastUploadDate(profile.last_upload_date ? new Date(profile.last_upload_date) : null);
                    setOnboardingComplete(profile.onboarding_complete);
                    localStorage.setItem('onboardingComplete', profile.onboarding_complete.toString());

                    const newPlanName = getPlanName(profile.plan_id);
                    setPlanName(newPlanName);

                    // Reset message count and dates if the plan changes
                    if (lastKnownPlan.current && lastKnownPlan.current !== newPlanName) {
                        setMessageCount(0);
                        setLastMessageDate(null);

                        await supabase
                            .from('userprofiles')
                            .update({
                                last_message_date: null,
                                message_count: 0
                            })
                            .eq('user_id', session.user.id);
                    }

                    // Clear last upload date if downgrading from Premium
                    if (isPlanPremium(lastKnownPlan.current) && !isPlanPremium(newPlanName)) {
                        setLastUploadDate(null);
                        await supabase
                            .from('userprofiles')
                            .update({ last_upload_date: null })
                            .eq('user_id', session.user.id);
                    }

                    // Trigger the premium upgrade email when plan changes to Premium, only if not already sent
                    if (newPlanName === 'Premium' && lastKnownPlan.current !== 'Premium' && !profile.premium_email_sent) {
                        emailTriggered.current = true; // Mark the email as triggered
                        triggerPremiumUpgradeEmail(session.user.email, session.access_token);

                        // Update the user profile to set premium_email_sent to true
                        await supabase
                            .from('userprofiles')
                            .update({ premium_email_sent: true })
                            .eq('user_id', session.user.id);
                    }

                    lastKnownPlan.current = newPlanName;
                    
                    if (profile.avatar_path) {
                        const baseAvatarUrl = "https://skyxbncllurrcsexskvy.supabase.co/storage/v1/object/public/user-avatars";
                        const avatarUrl = `${baseAvatarUrl}/${profile.avatar_path}`;
                        setAvatarURL(avatarUrl);
                        localStorage.setItem('avatarURL', avatarUrl);
                    }

                    setPlanId(profile.plan_id);
                    setTrialEndDate(new Date(profile.trial_end_date));
                }
            } else {
                localStorage.removeItem('sessionId');
                localStorage.removeItem('accessToken');
                localStorage.removeItem('userPersona'); // Remove userPersona from local storage
                localStorage.removeItem('userType'); // Remove userType from local storage
                setAccessToken(null);
                setUserPersona(null);
                setUserType(null);
            }
        });

        if (error) {
            console.error("Error subscribing to auth changes:", error);
        }

        return () => {
            if (data && data.subscription) {
                data.subscription.unsubscribe();
            }
        };
    }, []);

    useEffect(() => {
        if (accessToken) {
            localStorage.setItem('accessToken', accessToken);
        } else {
            localStorage.removeItem('accessToken');
        }
    }, [accessToken]);

    return (
        <UserContext.Provider value={{
            session, setSession, loading, userType, setUserType,
            userPersona, setUserPersona, avatarURL, setAvatarURL, onboardingComplete, setOnboardingComplete, planId, setPlanId, trialEndDate,
            setTrialEndDate, planName, setPlanName, showUpgradeModal, setShowUpgradeModal, lastUploadDate, setLastUploadDate, messageCount, setMessageCount,
            lastMessageDate, setLastMessageDate, accessToken, setAccessToken
        }}>
            {children}
        </UserContext.Provider>
    );
}
