import React, { useState, useEffect, useMemo } from 'react';
import { AgGridReact } from 'ag-grid-react';
import './ag-grid.css';
import './ag-theme-alpine.css';
import './CSVViewer.css'; // Import the new CSS file
import Papa from 'papaparse';

const CSVViewer = ({ file }) => {
  const [rowData, setRowData] = useState([]);
  const [columnDefs, setColumnDefs] = useState([]);
  const [error, setError] = useState(null);

  useEffect(() => {
    if (!file) return;

    // Parse the CSV file
    Papa.parse(file, {
      complete: (result) => {
        if (result.errors.length) {
          setError('Error parsing CSV file.');
          console.error(result.errors);
          return;
        }

        const cleanedData = result.data.filter(row => row.some(cell => cell));
        if (cleanedData.length === 0) return;

        // Create Column Definitions
        const columns = cleanedData[0].map((header, idx) => ({
          headerName: header,
          field: String(idx),
        }));
        setColumnDefs(columns);

        // Set row data
        setRowData(cleanedData.slice(1));
      },
      error: (error) => {
        setError('Error parsing CSV file.');
        console.error(error);
      }
    });
  }, [file]);

  const gridOptions = useMemo(() => ({
    columnDefs: columnDefs,
    rowData: rowData,
    animateRows: true,
    suppressMovableColumns: true,
  }), [columnDefs, rowData]);

  if (error) {
    return <div className="csv-viewer-error">{error}</div>;
  }

  return (
    <div className="csv-viewer-container">
      <div className="ag-theme-alpine" style={{ height: '100%', width: '100%' }}>
        <AgGridReact
          {...gridOptions}
        />
      </div>
    </div>
  );
};

export default CSVViewer;
