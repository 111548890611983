import React, { useEffect, useState, useContext } from 'react';
import axios from 'axios';
import { UserContext } from '../../Auth/UserAuth/UserContext';
import { SessionContext } from '../../Auth/UserAuth/SessionContext';
import { useDocument } from '../../Auth/UserAuth/DocumentContext';
import { ReactComponent as SuggestedQuestionsNotice } from '../../ReusableComponents/Icons/SuggestedQuestionsNotice.svg'; 
import { ReactComponent as LoadingSuggestedQuestionsNotice } from '../../ReusableComponents/Icons/LoadingSuggestedQuestionsNotice.svg'; 
import StandaloneLoader from '../../ReusableComponents/StandaloneLoader';

import './QuestionSuggestions.css';

function QuestionSuggestions({ onQuestionClick, justCleared }) {
    // Flag to enable the component
    const isComponentEnabled = false; // Set this to true to enable the component

    const [questions, setQuestions] = useState([]);
    const { documentId } = useDocument(); // Use documentId from DocumentContext
    const { isRestoringSession, filePath } = useContext(SessionContext);
    const { userPersona, session } = useContext(UserContext);
    const [loading, setLoading] = useState(false);

    const apiUrl = process.env.NODE_ENV === 'development' 
        ? process.env.REACT_APP_API_URL 
        : process.env.REACT_APP_PRODUCTION_API_URL;

    const handleQuestionClick = (question) => {
        if (typeof onQuestionClick === 'function') {
            onQuestionClick(question);
        }
    };

    const fetchData = async () => {
        setLoading(true);
        try {
            const result = await axios.post(`${apiUrl}/generate-questions`, {
                documentId: documentId, // Send documentId instead of documentText
                userPersona: userPersona
            }, {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${session.access_token}`
                }
            });

            if (result.data && result.data.questions) {
                setQuestions(result.data.questions);
            } else {
                console.log('No prompts received.');
            }
        } catch (error) {
            console.error('Error getting prompt suggestions:', error);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        if (!isComponentEnabled) {
            return;
        }

        if (isRestoringSession) {
            setQuestions([]);
            setLoading(false);
            return;
        }

        if (documentId && userPersona && filePath && !justCleared) {
            fetchData();
        } else {
            setQuestions([]);
            setLoading(false);
        }
    }, [documentId, userPersona, filePath, isRestoringSession, justCleared]);

    if (!isComponentEnabled || isRestoringSession) {
        return null;
    }

    return (
        <div className="question-suggestions-container">
            {loading ? (
                <div className="loading-container">
                    <LoadingSuggestedQuestionsNotice />
                    <StandaloneLoader type="spin" size={18} color="#000" />
                </div>
            ) : (
                questions.length > 0 && (
                    <div className="question-container">
                        <SuggestedQuestionsNotice className="suggested-notice" />
                        {questions.map((question, index) => (
                            <div
                                key={index}
                                className="question-box"
                                onClick={() => handleQuestionClick(question)}
                            >
                                {question}
                            </div>
                        ))}
                    </div>
                )
            )}
        </div>
    );
}

export default QuestionSuggestions;
