import React, { useEffect, useState } from 'react';

const TXTViewer = ({ documentState }) => {
    const [textContent, setTextContent] = useState('');

    useEffect(() => {
        const loadTxtFile = async () => {
            try {
                const response = await fetch(documentState.url);
                const text = await response.text();
                setTextContent(text);
            } catch (error) {
                console.error('Error loading TXT file:', error);
            }
        };

        loadTxtFile();
    }, [documentState.url]);

    return <pre>{textContent}</pre>;
};

export default TXTViewer;
