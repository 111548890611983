
import React from 'react';
import { useNavigate } from 'react-router-dom';  // Import the useNavigate hook
import './UpgradeCancelPage.css';

const UpgradeCancelPage = () => {
  // Initialize the useNavigate hook
  const navigate = useNavigate();


// Function to navigate to the dashboard
const navigateToDashboard = () => {
    window.location.href = '/dashboard'; // Reload and navigate to the dashboard
};




  return (
    <div className="comovis-cancel-container">
      <div className="comovis-cancel-header-section">
        {/* Optionally, add a header here */}
      </div>

      <h2 className="comovis-cancel-title">You've Cancelled Your Comovis Business Premium Plan.</h2>
      <p className="comovis-cancel-text">Your subscription has been successfully cancelled.</p>
      <p className="comovis-cancel-text">Your access to Premium features will continue until the current billing cycle ends.</p>

      <div className="comovis-button-container">
        {/* Attach the navigateToDashboard function to the onClick event */}
        <button onClick={navigateToDashboard} className="comovis-cancel-button">Back to Dashboard</button>
      </div>
    </div>
  );
};

export default UpgradeCancelPage;
