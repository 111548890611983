import React, { useState, useContext } from 'react';
import Button from '../../ReusableComponents/Buttons/Button';
import { supabase } from '../../Auth/SupabaseAuth/SupabaseClient';
import { UserContext } from '../../Auth/UserAuth/UserContext';
import { trackEvent } from '../UserAnalytics/MixPanelConfig';
import WhiteLogo from '../../ReusableComponents/Logos/Logo.svg';
import './SharedModalStyles.css'; // Import the shared styles
import './OnboardingStep3.css';

const defaultDescription = "The user persona you choose significantly influences the AI model's analysis and recommendations tailored to your workflow. Please select the persona that best corresponds with your role.";

// Persona descriptions for each industry
const personaDescriptions = {
    // Maritime & Shipping
    analyst: "As a skilled analyst in the maritime and global trade industry, leverage our AI to dive deep into intricate reports and documents, extracting intelligent insights seamlessly.",
    compliance_officer: "Maintain regulatory compliance efficiently through our AI-powered deep document insights and analysis tool tailored for the maritime and shipping industry.",
    shipbroker: "Strategically negotiate and arrange shipping contracts with AI-driven insights, optimizing chartering decisions and facilitating smooth negotiations.",
    freight_forwarder: "Manage logistics and navigate customs regulations effectively with AI-assisted document processing tailored for freight forwarding in global trade.",
    trader: "Optimize trade outcomes and manage risks using AI to analyze market dynamics, assisting you in making decisive, market-savvy decisions in the commodities and maritime market.",
    fleet_manager: "Oversee and maintain your fleet with AI-driven guidance on efficiency, maintenance schedules, and compliance with maritime regulations.",
    general: "For versatile roles within maritime and global trade, our AI helps streamline operations, improve decision-making, and enhance efficiency across your workflows.",
    
    // Professional Services
    legal_analyst: "Analyze legal documents, case law, and contracts with AI that extracts key insights, drafts communications, and generates summaries to support legal strategies and compliance efforts.",
    consultant: "Leverage AI to review client-provided documents, extract actionable insights, and draft tailored reports and communications that address client-specific challenges and opportunities.",
    account_manager: "Use AI to analyze client contracts, manage correspondence, and draft personalized communications that enhance client relationships and ensure satisfaction.",
    hr_manager: "Streamline HR processes with AI that reviews resumes, manages employee documentation, and drafts compliant communications and policies based on company standards.",
    project_manager: "Keep your projects on track by utilizing AI to analyze project documents, draft status reports, and generate task-specific communications that ensure alignment and progress.",
    analyst: "Dive deep into complex datasets and documents using AI, which extracts trends, drafts data-driven reports, and generates communications to support strategic decision-making.",
    
    // Finance
    investment_advisor: "Enhance client investment strategies with AI-driven analysis of financial documents, market reports, and personalized communication drafting to guide client decisions.",
    compliance_officer: "Ensure regulatory compliance by using AI to review financial documents, draft compliance reports, and generate communications that keep your firm aligned with legal standards.",
    financial_analyst: "Utilize AI to analyze financial statements, market data, and reports, drafting detailed analysis and communication that supports investment and business strategies.",
    portfolio_manager: "Optimize portfolio management with AI that reviews asset reports, assesses risks, and drafts communications to clients and stakeholders regarding portfolio performance.",
    risk_manager: "Proactively manage financial risks with AI-driven insights from risk assessment documents, generating detailed reports and strategic communications to mitigate potential threats.",
    
    personal: "Utilize AI to assist with a wide range of personal projects and tasks. Our tool helps you analyze and extract key insights from any document, regardless of the topic, and draft relevant communications."
};

// Mapping industry to personas
const industryToPersonas = {
    maritime_shipping: ['analyst', 'compliance_officer', 'shipbroker', 'freight_forwarder', 'trader', 'fleet_manager', 'general'],
    professional_services: ['legal_analyst', 'consultant', 'account_manager', 'hr_manager', 'project_manager', 'analyst'],
    finance: ['investment_advisor', 'compliance_officer', 'financial_analyst', 'portfolio_manager', 'risk_manager']
};

const OnboardingStep3 = ({ onBack, onNext }) => {
    const [persona, setPersona] = useState("");
    const [industry, setIndustry] = useState("");
    const { session, userType } = useContext(UserContext);
    const [isLoading, setIsLoading] = useState(false);

    const handleSelectIndustry = (event) => {
        setIndustry(event.target.value);
        setPersona(""); // Reset persona selection when industry changes
    };

    const handleSelectPersona = (event) => {
        const selectedPersona = event.target.value;
        if (userType === 'personal' && selectedPersona !== 'personal') {
            return; // If the userType is 'personal', do not allow selecting other personas
        }
        setPersona(selectedPersona);
    };

    const handleNext = async () => {
        setIsLoading(true);
        if (persona && (userType !== 'business' || industry)) { // Ensure industry is selected for business users
            const user = session.user;

            // Check if a profile already exists for the user
            const { data: existingProfiles, error: existingError } = await supabase
                .from('userprofiles')
                .select('id')
                .eq('user_id', user.id);

            if (existingError) {
                console.error('Error fetching existing profiles:', existingError);
                setIsLoading(false);
                return;
            }

            // If a profile exists, update it. Otherwise, create a new one
            if (existingProfiles.length > 0) {
                const { error: updateError } = await supabase
                    .from('userprofiles')
                    .update({ persona: persona, industry: industry }) // Update persona and industry fields
                    .eq('user_id', user.id);

                if (updateError) {
                    console.error('Error updating user profile:', updateError);
                } else {
                    trackEvent("Onboarding Step 3 Completed", {
                        userId: user.id,
                        email: user.email,
                        persona: persona,
                        industry: industry
                    });
                }
            } else {
                const { error: insertError } = await supabase
                    .from('userprofiles')
                    .insert([{ user_id: user.id, persona: persona, industry: industry }]); // Insert persona and industry fields

                if (insertError) {
                    console.error('Error inserting new user profile:', insertError);
                } else {
                    trackEvent("Onboarding Step 3 Completed", {
                        userId: user.id,
                        email: user.email,
                        persona: persona,
                        industry: industry
                    });
                }
            }

            // Then navigate to the next page
            onNext();
        } else {
            console.log("Persona or industry not selected.");
        }
        setIsLoading(false); // Stop loading
    };

    const availablePersonas = industry ? industryToPersonas[industry] : [];

    return (
        <div className="onboarding-full-page-overlay">
            <div className="onboarding-modal-box">
                <img src={WhiteLogo} alt="Logo" className="onboarding-modal-logo" />
                <div className="onboarding-modal-title">Select your AI focused user persona</div>
                <div className="onboarding-step-3-container">
                    <div className="persona-description">{persona ? personaDescriptions[persona] : defaultDescription}</div>
                    {userType === 'business' && (
                        <div className="dropdown-container">
                            <select id="industryDropdown" value={industry} onChange={handleSelectIndustry}>
                                <option value="">--Select an industry--</option>
                                <option value="maritime_shipping">Maritime & Shipping</option>
                                <option value="professional_services">Professional Services</option>
                                <option value="finance">Finance</option>
                            </select>
                        </div>
                    )}
                    {(!userType || userType !== 'business' || industry) && (
                        <div className="dropdown-container">
                            <select id="personaDropdown" value={persona} onChange={handleSelectPersona}>
                                <option value="">--Select a persona--</option>
                                {availablePersonas.map((personaKey) => (
                                    <option key={personaKey} value={personaKey}>
                                        {personaKey === 'hr_manager' ? 'HR Manager' : personaKey.replace(/_/g, ' ').replace(/\b\w/g, char => char.toUpperCase())}
                                    </option>
                                ))}
                                {userType === 'personal' && (
                                    <option value="personal">Personal</option>
                                )}
                            </select>
                        </div>
                    )}
                    <div className="button-group">
                        <Button text="Back" className="onboarding-button back-button" onClick={onBack} />
                        <Button text="Next" className="onboarding-button next-button" onClick={handleNext} isLoading={isLoading} />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default OnboardingStep3;
