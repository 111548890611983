import React, { useState } from "react";
import "./FormFieldsSignup.css";
import { useNavigate } from 'react-router-dom';
import Button from '../../ReusableComponents/Buttons/Button';
import { supabase } from '../../Auth/SupabaseAuth/SupabaseClient';
import { SignupSuccessToast, SignupErrorToast } from '../../ReusableComponents/Toast/ToastMessages';
import { trackEvent, identifyUser, setUserProfile } from '../UserAnalytics/MixPanelConfig';
import GoogleSignUpButton from './GoogleSignUpButton'; // Import the GoogleSignUpButton component

export default function FormFieldsSignup() {
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");
    const [emailError, setEmailError] = useState("");
    const [passwordError, setPasswordError] = useState("");
    const [confirmPasswordError, setConfirmPasswordError] = useState("");
    const [isLoading, setIsLoading] = useState(false);
    const navigate = useNavigate();


    

    const handleSignup = async (e) => {
        e.preventDefault();

        setIsLoading(true);

        // Reset errors
        setEmailError("");
        setPasswordError("");
        setConfirmPasswordError("");

        // Check for empty fields
        if (!email) {
            setEmailError("Email is required");
        }
        if (!password) {
            setPasswordError("Password is required");
        }
        if (!confirmPassword) {
            setConfirmPasswordError("Confirm password is required");
        }
        if (!email || !password || !confirmPassword) {
            setIsLoading(false);
            return;
        }

        // Check if passwords match
        if (password !== confirmPassword) {
            setConfirmPasswordError("Passwords do not match");
            setIsLoading(false);
            return;
        }

        // Let's track when a user attempts to sign up.
        trackEvent("Signup_Attempt", {
            email: email
        });

        // Use environment variables to set the redirect URL
        const baseURL = process.env.NODE_ENV === 'development' ? process.env.REACT_APP_DEV_URL : process.env.REACT_APP_PROD_URL;
        const redirectURL = `${baseURL}/dashboard`;

        // Try to sign up the user
        const { data, error } = await supabase.auth.signUp({
            email: email,
            password: password,
            options: {
                emailRedirectTo: redirectURL
            }
        });

        if (error) {
            console.log("An unexpected error occurred during signup:", error);
            SignupErrorToast('Signup error');

            // Track signup error with mixpanel
            trackEvent("Signup_Error", {
                email: email,
                error: error.message
            });

            setIsLoading(false);
        } else {
            // Identification: Use the 'id' from your user object as the unique identifier.
            identifyUser(data.user.id);

            // Setting User Profile: 
            setUserProfile({
                "$email": email,
            });

            SignupSuccessToast();

            // Track successful signup with mixpanel
            trackEvent("Signup_Success", {
                email: email
            });

            navigate("/confirm-email");
            setIsLoading(false);
        }
    }





    return (
        <div className="formfieldssignup-container">
            <form className="formfieldssignup" onSubmit={handleSignup}>
                <label className="input-label">
                    Email
                    <input className="input-field" type="email" value={email} onChange={e => setEmail(e.target.value)} placeholder="Enter email" />
                    {emailError && <div className="error">{emailError}</div>}
                </label>
                <label className="input-label">
                    Password
                    <input className="input-field" type="password" value={password} onChange={e => setPassword(e.target.value)} placeholder="Enter password" />
                    {passwordError && <div className="error">{passwordError}</div>}
                </label>
                <label className="input-label">
                    Confirm Password
                    <input className="input-field" type="password" value={confirmPassword} onChange={e => setConfirmPassword(e.target.value)} placeholder="Confirm password" />
                    {confirmPasswordError && <div className="error">{confirmPasswordError}</div>}
                </label>
                <Button text="Sign Up" className="signup-button" type="submit" isLoading={isLoading} loadingText="Processing..." />
            
                <GoogleSignUpButton /> 

                 <h4 className="login-link-text">
                    Already have an account? <a href="/login" className="login-link">Login here</a>
                </h4>
            </form>
        </div>
    );
}
