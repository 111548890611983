import React from 'react';
import Button from '../../ReusableComponents/Buttons/Button';
import WhiteLogo from '../../ReusableComponents/Logos/Logo.svg';
import './SharedModalStyles.css'; // Import shared styles

const WelcomeModal = ({ onNext }) => {
    return (
        <div className="onboarding-full-page-overlay">
            <div className="onboarding-modal-box">
                <img src={WhiteLogo} alt="Logo" className="onboarding-modal-logo" />
                <div className="onboarding-modal-title">Welcome to Comovis</div>
                <div className="onboarding-modal-content">
                    <p>You are about to complete the onboarding process. We'll gather some basic details and you'll get an opportunity to select your cusotm AI user persona. This will take approximately <b>30 seconds</b>.</p>
                </div>
                <div>
                    <Button text="Start" onClick={onNext} />
                </div>
            </div>
        </div>
    );
};

export default WelcomeModal;
