import React, { createContext, useState, useContext } from 'react';

// Create the context
const ExportAndShareContext = createContext();

// Provider component
export const ExportAndShareProvider = ({ children }) => {
    const [exportOptions, setExportOptions] = useState({
        selectedOption: null,
        isExporting: false,
        exportProgress: 0,
    });

    const selectExportOption = (option) => {
        setExportOptions({ ...exportOptions, selectedOption: option });
    };

    const startExport = () => {
        setExportOptions({ ...exportOptions, isExporting: true, exportProgress: 0 });
        // Implement export logic here (e.g., API call)
    };

    const updateExportProgress = (progress) => {
        setExportOptions({ ...exportOptions, exportProgress: progress });
    };

    const completeExport = () => {
        setExportOptions({ ...exportOptions, isExporting: false, exportProgress: 100 });
    };

    return (
        <ExportAndShareContext.Provider
            value={{ exportOptions, selectExportOption, startExport, updateExportProgress, completeExport }}
        >
            {children}
        </ExportAndShareContext.Provider>
    );
};

// Custom hook to use the context
export const useExportAndShare = () => useContext(ExportAndShareContext);
