import React from 'react';
import Button from '../../../ReusableComponents/Buttons/Button';
import './IntroChatModal.css';

const IntroChatModal = ({ onDismiss }) => {
    const handleGetStartedClick = () => {
        localStorage.setItem('showIntroModal', 'false');
        onDismiss();
    };

    return (
        <div className="intro-chat-modal">
            <h2 className="intro-title">Welcome to Comovis</h2>
            <div className="intro-text-container">
                <p>You can get started in a few easy steps, here's some initial tips to get started.</p>
                <ul className="intro-list">
                    <li><strong>Upload Your Document:</strong> Simply upload your document to begin gaining insights.</li>
                    <li><strong>Natural Language Processing:</strong> Feel free to use natural language for your queries.</li>
                </ul>
            </div>

            <h2 className="intro-title">Features</h2>

            <div className="intro-text-container">
                <ul className="intro-list">
                    <li><strong>Automatic Saving:</strong> Your documents and associated chats are saved automatically, accessible anytime, anywhere.</li>
                    <li><strong>Contextual Communications:</strong> Simply ask to generate emails or communications based on your document's context, specifying any additional tone and details.</li>
                    <li><strong>Custom Persona Insights:</strong> Comovis uses your custom persona to provide insights tailored to your workflow and role.</li>
                </ul>
            </div>

            <Button text="Get Started" color="black" className="intro-modal-btn" onClick={handleGetStartedClick} />
        </div>
    );
};

export default IntroChatModal;
