import React, { useState, useEffect, useContext } from 'react';
import MapChatMessageList from './MapChatMessageList';
import MapChatInputBox from './MapChatInputBox';
import { MapSessionContext } from '../../../Auth/UserAuth/MapSessionContext';
import { UserContext } from '../../../Auth/UserAuth/UserContext';
import { useLocation } from 'react-router-dom';
import './MapChatPanel.css';

const MapChatPanel = () => {
    const { mapSessionId } = useContext(MapSessionContext); 
    const [messages, setMessages] = useState([]);
    const [inputText, setInputText] = useState("");
    const { userPersona, accessToken, setAccessToken, session } = useContext(UserContext);
    const location = useLocation();

    // Add message to the chat
    const addMessage = (message) => {
        setMessages(prevMessages => {
            const updatedMessages = [...prevMessages, message];
            sessionStorage.setItem('mapMessages', JSON.stringify(updatedMessages));
            return updatedMessages;
        });
    };

    // Restore messages from sessionStorage or location state
    useEffect(() => {
        const storedMessages = JSON.parse(sessionStorage.getItem('mapMessages')) || [];
        if (location.state && location.state.messages) {
            setMessages(location.state.messages);
        } else {
            setMessages(storedMessages);
        }
    }, [location.state]);

    // Clear messages when the session ID is null, indicating session has been cleared
    useEffect(() => {
        if (mapSessionId === null) {
            setMessages([]);
            sessionStorage.removeItem('mapMessages');
        }
    }, [mapSessionId]);

    return (
        <div className="map-chat-panel">
            <MapChatMessageList messages={messages} />
            <MapChatInputBox addMessage={addMessage} inputText={inputText} setInputText={setInputText} />
        </div>
    );
};

export default MapChatPanel;
