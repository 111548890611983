import React from 'react';
import './LandingPageStyles.css';
import logo from '../../ReusableComponents/Logo.svg';
import Button from '../../ReusableComponents/Buttons/Button';
import { useNavigate } from 'react-router-dom';

function LandingPage() {
    const navigate = useNavigate();
    return (
        <div className="landing-page">
            <img src={logo} alt="Logo" className="landing-logo" />
            <div className="landing-container">
                <div className="welcome-text">
                    Welcome to Comovis, please login or create an account to get started.
                </div>
                <div className="buttons-container">
                    <Button text="Login" className="button" onClick={() => navigate('/login')} />
                    <Button text="Create an account" className="button" onClick={() => navigate('/signup')} />
                </div>
            </div>
        </div>
    );
}

export default LandingPage;
