import { UserUpgradedEmail } from './UserUpgradedEmail';  // Import your premium email template
import { render } from '@react-email/render';
import axios from 'axios';

export const triggerPremiumUpgradeEmail = async (email, accessToken) => {
  // Render the JSX to HTML
  const html = render(<UserUpgradedEmail />, {
    pretty: true,
  });

  // Determine the appropriate API URL based on the environment
  const apiUrl = process.env.NODE_ENV === 'development' 
    ? process.env.REACT_APP_API_URL 
    : process.env.REACT_APP_PRODUCTION_API_URL;

  try {
    // Post request to your server endpoint for triggering Premium upgrade email
    await axios.post(`${apiUrl}/triggerPremiumUpgradeEmail`,  
      { email, html },
      {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${accessToken}`
        }
      }
    );
    console.log("Premium upgrade email sent successfully");
    return true;
  } catch (error) {
    console.error("Failed to send premium upgrade email:", error);
    return false;
  }
};
