// ResetContext.js
import React, { createContext, useState, useCallback } from 'react';

export const ResetContext = createContext();

export const ResetProvider = ({ children }) => {
    const [reset, setReset] = useState(false);

    const triggerReset = useCallback(() => {
        setReset(true);
        setTimeout(() => setReset(false), 0); // Reset the reset state after triggering it
    }, []);

    return (
        <ResetContext.Provider value={{ reset, triggerReset }}>
            {children}
        </ResetContext.Provider>
    );
};
