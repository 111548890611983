import axios from 'axios';
import { loadStripe } from '@stripe/stripe-js';

// Load Stripe with your public key
const stripePromise = loadStripe("pk_live_51NGUQDFjMxLGMf3cqK1TktCR0Mdx6sfDX7LDiIWRxMT6ijMb7e0MS9f3pM6mcaD9QsOKuNIl2qvhVEH7mTE8rg5B00PnvpZGaT");

// Determine the appropriate API URL based on the environment
const apiUrl = process.env.NODE_ENV === 'development' 
    ? process.env.REACT_APP_API_URL 
    : process.env.REACT_APP_PRODUCTION_API_URL;

const initiateUpgrade = async (userId, session) => {
  try {
    console.log("Initiating upgrade process for userId:", userId);
    console.log("Session Object:", session);

    if(!session || !session.access_token) {
      console.error("Session object is not properly defined or missing access_token.");
      return;
    }
    
    // Define the checkout session URL using the apiUrl variable
    const checkoutSessionURL = `${apiUrl}/create-checkout-session`;

    // Step 1: Request a session ID from your backend
    console.log("About to send POST request to create checkout session.");
    const response = await axios.post(checkoutSessionURL, {
      userId: userId,
    }, {
      headers: {
        'Authorization': `Bearer ${session.access_token || session.user.access_token}`,
        'Content-Type': 'application/json',
      },
    });
    
    console.log("Received response:", response.data);
    const { sessionId } = response.data;

    // Step 2: Redirect to Stripe Checkout
    console.log("About to initialize Stripe and redirect.");
    const stripe = await stripePromise;
    const { error } = await stripe.redirectToCheckout({
      sessionId,
    });

    if (error) {
      console.error("Error redirecting to Stripe checkout:", error);
    } else {
      console.log("Successfully redirected to Stripe checkout.");
    }
  } catch (err) {
    console.error("Failed to initiate subscription upgrade:", err);
  }
};

export { initiateUpgrade };
