import React, { useState, useEffect, useContext, useRef } from 'react';
import { Link } from 'react-router-dom';
import './UserAccount.css';
import { ReactComponent as AccountDefaultAvatar } from './AccountDefaultAvatar.svg';
import { supabase } from '../../Auth/SupabaseAuth/SupabaseClient';
import { UserContext } from '../../Auth/UserAuth/UserContext';

function AccountPage() {
    const [userData, setUserData] = useState({});
    const fileInputRef = useRef(null); 
    const { session, avatarURL, setAvatarURL } = useContext(UserContext);
    const [avatarKey, setAvatarKey] = useState(Date.now()); // Add a key for the avatar

    useEffect(() => {
        if (session) {
            async function fetchUserProfile() {
                const { data, error } = await supabase
                    .from('userprofiles')
                    .select('*')
                    .eq('user_id', session.user.id);

                if (error) {
                    console.error('Error fetching user profile:', error);
                    return;
                }

                if (data.length > 0) {
                    setUserData(data[0]);

                    const baseAvatarUrl = "https://skyxbncllurrcsexskvy.supabase.co/storage/v1/object/public/user-avatars";

                    if (data[0].avatar_path) {
                        const avatarUrl = `${baseAvatarUrl}/${data[0].avatar_path}`;
                        setAvatarURL(avatarUrl);
                    }
                }
            }

            fetchUserProfile();
        }
    }, [session, setAvatarURL]);

    // File upload handler
    const handleFileChange = async (e) => {
        const file = e.target.files[0];
        if (file) {
            try {
                console.log("File selected:", file);
                const fileExtension = file.type.split("/")[1];
                const filePath = `${session.user.id}/avatar.${fileExtension}`;

                // Check if an avatar already exists and delete it if it does
                if (userData.avatar_path) {
                    console.log("Deleting existing avatar:", userData.avatar_path);
                    const { error: deleteError } = await supabase.storage.from('user-avatars').remove([userData.avatar_path]);
                    if (deleteError) {
                        console.error("Error deleting existing image:", deleteError);
                        return; // Don't continue if deletion failed
                    }
                }

                // Upload the new image
                console.log("Uploading new image to:", filePath);
                const { data, error: uploadError } = await supabase.storage.from('user-avatars').upload(filePath, file);
                if (uploadError) {
                    console.error("Error uploading image:", uploadError);
                    return;
                }
                console.log("File uploaded:", data);

                // Save the file path to the user profile in Supabase
                console.log("Updating user profile with avatar path:", filePath);
                const { error: updateError } = await supabase
                    .from('userprofiles')
                    .update({ avatar_path: filePath })
                    .eq('user_id', session.user.id);

                if (updateError) {
                    console.error("Error updating user profile with avatar path:", updateError);
                    return;
                }

                // Construct the public URL for the uploaded image
                const publicURL = `https://skyxbncllurrcsexskvy.supabase.co/storage/v1/object/public/user-avatars/${filePath}`;

                console.log("Avatar URL set to:", publicURL);
                setAvatarURL(publicURL);
                setAvatarKey(Date.now()); // Update the key to force re-render

            } catch (error) {
                console.error("Error handling file change:", error);
            }
        } else {
            console.log("No file selected.");
        }
    };

    return (
        <div className="account-main-container">
            <div className="account-header-container">
                <h1>My Account</h1>
            </div>
            <div className="account-content-container">
                <div className="account-avatar-container">
                    {avatarURL ? (
                        <img key={avatarKey} src={avatarURL} alt="User Avatar" className="user-avatar-img" />
                    ) : (
                        <AccountDefaultAvatar className="AccountDefaultAvatar" />
                    )}
                    <div
                        className="avatar-overlay"
                        onClick={() => fileInputRef.current.click()}
                    >
                        Change
                    </div>
                    <input
                        type="file"
                        style={{ display: 'none' }}
                        ref={fileInputRef}
                        onChange={handleFileChange}
                    />
                </div>
                <div className="account-form-container">
                    <div className="detail-inputs">
                        <label className="account-input-label">
                            First Name
                            <input type="text" className="account-input-field" value={userData.first_name || ''} readOnly disabled />
                        </label>
                        <label className="account-input-label">
                            Last Name
                            <input type="text" className="account-input-field" value={userData.last_name || ''} readOnly disabled />
                        </label>
                    </div>
                    <div className="detail-inputs">
                        <label className="account-input-label">
                            Email Address
                            <input type="email" className="account-input-field" value={userData.email || ''} readOnly disabled />
                        </label>
                        <label className="account-input-label">
                            Company Name
                            <input type="text" className="account-input-field" value={userData.company_name || ''} readOnly disabled />
                        </label>
                    </div>
                    <div className="plans-membership-container">
                        <h2>Plans & Membership</h2>
                        <Link to="/dashboard/upgrade-plan" className="view-plans-link">View Plans</Link>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default AccountPage;
