
//Repsonsible for triggering the email reset link

import { useEffect, useState } from 'react';
import { supabase } from '../../Auth/SupabaseAuth/SupabaseClient';
import { useNavigate } from 'react-router-dom';
import logo from '../../ReusableComponents/Logo.svg';
import Button from '../../ReusableComponents/Buttons/Button';
import './PasswordResetEmail.css';



function PasswordResetTrigger() {
    const [email, setEmail] = useState('');
    const [message, setMessage] = useState('');
    const [resetAttempts, setResetAttempts] = useState(0);
    const maxAttempts = 3;
    const navigate = useNavigate();




    const isValidEmail = (email) => {
        // Simple regex for basic email validation
        return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
    };



const handleSendEmail = async () => {
    console.log("handleSendEmail triggered");

    if (!isValidEmail(email)) {
        setMessage("Please enter a valid email address.");
        return;
    }

    if (resetAttempts >= maxAttempts) {
        setMessage("You've reached the maximum number of reset attempts. Please try again later.");
        return;
    }

    // Determine the appropriate URL based on the environment
    const env = process.env.NODE_ENV;
    let redirectToURL;
    if (env === 'development') {
        redirectToURL = 'http://localhost:3000/forgot-password';
    } else if (env === 'production') {
        redirectToURL = 'https://app.comovis.ai/forgot-password';
    }

    // Adding the redirectTo parameter
    const { error } = await supabase.auth.resetPasswordForEmail(email, {
        redirectTo: redirectToURL
    });

    if (error) {
        if (error.message.includes('not found')) {
            setMessage("This email is not registered. Please check your input or sign up.");
        } else {
            setMessage(`Failed to send password reset email: ${error.message}`);
        }
    } else {
        setMessage('Password reset email sent! Please check your email.');
        setResetAttempts(prevAttempts => prevAttempts + 1);
    }
};



    return (
        <div className="password-reset-trigger-page">
            <img src={logo} alt="Logo" className="password-reset-trigger-logo" />

            <p className="password-reset-trigger-instructions">
                Please enter your email address. We will send you a link to reset your password.
            </p>

            <div className="password-reset-trigger-form">
                <label className="password-reset-trigger-input-label">
                    Email
                    <input
                        className="password-reset-trigger-input-field"
                        type="email"
                        placeholder="Enter email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                    />
                </label>

                <div className="password-reset-trigger-buttons">
                    <Button text="Reset my password" className="password-reset-trigger-send-button" onClick={handleSendEmail} />
                    <Button text="Cancel" className="password-reset-trigger-cancel-button" onClick={() => navigate('/login')} />
                </div>
            </div>

            {message && <p className="password-reset-trigger-message">{message}</p>}
        </div>
    );
}

export default PasswordResetTrigger;
