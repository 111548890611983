import React, { useEffect, useContext, useState } from 'react';
import { useNavigate, useLocation, useParams } from 'react-router-dom';
import { SessionContext } from '../../Auth/UserAuth/SessionContext';
import { MapSessionContext } from '../../Auth/UserAuth/MapSessionContext';
import { UserContext } from '../../Auth/UserAuth/UserContext';
import { ChatContext } from '../../Auth/UserAuth/ChatContext';
import { NotesContext } from '../../Auth/UserAuth/NotesContext';
import { useDocument } from '../../Auth/UserAuth/DocumentContext'; 
import { supabase } from '../../Auth/SupabaseAuth/SupabaseClient';
import { trackEvent } from '../../Auth/UserAnalytics/MixPanelConfig';
import LoadingComponent from '../../ReusableComponents/LoadingComponent';

const fetchLatestVesselData = async (sessionId, imo, accessToken) => {
    if (!accessToken) {
        throw new Error('Access token is missing.');
    }

    const apiUrl = `${process.env.REACT_APP_API_URL}/enhanced-vsl-port-data`;
    const response = await fetch(apiUrl, {
        method: 'POST',
        headers: { 
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${accessToken}`
        },
        body: JSON.stringify({ sessionId, imo })
    });

    if (!response.ok) {
        throw new Error('Failed to fetch latest vessel data');
    }

    const data = await response.json();
    return data;
};

function LoadSession({ sessionId: propSessionId, session }) {
    const { sessionId: urlSessionId } = useParams(); // Get sessionId from URL if available
    const navigate = useNavigate();
    const location = useLocation();
    const [loading, setLoading] = useState(true); // Add loading state

    const {
        setSessionId,
        setFilePath,
        setIsRestoringSession
    } = useContext(SessionContext);

    const {
        setMapSessionId,
        setMapMessages,
        updateLat,
        updateLon,
        updateName,
        updateTypeSpecific,
        updateDepPortCoords,
        updateDestPortCoords,
        updateTrackHistory,
        updateCourse,
        updateHeading,
        updateIMO,
        updateMMSI
    } = useContext(MapSessionContext);

    const { accessToken } = useContext(UserContext);
    const { setMessages } = useContext(ChatContext);
    const { saveNoteToState } = useContext(NotesContext);
    const { setDocumentId, setDocumentState, setBolDetails } = useDocument(); // Import setBolDetails

    const activeSessionId = propSessionId || urlSessionId; // Use sessionId from props or URL

    useEffect(() => {
        if (!activeSessionId) {
            console.error('Session ID is missing.');
            setLoading(false);
            return;
        }

        trackEvent("Session Loaded", {
            sessionId: activeSessionId,
            userId: session?.user?.id
        });

        const extractFileTypeFromPath = (filePath) => filePath.split('.').pop();

        const loadSession = async () => {
            setLoading(true); // Start loading

            const { data: sessionData, error } = await supabase
                .from('sessions')
                .select('*')
                .eq('id', activeSessionId)
                .single();

            if (error) {
                console.error(`Failed to load session with id ${activeSessionId}:`, error);
                setLoading(false); // End loading on error
                return;
            }

            if (sessionData.session_type === 'doc-intel') {
                setSessionId(sessionData.id);
                sessionStorage.setItem('sessionId', sessionData.id);

                const { data: documentData, error: documentError } = await supabase
                    .from('documents')
                    .select('*')
                    .eq('id', sessionData.document_id)
                    .single();

                if (documentError) {
                    console.error(`Failed to load document with id ${sessionData.document_id}:`, documentError);
                    setLoading(false); // End loading on error
                    return;
                }

                sessionStorage.setItem('documentId', sessionData.document_id);
                setDocumentId(sessionData.document_id);

                const fileType = extractFileTypeFromPath(documentData.file_path);
                setFilePath(documentData.file_path);
                setIsRestoringSession(true);
                setDocumentState({ file: null, url: documentData.url, fileType });

                // Check if the document is a BOL
                if (documentData.document_type === 'BOL' && documentData.extracted_data) {
                    setBolDetails(documentData.extracted_data);  // Set BOL details in the DocumentContext
                }

                const { data: messagesData, error: messagesError } = await supabase
                    .from('messages')
                    .select('*')
                    .eq('session_id', activeSessionId);

                if (messagesError) {
                    console.error(`Failed to load messages for session id ${activeSessionId}:`, messagesError);
                    setLoading(false); // End loading on error
                    return;
                }

                const formattedMessages = messagesData.map(message => ({
                    text: message.message,
                    type: message.message_type === 'question' ? 'user' : 'assistant'
                }));

                setMessages(formattedMessages);

                const { data: notesData, error: notesError } = await supabase
                    .from('notes')
                    .select('*')
                    .eq('session_id', activeSessionId);

                if (notesError) {
                    console.error(`Failed to load notes for session id ${activeSessionId}:`, notesError);
                } else {
                    notesData.forEach(note => {
                        saveNoteToState(note);
                    });
                }

                setLoading(false); // End loading before navigation
                if (location.pathname !== "/doc-intel") {
                    navigate('/doc-intel');
                }
            } else if (sessionData.session_type === 'map') {
                setMapSessionId(sessionData.id);
                sessionStorage.setItem('mapSessionId', sessionData.id);

                const { data: mapMessagesData, error: mapMessagesError } = await supabase
                    .from('map_explore_messages')
                    .select('*')
                    .eq('session_id', activeSessionId);

                if (mapMessagesError) {
                    console.error(`Failed to load map messages for session id ${activeSessionId}:`, mapMessagesError);
                    setLoading(false); // End loading on error
                    return;
                }

                const formattedMapMessages = mapMessagesData.map(message => ({
                    text: message.message,
                    type: message.message_type === 'question' ? 'user' : 'assistant'
                }));

                setMapMessages(formattedMapMessages);

                if (sessionData.lat) updateLat(sessionData.lat);
                if (sessionData.lon) updateLon(sessionData.lon);
                if (sessionData.vessel_name) updateName(sessionData.vessel_name);
                if (sessionData.vsl_type_specific) updateTypeSpecific(sessionData.vsl_type_specific);
                if (sessionData.departure_port_coords) updateDepPortCoords(sessionData.departure_port_coords);
                if (sessionData.destination_port_coords) updateDestPortCoords(sessionData.destination_port_coords);
                if (sessionData.course) updateCourse(sessionData.course);
                if (sessionData.heading) updateHeading(sessionData.heading);
                if (sessionData.imo) {
                    sessionStorage.setItem('imo', sessionData.imo);
                    updateIMO(sessionData.imo);
                }
                if (sessionData.mmsi) {
                    sessionStorage.setItem('mmsi', sessionData.mmsi);
                    updateMMSI(sessionData.mmsi);
                }

                if (!accessToken) {
                    console.error('Access token is missing.');
                    setLoading(false); // End loading on error
                    return;
                }

                try {
                    const imo = sessionData.imo;
                    const latestVesselData = await fetchLatestVesselData(activeSessionId, imo, accessToken);
                    updateLat(latestVesselData.lat);
                    updateLon(latestVesselData.lon);
                    updateName(latestVesselData.name);
                    updateTypeSpecific(latestVesselData.type_specific);
                    updateDepPortCoords(latestVesselData.depPortCoords);
                    updateDestPortCoords(latestVesselData.destPortCoords);
                    updateTrackHistory(latestVesselData.trackHistory);
                    updateCourse(latestVesselData.course);
                    updateHeading(latestVesselData.heading);
                } catch (error) {
                    console.error('Failed to fetch latest vessel data:', error);
                }

                setLoading(false); // End loading before navigation
                if (location.pathname !== "/explore") {
                    navigate('/explore', { state: { messages: formattedMapMessages } });
                }
            }
        };

        loadSession();

    }, [activeSessionId, navigate, setSessionId, setMapSessionId, setFilePath, setDocumentState, setMessages, setMapMessages, session, location, setIsRestoringSession, saveNoteToState, updateLat, updateLon, updateName, updateTypeSpecific, updateDepPortCoords, updateDestPortCoords, updateTrackHistory, updateCourse, updateHeading, updateIMO, updateMMSI, accessToken, setDocumentId, setBolDetails]);

    return loading ? <LoadingComponent /> : null; // Display loading indicator if loading
}

export default LoadSession;
