import React, { useState, useContext, useEffect } from 'react';
import { Mail, Copy, Send, Sparkles } from 'lucide-react';
import ReactMarkdown from 'react-markdown';
import './DraftEmailAI.css';
import Button from '../../../ReusableComponents/Buttons/Button';
import { UserContext } from '../../../Auth/UserAuth/UserContext'; 
import promptTemplates from './EmailAITemplates';  // Importing the email templates





const EmailUI = ({ subject, content, onCopySubject, onCopyContent, onEdit }) => {
  return (
    <div className="email-ui-container">
      <div className="email-ui-header">
        <Mail className="email-ui-icon" size={20} />
        <h2 className="email-ui-subject">{subject}</h2>
        <Button onClick={onCopySubject} className="button-black" text={<Copy size={16} />} size="medium" title="Copy subject" />
      </div>
      <div className="email-ui-body">
        <ReactMarkdown className="email-ui-content">{content}</ReactMarkdown>
      </div>
      <div className="email-ui-footer">
        <Button onClick={onEdit} text="Edit" size="medium" className="button-black" />
        <div className="custom-button-position">
          <Button
            onClick={onCopyContent}
            className="button-black"
            text={
              <>
                <Copy size={16} className="custom-xx-copy-icon" /> Copy email
              </>
            }
            size="medium"
            title="Copy email content"
          />
        </div>
      </div>
    </div>
  );
};

function DraftEmailAI() {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [step, setStep] = useState(1);
  const [selectedTemplate, setSelectedTemplate] = useState('');
  const [context, setContext] = useState('');
  const [generatedSubject, setGeneratedSubject] = useState('');
  const [generatedContent, setGeneratedContent] = useState('');
  const [copiedSubject, setCopiedSubject] = useState(false);
  const [copiedContent, setCopiedContent] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const { session, userPersona: contextUserPersona } = useContext(UserContext); // Access session and persona from UserContext

  // Check if userPersona exists in local storage
  const localStorageUserPersona = localStorage.getItem('userPersona');
  const userPersona = contextUserPersona || localStorageUserPersona || '';

  useEffect(() => {
    console.log("User Persona on Load:", userPersona);  // Log the user persona on component load
    console.log("Prompt Templates Object:", promptTemplates);  // Log the entire templates object to ensure it's imported correctly
  }, [userPersona]);

  // Normalize userPersona to match keys in promptTemplates (e.g., converting to PascalCase)
  const normalizedPersona = userPersona
    ? userPersona.split('_').map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()).join('')
    : '';

  const personaTemplates = normalizedPersona ? promptTemplates[normalizedPersona] : null;

  if (!personaTemplates) {
    console.error(`No templates found for persona: ${userPersona}`);
  } else {
    console.log("Templates for Persona:", personaTemplates);
  }

  const openModal = () => {
    setIsModalOpen(true);
    setStep(1);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setStep(1);
    setSelectedTemplate('');
    setContext('');
    setGeneratedSubject('');
    setGeneratedContent('');
  };

  const handleTemplateChange = (templateId) => {
    console.log("Template selected:", templateId);
    setSelectedTemplate(templateId);
    setStep(2); // Automatically move to the next step when a template is selected
  };

  const handleContextChange = (e) => setContext(e.target.value);

  const generateEmail = async () => {
    const API_URL = process.env.NODE_ENV === 'production'
      ? process.env.REACT_APP_PRODUCTION_API_URL
      : process.env.REACT_APP_API_URL;

    const selected = personaTemplates?.find(template => template.id === selectedTemplate);
    if (!selected) {
      console.error("No selected template found or template not valid.");
      return;
    }

    console.log("Generating email for selected template:", selected);

    setIsLoading(true);

    try {
      const response = await fetch(`${API_URL}/generate-email`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${session.accessToken}` // Use access token from session
        },
        body: JSON.stringify({
          userPersona,          // Pass the user persona
          prompt: selected.prompt,  // Use the prompt from the selected template
          context              // Pass the user-provided context
        }),
      });

      if (!response.ok) {
        throw new Error('Failed to generate email content');
      }

      const data = await response.json();
      setGeneratedSubject(data.subject); // Set the generated subject from the API response
      setGeneratedContent(data.content); // Set the generated content from the API response
      setStep(3);
    } catch (error) {
      console.error("Error generating email:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const copyToClipboard = (text) => {
    navigator.clipboard.writeText(text);
  };

  const handleCopySubject = () => {
    copyToClipboard(generatedSubject);
    setCopiedSubject(true);
    setTimeout(() => setCopiedSubject(false), 2000);
  };

  const handleCopyContent = () => {
    copyToClipboard(generatedContent);
    setCopiedContent(true);
    setTimeout(() => setCopiedContent(false), 2000);
  };

  const renderStep = () => {
    if (!personaTemplates) {
      return <p>No templates available for the current persona.</p>;
    }

    switch (step) {
      case 1:
        return (
          <div className="draft-email-template-selection">
            <h2 className="draft-email-modal-title">Select an Email Template</h2>
            <p className="draft-email-modal-description">
              Choose a template to start drafting your email. We'll use AI to help you create a personalized message.
            </p>
            <ul className="draft-email-template-list">
              {personaTemplates.map(template => (
                <li 
                  key={template.id}
                  className={`draft-email-template-item ${selectedTemplate === template.id ? 'selected' : ''}`} 
                  onClick={() => handleTemplateChange(template.id)}
                >
                  <div className="draft-email-template-name">{template.title}</div>
                  <div className="draft-email-template-description">{template.description}</div>
                </li>
              ))}
            </ul>
          </div>
        );
      case 2:
        return (
          <>
            <h2 className="draft-email-modal-title">Provide Additional Context</h2>
            <textarea
              value={context}
              onChange={handleContextChange}
              placeholder="Include details such as the client's or recipient's name, specifics about previous issues, successes, negotiations, deadlines, key problems, objectives, or any other relevant information that the AI can use to draft the best communication."
              className="draft-email-context-input"
            />
            <Button 
              onClick={generateEmail} 
              text={
                <>
                  <Sparkles className="generate-email-sparkles-icon" />
                  {isLoading ? "Generating..." : "Generate Email"}
                </>
              } // Update text with sparkles icon
              size="medium" 
              className="button-black draft-email-generate-button" 
              disabled={!context || isLoading} // Disable button while loading
            />
            <Button 
              onClick={() => setStep(1)} 
              text="Back" 
              size="medium" 
              className="button-black draft-email-back-button" 
              style={{ width: "100%", marginTop: "10px" }} // Adjust the back button width and margin
            />
          </>
        );
      case 3:
        return (
          <>
            <h2 className="draft-email-modal-title">Generated Email</h2>
            <EmailUI 
              subject={generatedSubject}
              content={generatedContent}
              onCopySubject={handleCopySubject}
              onCopyContent={handleCopyContent}
              onEdit={() => setStep(2)}
            />
          </>
        );
      default:
        return null;
    }
  };

  return (
    <div className="draft-email-ai">
      <Button 
        onClick={openModal} 
        text={<><Send className="mr-send-button" size={16} /> Generate Email</>}
        size="medium" 
        className="button-black draft-email-button" 
      />
      
      {isModalOpen && (
        <div className="draft-email-modal">
          <div className={`draft-email-modal-content ${step === 3 ? 'large' : ''}`}>
            <button className="draft-email-close-button" onClick={closeModal}>&times;</button>
            {renderStep()}
          </div>
        </div>
      )}
    </div>
  );
}

export default DraftEmailAI;
