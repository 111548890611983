import { jsPDF } from 'jspdf';
import * as XLSX from 'xlsx';

export const handleExportToPDF = (bolDetails) => {
    const doc = new jsPDF();

    let y = 10;
    doc.setFontSize(18);
    doc.text('Extracted Document Data', 10, y);
    y += 10;

    doc.setFontSize(12);
    Object.entries(bolDetails).forEach(([sectionTitle, sectionData]) => {
        doc.text(sectionTitle, 10, y);
        y += 7;

        Object.entries(sectionData).forEach(([key, value]) => {
            const line = `${key}: ${value}`;
            doc.text(line, 10, y);
            y += 7;
        });

        y += 5;
    });

    doc.save('document-data.pdf');
    console.log("Exported to PDF");
};

export const handleExportToCSV = (bolDetails) => {
    const csvRows = [];
    const headers = ['Section', 'Field', 'Value'];
    csvRows.push(headers.join(',')); // Add header row

    Object.entries(bolDetails).forEach(([sectionTitle, sectionData]) => {
        Object.entries(sectionData).forEach(([key, value]) => {
            const row = [sectionTitle, key, value];
            csvRows.push(row.join(','));
        });
    });

    const csvString = csvRows.join('\n');
    const blob = new Blob([csvString], { type: 'text/csv;charset=utf-8;' });
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = 'document-data.csv';
    a.click();
    console.log("Exported to CSV");
};

export const handleExportToXLSX = (bolDetails) => {
    const data = [];

    Object.entries(bolDetails).forEach(([sectionTitle, sectionData]) => {
        Object.entries(sectionData).forEach(([key, value]) => {
            data.push({
                Section: sectionTitle,
                Field: key,
                Value: value,
            });
        });
    });

    const worksheet = XLSX.utils.json_to_sheet(data);

    // Add headers to the worksheet
    const headers = ['Section', 'Field', 'Value'];
    XLSX.utils.sheet_add_aoa(worksheet, [headers], { origin: 'A1' });

    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Document Data');

    // Adjust column widths for better readability (optional)
    worksheet['!cols'] = [
        { wch: 20 }, // Width of the 'Section' column
        { wch: 30 }, // Width of the 'Field' column
        { wch: 50 }  // Width of the 'Value' column
    ];

    XLSX.writeFile(workbook, 'document-data.xlsx');
    console.log("Exported to XLSX");
};

export const handleExportToJSON = (bolDetails) => {
    const jsonString = JSON.stringify(bolDetails, null, 2); // Convert object to JSON string with indentation
    const blob = new Blob([jsonString], { type: 'application/json' });
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = 'document-data.json';
    a.click();
    console.log("Exported to JSON");
};

export const handleExportToXML = (bolDetails) => {
    const convertToXML = (obj) => {
        let xml = '';

        if (typeof obj === 'object') {
            Object.entries(obj).forEach(([key, value]) => {
                const elementName = key.replace(/ /g, '_'); // Replace spaces with underscores
                xml += `<${elementName}>`;
                if (typeof value === 'object') {
                    xml += convertToXML(value);
                } else {
                    xml += value;
                }
                xml += `</${elementName}>`;
            });
        }
        return xml;
    };

    const xmlString = `<BOLDetails>${convertToXML(bolDetails)}</BOLDetails>`;
    const blob = new Blob([xmlString], { type: 'application/xml' });
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = 'document-data.xml';
    a.click();
    console.log("Exported to XML");
};
