
// UserChatMessageItem.js


import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import './MapUserChatMessageItem.css'; // Importing the CSS
import { ReactComponent as ChatUserAvatarSVG } from '../../../ReusableComponents/Icons/ChatUserAvatar.svg';
import { UserContext } from '../../../Auth/UserAuth/UserContext';


const UserChatMessageItem = ({ text }) => {
    const { session, avatarURL } = useContext(UserContext);




    // Possible prefixes that should be bolded.
    const boldPrefixes = [
        "Can you elaborate further on the following:",
        "I would like more insights about the following:",
        "Could you provide references and page numbers related to the following:"
    ];

    let boldPart = '';
    let regularPart = text;

    // Find if the text starts with one of the bold prefixes.
    for (let prefix of boldPrefixes) {
        if (text.startsWith(prefix)) {
            boldPart = prefix;
            regularPart = text.slice(prefix.length);
            break;
        }
    }


    function ChatUserAvatar({ className, avatarURL }) {
        return (
            avatarURL ?
                <img src={avatarURL} alt="Chat User Avatar" className={className} />
                :
                <ChatUserAvatarSVG className={className} />
        );
    }

    ChatUserAvatar.propTypes = {
        className: PropTypes.string,
        avatarURL: PropTypes.string
    };





    return (
        <div className="map-user-chat-message-item">
            <div className="map-user-message-header">
                <ChatUserAvatar className="map-user-avatar-icon" avatarURL={avatarURL} />
                <p className="map-user-message-text">
                    <span className="bold-text">{boldPart}</span>{regularPart}
                </p>
            </div>
        </div>
    );
};

UserChatMessageItem.propTypes = {
    text: PropTypes.string.isRequired,
    avatarURL: PropTypes.string  // Adding avatarURL as a prop
};

export default UserChatMessageItem;
