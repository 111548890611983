import React, { useState, useContext, useEffect } from 'react';
import DocumentViewer from '../../Components/DocumentViewer/DocumentViewer';
import ChatPanel from '../../Components/ChatPanel/ChatPanel';
import ProcessedDocPanel from '../../Components/ProcessedDocPanel/ProcessedDocPanel';
import { SessionContext } from '../../Auth/UserAuth/SessionContext';
import { UserContext } from '../../Auth/UserAuth/UserContext';
import { useDemo } from '../../Auth/UserAuth/DemoContext';
import HeaderPanel from '../../Components/HeaderMenu/HeaderPanel';
import SidePanel from '../../Components/SidePanel/SidePanel';
import DemoModal from './DemoModal';
import DemoSignupModal from './DemoSignupModal';
import { useDocument } from '../../Auth/UserAuth/DocumentContext'; // Import useDocument
import LoadingComponent from '../../ReusableComponents/LoadingComponent'; 
import './DocIntel.css';

const TIMEOUT_LIMIT = 4 * 60 * 1000; // 4 minutes in milliseconds
const INTERACTION_LIMIT = 6; // 3 user messages (2 complete user queries and 1 additional)

function DocumentIntelligence() {
    const {
        sessionId,
        isRestoringSession,
        setIsRestoringSession,
        clearDocIntelSession,
        handleMenuItemClick,
        activeMenuItem,
    } = useContext(SessionContext);

    const { isDemo } = useDemo();
    const { session } = useContext(UserContext);
    const { showProcessedDocPanel } = useDocument(); // Use DocumentContext
    const [showDemoModal, setShowDemoModal] = useState(false);
    const [showSignupModal, setShowSignupModal] = useState(false);
    const [userMessageCount, setUserMessageCount] = useState(() => {
        return isDemo ? parseInt(localStorage.getItem('userMessageCount') || '0', 10) : 0;
    });
    const [timeElapsed, setTimeElapsed] = useState(() => {
        return isDemo ? parseInt(localStorage.getItem('timeElapsed') || '0', 10) : 0;
    });

    useEffect(() => {
        if (sessionId) {
            setIsRestoringSession(false);
        }
    }, [sessionId, setIsRestoringSession]);

    useEffect(() => {
        if (isDemo) {
            if (!sessionStorage.getItem('demoModalShown')) {
                setShowDemoModal(true);
                sessionStorage.setItem('demoModalShown', 'true');
            }

            const timer = setInterval(() => {
                setTimeElapsed(prev => {
                    const newTimeElapsed = prev + 1000;
                    localStorage.setItem('timeElapsed', newTimeElapsed);
                    if (newTimeElapsed >= TIMEOUT_LIMIT) {
                        setShowSignupModal(true);
                        clearInterval(timer);
                    }
                    return newTimeElapsed;
                });
            }, 1000);

            return () => clearInterval(timer);
        }
    }, [isDemo]);

    const handleNewMessage = () => {
        if (isDemo) {
            setUserMessageCount(prev => {
                const newCount = prev + 1;
                localStorage.setItem('userMessageCount', newCount);
                if (newCount >= INTERACTION_LIMIT) {
                    setShowSignupModal(true);
                }
                return newCount;
            });
        }
    };

    const handleClearSessionClick = () => {
        clearDocIntelSession();
        if (isDemo) {
            localStorage.removeItem('userMessageCount');
            localStorage.removeItem('timeElapsed');
        }
    };

    if (!session) {
        return <LoadingComponent loading={true} />;
    }

    return (
        <div className="doc-intel-container">
            <SidePanel activeMenuItem={activeMenuItem} handleMenuItemClick={handleMenuItemClick} />
            <div className="doc-intel-main">
                <HeaderPanel 
                    activeMenuItem={activeMenuItem} 
                    handleMenuItemClick={handleMenuItemClick} 
                    handleClearSessionClick={handleClearSessionClick} 
                />
                <div className="doc-intel-content">
                    <DocumentViewer className="document-viewer" />
                    {showProcessedDocPanel ? (
                        <ProcessedDocPanel className="processed-doc-panel" data={{}} /> // Replace {} with actual BOL data
                    ) : (
                        <ChatPanel 
                            className="chat-panel"
                            isRestoringSession={isRestoringSession} 
                            onNewMessage={handleNewMessage} 
                        />
                    )}
                </div>
            </div>
            {isDemo && showDemoModal && (
                <DemoModal 
                    showModal={showDemoModal} 
                    onClose={() => setShowDemoModal(false)} 
                    onSelectOption={() => setShowDemoModal(false)} 
                />
            )}
            {isDemo && showSignupModal && (
                <DemoSignupModal 
                    showModal={showSignupModal} 
                    onClose={() => setShowSignupModal(false)} 
                />
            )}
        </div>
    );
}

export default DocumentIntelligence;
