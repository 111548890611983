import React, { useState, useRef, useEffect, useContext } from 'react';
import TextInteractionHandler from '../TextInteraction/TextInteractionHandler';
import ContextMenu from '../TextInteraction/ContextMenu';
import { SessionContext } from '../../Auth/UserAuth/SessionContext';
import { UserContext } from '../../Auth/UserAuth/UserContext';
import './AIChatMessageItem.css';
import { ReactComponent as CopyClipboardIcon } from '../../ReusableComponents/Icons/CopyClipboardIcon.svg';
import { ReactComponent as TickIcon } from '../../ReusableComponents/Icons/TickIcon.svg';
import { ReactComponent as ComovisAIMessageIcon } from '../../ReusableComponents/Icons/ComovisAIMessageIcon.svg';
import { ReactComponent as ChatPositiveFeedback } from '../../ReusableComponents/Icons/ChatPositiveFeedback.svg';
import { ReactComponent as ChatNegativeFeedback } from '../../ReusableComponents/Icons/ChatNegativeFeedback.svg';
import { ReactComponent as ChatShareIcon } from '../../ReusableComponents/Icons/ChatShareIcon.svg';
import { ReactComponent as ChatEmailIcon } from '../../ReusableComponents/Icons/PromptEmailIcon.svg';
import { trackEvent } from '../../Auth/UserAnalytics/MixPanelConfig';
import { useDocument } from '../../Auth/UserAuth/DocumentContext';
import { NotesContext } from '../../Auth/UserAuth/NotesContext';
import { formatText } from './ChatUtils/FormatAIText';
import TooltipWrapper from '../../ReusableComponents/Tooltip/TooltipWrapper';
import ComovisDispatchModal from '../ComovisDispatch/ComovisDispatchModal';

const AIChatMessageItem = ({ text }) => {
    const [copySuccess, setCopySuccess] = useState(false);
    const [isFeedbackModalOpen, setFeedbackModalOpen] = useState(false);
    const [feedbackComment, setFeedbackComment] = useState('');
    const [feedbackCheckboxes, setFeedbackCheckboxes] = useState({
        accuracy: false,
        contextFocus: false,
        speed: false,
        userPersonaFocus: false,
    });
    const [isLoading, setIsLoading] = useState(false);
    const [isSubmitted, setIsSubmitted] = useState(false);
    const [feedbackType, setFeedbackType] = useState('');
    const [isDispatchModalOpen, setDispatchModalOpen] = useState(false);
    const containerRef = useRef(null);
    const contextMenuRef = useRef(null);
    const { setSelectedMenuText, setSelectedMenuOption } = useContext(SessionContext);
    const { session } = useContext(UserContext);
    const { setCurrentPage } = useDocument();
    const { notes, openNoteModal } = useContext(NotesContext);

    const [isMenuVisible, setMenuVisibility] = useState(false);
    const [selectedTextInfo, setSelectedTextInfo] = useState({
        text: '',
        position: { top: 0, left: 0 }
    });

    const handleTextSelected = (selectedText, rect) => {
        setSelectedTextInfo({
            text: selectedText,
            position: { top: rect.top + window.scrollY, left: rect.left + window.scrollX }
        });
        setMenuVisibility(true);
    };

    const handleMenuOptionSelect = (option, selectedText) => {
        if (["Tell me more", "Give me insights", "Give me references", "Add notes"].includes(option)) {
            setSelectedMenuText(selectedText);
            setSelectedMenuOption(option);
            trackEvent("Context Menu Click - Chat Panel", {
                action: option,
                selectedText: selectedText
            });
            if (option === "Add notes") {
                openNoteModal(selectedText, selectedTextInfo.position, selectedText);
            }
        }
        setMenuVisibility(false);
    };

    const copyToClipboard = async () => {
        try {
            const textToCopy = new DOMParser().parseFromString(text, 'text/html').body.textContent || '';
            await navigator.clipboard.writeText(textToCopy);
            setCopySuccess(true);

            setTimeout(() => {
                setCopySuccess(false);
            }, 2000);
        } catch (error) {
            console.error('Failed to copy message:', error);
        }
    };

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (contextMenuRef.current && !contextMenuRef.current.contains(event.target)) {
                setMenuVisibility(false);
            }
        };

        document.addEventListener('mousedown', handleClickOutside);

        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    useEffect(() => {
        const handleClick = (event) => {
            const noteId = event.target.getAttribute('data-note-id');
            if (noteId) {
                const noteText = event.target.innerText;
                const note = notes.find(n => n.notesMessageId === noteId);
                if (note) {
                    openNoteModal(noteText, { top: '50%', left: '50%' }, note.text, noteId);
                }
            }
        };

        if (containerRef.current) {
            containerRef.current.addEventListener('click', handleClick);
        }

        return () => {
            if (containerRef.current) {
                containerRef.current.removeEventListener('click', handleClick);
            }
        };
    }, [notes, openNoteModal]);

    useEffect(() => {
        const handlePageNumberClick = (event) => {
            if (event.target.classList.contains('page-number')) {
                const page = event.target.getAttribute('data-page');
                setCurrentPage(Number(page));
            }
        };

        if (containerRef.current) {
            containerRef.current.addEventListener('click', handlePageNumberClick);
        }

        return () => {
            if (containerRef.current) {
                containerRef.current.removeEventListener('click', handlePageNumberClick);
            }
        };
    }, [setCurrentPage]);

    const handleFeedbackClick = (type) => {
        setFeedbackType(type);
        setFeedbackModalOpen(true);
    };

    const handleFeedbackCommentChange = (event) => {
        setFeedbackComment(event.target.value);
    };

    const handleCheckboxChange = (event) => {
        const { name, checked } = event.target;
        setFeedbackCheckboxes((prev) => ({
            ...prev,
            [name]: checked,
        }));
    };

    const handleFeedbackSubmit = async () => {
        if (!session || !session.access_token) {
            console.error('No session or access token available');
            return;
        }

        setIsLoading(true);
        let comment = feedbackComment;
        if (feedbackType === 'positive') {
            comment = Object.keys(feedbackCheckboxes)
                .filter(key => feedbackCheckboxes[key])
                .join(', ');
        }

        const feedbackData = {
            feedback_type: feedbackType,
            comment: comment,
            issues: null,
            session_type: 'doc-intel'
        };

        const API_URL = process.env.NODE_ENV === 'production'
            ? process.env.REACT_APP_PRODUCTION_API_URL
            : process.env.REACT_APP_API_URL;

        try {
            const response = await fetch(`${API_URL}/submit-feedback`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${session.access_token}`
                },
                body: JSON.stringify(feedbackData),
            });

            if (response.ok) {
                setIsSubmitted(true);
                setTimeout(() => {
                    setFeedbackModalOpen(false);
                    setIsSubmitted(false);
                    setFeedbackComment('');
                    setFeedbackCheckboxes({
                        accuracy: false,
                        contextFocus: false,
                        speed: false,
                        userPersonaFocus: false,
                    });
                }, 1500);
            } else {
                console.error('Failed to submit feedback');
            }
        } catch (error) {
            console.error('Error submitting feedback:', error);
        } finally {
            setIsLoading(false);
        }
    };

    const handleCloseModal = () => {
        setFeedbackModalOpen(false);
        setFeedbackComment('');
        setIsLoading(false);
        setIsSubmitted(false);
        setFeedbackCheckboxes({
            accuracy: false,
            contextFocus: false,
            speed: false,
            userPersonaFocus: false,
        });
    };

    const handleEmailIconClick = () => {
        setDispatchModalOpen(true);
    };


   
    return (
        <div className="ai-chat-message-item" ref={containerRef}>
            <TextInteractionHandler onTextSelected={handleTextSelected} containerRef={containerRef} />
            <div className="message-header">
                <ComovisAIMessageIcon className="app-icon" />
                <div className="ai-message-text">
                    {formatText(text, notes)} {/* Pass notes to formatText */}
                </div>
            </div>
            {isMenuVisible && (
                <ContextMenu
                    ref={contextMenuRef}
                    text={selectedTextInfo.text}
                    position={selectedTextInfo.position}
                    options={["Tell me more", "Give me insights", "Give me references", "Add notes"]}
                    onSelect={handleMenuOptionSelect}
                />
            )}
            <div className="icon-container-custom">
                <TooltipWrapper id="tooltip-positive-feedback" content="Give good feedback">
                    <ChatPositiveFeedback className="icon-custom icon-positive-feedback-custom" onClick={() => handleFeedbackClick('positive')} />
                </TooltipWrapper>
                <TooltipWrapper id="tooltip-negative-feedback" content="Give bad feedback">
                    <ChatNegativeFeedback className="icon-custom icon-negative-feedback-custom" onClick={() => handleFeedbackClick('negative')} />
                </TooltipWrapper>
                <TooltipWrapper id="tooltip-share" content="Share: Coming soon">
                    <ChatShareIcon className="icon-custom icon-share-custom" />
                </TooltipWrapper>
                <TooltipWrapper id="tooltip-email" content="Comovis Email Dispatch">
                    <ChatEmailIcon className="icon-custom icon-email-custom" onClick={handleEmailIconClick} />
                </TooltipWrapper>
                <TooltipWrapper id="tooltip-copy" content="Copy Contents">
                    {copySuccess ? <TickIcon className="success-copy" /> : <CopyClipboardIcon className="icon-copy" onClick={copyToClipboard} />}
                </TooltipWrapper>
            </div>

            {isDispatchModalOpen && (
                <ComovisDispatchModal 
                    isOpen={isDispatchModalOpen} 
                    onClose={() => setDispatchModalOpen(false)} 
                />
            )}

            {isFeedbackModalOpen && (
                <div className="feedback-overlay">
                    <div className="give-feedback-container">
                        <button className="feedback-close-button" onClick={handleCloseModal}>×</button>
                        {!isSubmitted ? (
                            <>
                                <div className="feedback-header">{feedbackType === 'positive' ? 'What are we doing great?' : 'What could we have done better?'}</div>
                                <div className="feedback-paragraph">
                                    {feedbackType === 'positive' ? 'Use the checkboxes to let us know what we are doing well, so we can double down.' : 'In a few words, tell us what went wrong and we\'ll use this feedback to fine-tune the AI model to perform tasks tailored to your workflow.'}
                                </div>
                                {feedbackType === 'positive' ? (
                                    <div className="feedback-checkboxes">
                                        <label>
                                            <input type="checkbox" name="accuracy" checked={feedbackCheckboxes.accuracy} onChange={handleCheckboxChange} />
                                            Accuracy
                                        </label>
                                        <label>
                                            <input type="checkbox" name="speed" checked={feedbackCheckboxes.speed} onChange={handleCheckboxChange} />
                                            Speed
                                        </label>
                                        <label>
                                            <input type="checkbox" name="contextFocus" checked={feedbackCheckboxes.contextFocus} onChange={handleCheckboxChange} />
                                            Context focus
                                        </label>
                                    </div>
                                ) : (
                                    <textarea
                                        className="feedback-comment"
                                        placeholder="Your feedback is much appreciated and helps improve our AI model and your custom user persona."
                                        value={feedbackComment}
                                        onChange={handleFeedbackCommentChange}
                                    ></textarea>
                                )}
                                <button
                                    className="feedback-submit-button"
                                    onClick={handleFeedbackSubmit}
                                    disabled={isLoading}
                                >
                                    {isLoading ? 'Submitting...' : 'Submit'}
                                </button>
                            </>
                        ) : (
                            <div className="submitted-message">
                                Submitted successfully, you can now close this and continue where you left off.
                            </div>
                        )}
                    </div>
                </div>
            )}
        </div>
    );
};

export default AIChatMessageItem;
