// ToastMessages.js

import React from 'react';
import { toast } from 'react-toastify';

// Your SVG components
import { ReactComponent as SignupSuccessToastSVG } from './SignupSuccessToast.svg';
import { ReactComponent as SignupErrorToastSVG } from './SignupErrorToast.svg';
import { ReactComponent as EmailConfirmedToastSVG } from './EmailConfirmedToast.svg';
import { ReactComponent as LoginFailedToastSVG } from './LoginFailedToast.svg';
import { ReactComponent as SessionClearedToastSVG } from './SessionClearedToast.svg';
import { ReactComponent as SessionRestoreToastSVG } from './SessionRestoreToast.svg';
import { ReactComponent as SessionDeletedToastSVG } from './SessionDeletedToast.svg';
import { ReactComponent as AllSessionsDeletedToastSVG } from './AllSessionsDeletedToast.svg';
import { ReactComponent as AutoSaveSessionToastSVG } from './AutoSaveSessionToast.svg';


// A custom Toast component
const CustomToast = ({ Icon }) => (
    <div>
        <Icon /> {/* Render the SVG */}
    </div>
);

const options = {
    position: 'top-right',
    autoClose: 2000,
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
};

export const SignupSuccessToast = () => {
    toast(<CustomToast Icon={SignupSuccessToastSVG} />, options);
};

export const SignupErrorToast = () => {
    toast(<CustomToast Icon={SignupErrorToastSVG} />, options);
};

export const EmailConfirmedToast = () => {
    toast(<CustomToast Icon={EmailConfirmedToastSVG} />, options);
};


export const LoginFailedToast = () => {
    toast(<CustomToast Icon={LoginFailedToastSVG} />, options);
};


export const SessionClearedToast = () => {
    toast(<CustomToast Icon={SessionClearedToastSVG} />, options);
};


export const SessionRestoreToast = () => {
    toast(<CustomToast Icon={SessionRestoreToastSVG} />, options);
};


export const SessionDeletedToast = () => {
    toast(<CustomToast Icon={SessionDeletedToastSVG} />, options);
};


export const AllSessionsDeletedToast = () => {
    toast(<CustomToast Icon={AllSessionsDeletedToastSVG} />, options);
};


export const AutoSaveSessionToast = () => {
    toast(<CustomToast Icon={AutoSaveSessionToastSVG} />, options);
};