import React, { useContext, useState, useEffect } from 'react';
import OverlayComponent from '../../ReusableComponents/OverlayComponent';
import './ComovisDispatchModal.css';
import Button from '../../ReusableComponents/Buttons/Button';
import { ChatContext } from '../../Auth/UserAuth/ChatContext';
import { SessionContext } from '../../Auth/UserAuth/SessionContext';

const ComovisDispatchModal = ({ isOpen, onClose }) => {
    const { setInputText, setIsEmailPrompt } = useContext(ChatContext);
    const [hasSubmitted, setHasSubmitted] = useState(false);

    useEffect(() => {
        if (isOpen) {
            setHasSubmitted(false);
        }
    }, [isOpen]);

    if (!isOpen) return null;

    const handleOptionClick = (text) => {
        if (hasSubmitted) return;
        setInputText(text);
        setIsEmailPrompt(true);
        setHasSubmitted(true);
        onClose();
    };

    const options = [
        {
            title: "Draft vessel availability update",
            description: "Compose an email on current vessel positions and upcoming availability",
            prompt: "Draft a vessel availability update email. Include current positions of our fleet and upcoming availability for the next month. Leverage insights from chat interactions to highlight any unique opportunities or challenges that may be relevant. If any part of the information is unclear or incomplete, leave those sections for the user to edit."
        },
        {
            title: "Prepare charter party negotiation",
            description: "Create an email with key terms for negotiation based on recent data or interactions",
            prompt: "Prepare an email for a charter party negotiation. List key terms we want to discuss, including rates, laytime, and any special clauses. Base your suggestions on recent market trends, our company's standard practices, and any insights extracted from chat history. Leave any areas of uncertainty for the user to refine."
        },
        {
            title: "Compose market report for client",
            description: "Write an email summarizing recent market trends and opportunities",
            prompt: "Compose a market report email for our key clients. Summarize recent trends in freight rates, vessel supply and demand, and any significant events affecting the shipping market. Incorporate relevant data and insights from chat context to provide a tailored outlook for the next quarter. Any unclear details should be left for the user to edit."
        },
        {
            title: "Draft fixture recap",
            description: "Prepare an email recapping agreed charter party terms for all parties",
            prompt: "Draft a fixture recap email. Include all key terms agreed upon in our recent charter party negotiation, such as vessel details, cargo particulars, laycan, freight rate, and any special clauses. Ensure the language is clear and concise, and reference any relevant points from the chat context. If any details are uncertain or missing, leave them for the user to complete."
        }
    ];

    return (
        <OverlayComponent onClose={onClose}>
            <div className="comovis-dispatch-content" onClick={e => e.stopPropagation()}>
                <h2 className="comovis-dispatch-title">Compose AI Generated Contextual Email</h2>
                <p className="comovis-dispatch-description">
                    We analyze your chat interactions with our AI and the selected use-case to extract key insights and generate tailored communications.
                </p>
                
                <ul className="comovis-dispatch-options-list">
                    {options.map((option, index) => (
                        <li 
                            key={index}
                            className={`comovis-dispatch-option-item ${hasSubmitted ? 'disabled' : ''}`} 
                            onClick={() => handleOptionClick(option.prompt)}
                        >
                            <div className="comovis-dispatch-option-title">{option.title}</div>
                            <div className="comovis-dispatch-option-description">{option.description}</div>
                        </li>
                    ))}
                </ul>

                <Button 
                    text="Close" 
                    color="black" 
                    size="small"
                    onClick={onClose} 
                    className="comovis-dispatch-close-button"
                />
            </div>
        </OverlayComponent>
    );
};

export default ComovisDispatchModal;
