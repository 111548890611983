import React, { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { UserContext } from '../../../Auth/UserAuth/UserContext';
import useFetchSessions from '../../UserAppSessions/UseFetchSessions';
import './RecentSessionsWidget.css';
import { ReactComponent as BOLTag } from '../../../ReusableComponents/Icons/BOLTag.svg';
import { ReactComponent as GeneralDocTag } from '../../../ReusableComponents/Icons/GeneralDocTag.svg';
import { ReactComponent as InvoiceTag } from '../../../ReusableComponents/Icons/InvoiceTag.svg';
import { ReactComponent as ContractTag } from '../../../ReusableComponents/Icons/ContractTag.svg';
import { ReactComponent as CertificateTag } from '../../../ReusableComponents/Icons/CertificateTag.svg';
import { ReactComponent as UploadPDFIcon } from '../../../ReusableComponents/Icons/UploadPDFIcon.svg';
import { ReactComponent as UploadCSVIcon } from '../../../ReusableComponents/Icons/UploadCSVIcon.svg';
import { ReactComponent as UploadXLSXIcon } from '../../../ReusableComponents/Icons/UploadXLSXIcon.svg';
import { ReactComponent as UploadPNGIcon } from '../../../ReusableComponents/Icons/UploadPNGIcon.svg';
import { ReactComponent as UploadJPEGIcon } from '../../../ReusableComponents/Icons/UploadJPEGIcon.svg';

const RecentSessionsWidget = () => {
  const { session } = useContext(UserContext);
  const { sessions, loading, error } = useFetchSessions(session?.user?.id);
  const navigate = useNavigate();

  const formatDate = (dateStr) => {
    if (!dateStr) return 'Unknown date';

    const inputDate = new Date(dateStr);
    const utcInputDate = Date.UTC(inputDate.getUTCFullYear(), inputDate.getUTCMonth(), inputDate.getUTCDate());

    const now = new Date();
    const utcNow = Date.UTC(now.getUTCFullYear(), now.getUTCMonth(), now.getUTCDate());

    const diffTime = Math.abs(utcNow - utcInputDate);
    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));

    if (diffDays === 0) {
      return 'Today';
    } else if (diffDays === 1) {
      return 'Yesterday';
    } else if (diffDays <= 7) {
      return `${diffDays} days ago`;
    } else {
      return inputDate.toLocaleDateString("en-US", { day: 'numeric', month: 'long', year: 'numeric' });
    }
  };

  if (loading) {
    return <div className="dh-loading">Loading recent sessions...</div>;
  }

  if (error) {
    return <div className="dh-error">Error loading sessions. Please try again later.</div>;
  }

  const recentSessions = sessions.slice(0, 5); // Get only the 5 most recent sessions

const handleSessionClick = (sessionId) => {

  navigate(`/load-session/${sessionId}`);
    // Trigger a page refresh
  window.location.reload();
};


  const renderDocumentTypeTag = (documentType) => {
    switch (documentType) {
      case 'BOL':
        return <BOLTag className="dh-session-type-icon" />;
      case 'General':
        return <GeneralDocTag className="dh-session-type-icon" />;
      case 'Invoice':
        return <InvoiceTag className="dh-session-type-icon" />;
      case 'Contract':
        return <ContractTag className="dh-session-type-icon" />;
      case 'Certificate':
        return <CertificateTag className="dh-session-type-icon" />;
      default:
        return null;
    }
  };

  const renderDocumentIcon = (fileType) => {
    switch (fileType) {
      case 'pdf':
        return <UploadPDFIcon className="dh-session-icon" />;
      case 'csv':
        return <UploadCSVIcon className="dh-session-icon" />;
      case 'xlsx':
        return <UploadXLSXIcon className="dh-session-icon" />;
      case 'png':
        return <UploadPNGIcon className="dh-session-icon" />;
      case 'jpg':
      case 'jpeg':
        return <UploadJPEGIcon className="dh-session-icon" />;
      default:
        return null;
    }
  };

  return (
    <div className="dh-recent-sessions-widget">
      {recentSessions.length === 0 ? (
        <div className="dh-no-sessions">
          <p>No recent sessions</p>
        </div>
      ) : (
        <ul className="dh-sessions-list">
          {recentSessions.map((session) => (
            <li
              key={session.id}
              className="dh-session-item"
              onClick={() => handleSessionClick(session.id)}
            >
              <div className="dh-session-icon-container">
                {renderDocumentIcon(session.document?.file_name?.split('.').pop() || '')}
              </div>
              <div className="dh-session-info">
                <h3>{session.session_title}</h3>
                <div className="dh-session-date-tag">
                  <p>{formatDate(session.created_at)}</p>
                  {renderDocumentTypeTag(session.document?.document_type)}
                </div>
              </div>
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};

export default RecentSessionsWidget;
