import React, { useState, useRef, useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import TextInteractionHandler from '../../../Components/TextInteraction/TextInteractionHandler';
import ContextMenu from '../../../Components/TextInteraction/ContextMenu';
import { SessionContext } from '../../../Auth/UserAuth/SessionContext';
import './MapAIChatMessageItem.css'; // Importing the CSS
import { ReactComponent as CopyClipboardIcon } from '../../../ReusableComponents/Icons/CopyClipboardIcon.svg'; 
import { ReactComponent as TickIcon } from '../../../ReusableComponents/Icons/TickIcon.svg'; 
import { ReactComponent as ComovisAIMessageIcon } from '../../../ReusableComponents/Icons/ComovisAIMessageIcon.svg'; 
import { trackEvent } from '../../../Auth/UserAnalytics/MixPanelConfig';

const AIChatMessageItem = ({ text }) => {
    const [copySuccess, setCopySuccess] = useState(false);
    const containerRef = useRef(null);
    const contextMenuRef = useRef(null);
    const { setSelectedMenuText, setSelectedMenuOption } = useContext(SessionContext);

    const [isMenuVisible, setMenuVisibility] = useState(false);
    const [selectedTextInfo, setSelectedTextInfo] = useState({
        text: '',
        position: { top: 0, left: 0 }
    });

    const handleTextSelected = (selectedText, rect) => {
        setSelectedTextInfo({
            text: selectedText,
            position: { top: rect.top + window.scrollY, left: rect.left + window.scrollX }
        });
        setMenuVisibility(true);
    };

    const handleMenuOptionSelect = (option, selectedText) => {
        if (["Tell me more", "Give me insights", "Give me references"].includes(option)) {
            setSelectedMenuText(selectedText);  // Update the selected text context value
            setSelectedMenuOption(option);     // Update the selected menu option context value

            // Track the interaction with Mixpanel
            trackEvent("Context Menu Click - Chat Panel", {
                action: option,
                selectedText: selectedText
            });
        }
        setMenuVisibility(false);
    };

    const copyToClipboard = async () => {
        try {
            // Convert the HTML string back to plain text
            const htmlToText = new DOMParser().parseFromString(text, 'text/html').body.textContent || '';

            // Use the Clipboard API to write the text to user's clipboard
            await navigator.clipboard.writeText(htmlToText);

            setCopySuccess(true);

            // After 2 seconds, revert the tick back to the clipboard icon
            setTimeout(() => {
                setCopySuccess(false);
            }, 2000);
        } catch (error) {
            console.error('Failed to copy message:', error);
        }
    };

    useEffect(() => {
        const handleClickOutside = (event) => {
            // Check if the click is outside the context menu
            if (contextMenuRef.current && !contextMenuRef.current.contains(event.target)) {
                setMenuVisibility(false);
            }
        };

        // Attach the listener to the document
        document.addEventListener('mousedown', handleClickOutside);

        return () => {
            // Remove the listener on component unmount
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    return (
        <div className="map-ai-chat-message-item" ref={containerRef}>
            <TextInteractionHandler onTextSelected={handleTextSelected} containerRef={containerRef} />
            <div className="map-message-header">
                <ComovisAIMessageIcon className="map-app-icon" />
                <div dangerouslySetInnerHTML={{ __html: text }} className="map-ai-message-text"></div>
                {copySuccess ? <TickIcon className="map-copy-icon" /> : <CopyClipboardIcon className="map-copy-icon" onClick={copyToClipboard} />}
            </div>
            {isMenuVisible && (
                <ContextMenu
                    ref={contextMenuRef}
                    text={selectedTextInfo.text}
                    position={selectedTextInfo.position}
                    options={["Tell me more", "Give me insights", "Give me references"]}
                    onSelect={handleMenuOptionSelect}
                />
            )}
        </div>
    );
};

AIChatMessageItem.propTypes = {
    text: PropTypes.string.isRequired,
};

export default AIChatMessageItem;
