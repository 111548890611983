// BOLTemplate.js

// Define the BOL_TEMPLATE
const BOL_TEMPLATE = {
    "Shipper Information": {
        "Name": "",
        "Address": ""
    },
    "Consignee Information": {
        "Name": "",
        "Address": "",
        "Tel": "",
        "Attn": ""
    },
    "Cargo Description": {
        "Commodity Description": "",
        "Quantity": "",
        "Weight": "",
        "Volume": "",
        "Packaging Type": ""
    },
    "Dates": {
        "Date of Issue": "",
        "Expected Delivery Date": ""
    },
    "Ports": {
        "Port of Loading": "",
        "Port of Discharge": ""
    },
    "Transport Information": {
        "Vessel Name": "",
        "Voyage Number": "",
        "Container Number": ""
    },
    "Freight and Charges": {
        "Freight Amount": "",
        "Payment Terms": ""
    },
    "Document Information": {
        "Bill of Lading Number": "",
        "Number of Original BOLs issued": ""
    }
};

// Export the BOL_TEMPLATE
export default BOL_TEMPLATE;
