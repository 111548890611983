import React from 'react';
import PropTypes from 'prop-types';

const OverlayComponent = ({ children, onClose }) => {
  return (
    <div style={overlayStyles} onClick={onClose}>
      <div onClick={(e) => e.stopPropagation()}>
        {children}
      </div>
    </div>
  );
};

const overlayStyles = {
  position: 'fixed',
  top: 0,
  left: 0,
  width: '100vw',
  height: '100vh',
  backgroundColor: 'rgba(0, 0, 0, 0.484)',
  backdropFilter: 'blur(2px)',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  zIndex: 100000,
};

OverlayComponent.propTypes = {
  children: PropTypes.node.isRequired,
  onClose: PropTypes.func,
};

OverlayComponent.defaultProps = {
  onClose: () => {},
};

export default OverlayComponent;
