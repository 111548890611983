import React, { useState, useContext } from "react";
import { useNavigate } from 'react-router-dom';
import { supabase } from '../../Auth/SupabaseAuth/SupabaseClient';
import "./LoginFormField.css";
import Button from '../../ReusableComponents/Buttons/Button';
import { UserContext } from '../../Auth/UserAuth/UserContext';
import { trackEvent } from '../UserAnalytics/MixPanelConfig'; 
import { LoginFailedToast } from '../../ReusableComponents/Toast/ToastMessages';
import GoogleLoginButton from './GoogleLoginButton'; 

export default function FormFieldsLogin() {
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const navigate = useNavigate();
    const [isLoading, setLoading] = useState(false);
    const { session, avatarURL, setAvatarURL } = useContext(UserContext);

    const handleLogin = async (event) => {
        event.preventDefault();

        setLoading(true);

        // Set a timeout for the login process
        const loginTimeout = setTimeout(() => {
            setLoading(false);
            console.error("Login process timed out.");

            // Show error message
            alert("Something went wrong, try again. Auto-refresh will start.");

            // Track the timeout event
            trackEvent("Login_Timeout", {
                email: email,
                error: "Login process timed out."
            });

            // Auto-refresh the page after 2 seconds
            setTimeout(() => {
                window.location.reload();
            }, 1000);
        }, 5000); // 5-second timeout

        try {
            const { data: loginSession, error } = await supabase.auth.signInWithPassword({ email, password });

            clearTimeout(loginTimeout); // Clear the timeout if login completes in time
            setLoading(false);

            if (error) {
                console.error("Error logging in:", error.message);

                // Display Login Failed Toast Message
                LoginFailedToast();

                // Track failed login attempts
                trackEvent("Login_Error", {
                    email: email,
                    error: error.message
                });
                return;
            }

            if (loginSession) {
                //console.log(`User logged in successfully with user id: ${loginSession.user.id}`);

                // Save session ID to local storage
                localStorage.setItem('sessionId', loginSession.user.id);

                // Track successful logins
                trackEvent("Login_Success", {
                    userId: loginSession.user.id,
                    email: email
                });

                // Fetch the user profile including avatar path
                const { data, error: profileError } = await supabase
                    .from('userprofiles')
                    .select('*')
                    .eq('user_id', loginSession.user.id);

                if (profileError) {
                    console.error('Error fetching user profile:', profileError);

                    // Track errors when fetching user profile post-login
                    trackEvent("Login_FetchProfile_Error", {
                        userId: loginSession.user.id,
                        error: profileError.message
                    });
                } else if (data.length > 0) {
                    const baseAvatarUrl = "https://skyxbncllurrcsexskvy.supabase.co/storage/v1/object/public/user-avatars";

                    if (data[0].avatar_path) {
                        const avatarUrl = `${baseAvatarUrl}/${data[0].avatar_path}`;

                        // Directly use the setAvatarURL function to update the avatar URL
                        setAvatarURL(avatarUrl);
                    }

                    // Handling onboarding_complete (Adjust this according to your logic)
                    if (data[0].onboarding_complete) {
                        navigate('/dashboard', { replace: true });
                    } else {
                        navigate('/dashboard', { replace: true });
                    }
                }
            } else {
                console.log('The session object was null. Login might still be in progress.');

                // Track scenarios where session is null (can be used to investigate edge cases)
                trackEvent("Login_NullSession", {
                    email: email
                });
            }
        } catch (error) {
            clearTimeout(loginTimeout); // Clear the timeout in case of an error
            setLoading(false);
            console.error("Unexpected error during login:", error);

            // Track unexpected errors
            trackEvent("Login_UnexpectedError", {
                email: email,
                error: error.message
            });

            // Display a general error message
            LoginFailedToast();
        }
    };

    return (
        <div className="formfieldslogin-container">
            <form className="formfieldslogin" onSubmit={handleLogin}>
                <label className="input-label">
                    Email
                    <input
                        className="input-field"
                        type="email"
                        placeholder="Enter email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                    />
                </label>
                <label className="input-label">
                    Password
                    <input
                        className="input-field"
                        type="password"
                        placeholder="Enter password"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                    />
                </label>
                <Button text="Login" className="login-button" type="submit" isLoading={isLoading} loadingText="Logging in..." />

                <GoogleLoginButton /> 

                <p className="forgot-text" onClick={() => navigate('/reset-password-trigger')}>Forgot Password?</p>
                <p className="signup-text" onClick={() => navigate('/signup')}>Don't have an account? Sign up</p>
            </form>
        </div>
    );
}
