import { useState, useEffect } from 'react';
import { supabase } from '../../Auth/SupabaseAuth/SupabaseClient';

function useFetchSessions(user_id) {
    const [sessions, setSessions] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchSessions = async () => {
            setLoading(true);
            try {
                // Fetch doc-intel sessions with document file name and extracted BOL data
                let { data: docData, error: docError } = await supabase
                    .from('sessions')
                    .select(`
                        *,
                        document:document_id (
                            document_type,
                            file_name,
                            extracted_data
                        )
                    `)
                    .eq('user_id', user_id)
                    .eq('session_type', 'doc-intel');

                if (docError) {
                    throw docError;
                }

                // Fetch map sessions
                let { data: mapData, error: mapError } = await supabase
                    .from('sessions')
                    .select('*, session_title')
                    .eq('user_id', user_id)
                    .eq('session_type', 'map');

                if (mapError) {
                    throw mapError;
                }

                // Combine both sessions
                const combinedSessions = [...docData, ...mapData].map(session => {
                    // Extract BOL data if the document_type is 'BOL'
                    const bolDetails = session.document?.document_type === 'BOL'
                        ? session.document.extracted_data
                        : null;

                    return {
                        ...session,
                        session_title: session.session_type === 'doc-intel'
                            ? (session.document ? session.document.file_name : 'Untitled Document')
                            : (session.session_title || 'Untitled Session'),
                        bolDetails  // Add BOL data to the session object
                    };
                });

                // Fetch notes related to sessions
                const sessionIds = combinedSessions.map(session => session.id); // Get all session IDs
                let { data: notesData, error: notesError } = await supabase
                    .from('notes')
                    .select('session_id')
                    .in('session_id', sessionIds); // Fetch notes for these session IDs

                if (notesError) {
                    throw notesError;
                }

                // Create a set of session IDs that have notes
                const sessionsWithNotes = new Set(notesData.map(note => note.session_id));

                // Add hasNotes property to sessions
                const sessionsWithNotesFlag = combinedSessions.map(session => ({
                    ...session,
                    hasNotes: sessionsWithNotes.has(session.id)  // Check if session has notes
                }));

                setSessions(sessionsWithNotesFlag);
            } catch (err) {
                console.error('Error:', err);
                setError(err);
            } finally {
                setLoading(false);
            }
        };

        fetchSessions();
    }, [user_id]);

    return { sessions, loading, error };
}

export default useFetchSessions;
