import React, { useState, useContext, useEffect } from 'react';
import Button from '../../ReusableComponents/Buttons/Button';
import { useNavigate } from 'react-router-dom';
import './OnboardingStep4.css';
import { supabase } from '../../Auth/SupabaseAuth/SupabaseClient';
import { UserContext } from '../../Auth/UserAuth/UserContext';
import { trackEvent } from '../UserAnalytics/MixPanelConfig';
import { triggerWelcomeEmail } from '../../Components/EmailTemplates/TriggerWelcomeEmail';
import WhiteLogo from '../../ReusableComponents/Logos/Logo.svg';
import './SharedModalStyles.css';
import LoadingComponent from '../../ReusableComponents/LoadingComponent';

function OnboardingStep4({ onBack, onNext, onComplete }) {
    const navigate = useNavigate();
    const [isChecked, setIsChecked] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [isPageLoading, setIsPageLoading] = useState(false);

    const { session, setPlanId, setPlanName, setTrialEndDate, setAccessToken, setOnboardingComplete } = useContext(UserContext);

    useEffect(() => {
        setAccessToken(session.access_token);
    }, [session]);

    const handleGetStarted = async () => {
        setIsLoading(true);
        console.log("Get Started button clicked, loading state set to true");

        if (!isChecked) {
            console.log("Agreement not checked.");
            trackEvent("Onboarding_FinalStep_Error", {
                userId: session.user.id,
                email: session.user.email,
                error: "Agreement not checked"
            });
            setIsLoading(false);
            return;
        }

        const user = session.user;
        console.log("User info available:", user);

        try {
            const { data: existingProfiles, error: existingError } = await supabase
                .from('userprofiles')
                .select('id')
                .eq('user_id', user.id);

            console.log("Supabase select result:", { existingProfiles, existingError });

            if (existingError) {
                console.error('Error fetching existing profiles:', existingError);
                trackEvent("Onboarding_FinalStep_Error", {
                    userId: user.id,
                    email: user.email,
                    error: "Error fetching existing profiles"
                });
                setIsLoading(false);
                return;
            }

            if (existingProfiles.length > 0) {
                console.log("Existing profile found, updating profile");
                const { error: updateError } = await supabase
                    .from('userprofiles')
                    .update({ onboarding_complete: true })
                    .eq('user_id', user.id);

                console.log("Supabase update result:", updateError);

                if (!updateError) {
                    console.log("Profile updated successfully");
                    await setTrialPlanForUser(user.id);
                    trackEvent("Onboarding Completed Step 4 LFG!!", {
                        userId: user.id,
                        email: user.email
                    });
                } else {
                    console.error('Error updating user profile:', updateError);
                }
            } else {
                console.log("No existing profile found, inserting new profile");
                const { error: insertError } = await supabase
                    .from('userprofiles')
                    .insert([{ user_id: user.id, onboarding_complete: true }]);

                console.log("Supabase insert result:", insertError);

                if (!insertError) {
                    console.log("Profile inserted successfully");
                    await setTrialPlanForUser(user.id);
                    setPlanId('d4857aff-031e-4b17-a969-3c256b95b9e3');
                    setPlanName('Trial');
                    trackEvent("Onboarding Completed Step 4 LFG!!", {
                        userId: user.id,
                        email: user.email
                    });
                } else {
                    console.error('Error inserting new user profile:', insertError);
                }
            }

            // Update local storage and context state to mark onboarding as complete
            localStorage.setItem('onboardingComplete', 'true');
            setOnboardingComplete(true);
            onComplete();

            console.log("Navigating to /dashboard");
            setIsPageLoading(true); // Show loading component
            setTimeout(() => {
                window.location.reload(); // Refresh the page after showing loading state
            }, 1000); // Adjust timeout as needed
        } catch (error) {
            console.error('Unexpected error:', error);
        } finally {
            setIsLoading(false);
            console.log("Loading state set to false");
        }
    };

    const setTrialPlanForUser = async (userId) => {
        console.log("Setting trial plan for user:", userId);
        const trialPlanId = 'd4857aff-031e-4b17-a969-3c256b95b9e3';
        const trialEndDate = new Date();
        trialEndDate.setDate(trialEndDate.getDate() + 14);

        const { error } = await supabase
            .from('userprofiles')
            .update({
                plan_id: trialPlanId,
                trial_end_date: trialEndDate.toISOString()
            })
            .eq('user_id', userId);

        if (error) {
            console.error('Error setting trial plan for user:', error);
        } else {
            setTrialEndDate(trialEndDate);
            console.log("Trial plan set successfully");
        }
    };

    const handleCheckboxChange = (event) => {
        setIsChecked(event.target.checked);
    };

    return (
        <div className="onboarding-full-page-overlay">
            {isPageLoading ? (
                <LoadingComponent loading={true} size={50} />
            ) : (
                <div className="onboarding-modal-box">
                    <img src={WhiteLogo} alt="Logo" className="onboarding-modal-logo" />
                    <div className="onboarding-modal-title">The Necessary Legal Stuff</div>
                    <div className="onboarding-step-4-container">
                        <div className="ai-powered-workflow-section">
                            <p>Click the links below to read our T&C and privacy policy. When ready, click the checkbox to get started and complete onboarding.</p>
                        </div>
                        <div className="agreement-section">
                            <div className="agreement-container">
                                <input
                                    type="checkbox"
                                    checked={isChecked}
                                    onChange={handleCheckboxChange}
                                />
                                <label>By completing the onboarding, you agree to the <a href="https://www.comovis.ai/our-terms" target="_blank" rel="noopener noreferrer">Terms of Use</a> and <a href="https://www.comovis.ai/privacy-policy" target="_blank" rel="noopener noreferrer">Privacy Policy</a></label>
                            </div>
                        </div>
                        <div className="button-section">
                            <Button
                                text="Get started"
                                className="onboarding-button-page4"
                                onClick={async () => {
                                    await handleGetStarted();
                                    if (session && session.access_token) {
                                        await triggerWelcomeEmail(session.user.email, session.access_token);
                                    }
                                }}
                                disabled={!isChecked || isLoading}
                                isLoading={isLoading}
                                title={!isChecked ? "Please agree to terms of use" : ""}
                            />
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
}

export default OnboardingStep4;
