import React, { useState, useEffect, useContext } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { NotesContext } from '../../../Auth/UserAuth/NotesContext';
import { SessionContext } from '../../../Auth/UserAuth/SessionContext'; 
import { UserContext } from '../../../Auth/UserAuth/UserContext'; 
import Button from '../../../ReusableComponents/Buttons/Button';  
import './NotesModal.css';

const NoteModal = ({ top = '50%', left = '50%' }) => {
    const { closeNoteModal, currentNote, quotedText, notesMessageId, saveNoteToState } = useContext(NotesContext);
    const { session } = useContext(UserContext);
    const { sessionId } = useContext(SessionContext);
    const [noteContent, setNoteContent] = useState(currentNote ? currentNote.note : '');
    const [isEditing, setIsEditing] = useState(!currentNote); 
    const [isLoading, setIsLoading] = useState(false);
    const [timestamp, setTimestamp] = useState(currentNote ? (currentNote.editedAt || currentNote.createdAt) : null);
    const [isEdited, setIsEdited] = useState(!!currentNote?.editedAt);

    useEffect(() => {
        //console.log("NoteModal opened with notesMessageId:", notesMessageId);
        //console.log("Quoted Text passed to modal:", quotedText); // Log the quoted text being passed
        document.documentElement.style.setProperty('--modal-top', top);
        document.documentElement.style.setProperty('--modal-left', left);
    }, [top, left, quotedText, notesMessageId]);



  
    const handleSave = async () => {
    if (!session || !session.access_token) {
        console.error('No session or access token available');
        return;
    }

    //console.log("Save button clicked");
    setIsLoading(true);
    const now = new Date().toISOString();

    const session_id = sessionStorage.getItem('sessionId') || sessionId;

    if (!session_id) {
        console.error('No session ID available');
        setIsLoading(false);
        return;
    }

    const updatedNote = currentNote
        ? { ...currentNote, note: noteContent, edited_at: now } // Update the existing note
        : { 
            notesMessageId, 
            quoted_text: quotedText, 
            note: noteContent, 
            created_at: now, 
            session_id, 
            document_id: null,
            session_type: 'doc_intel'
        };

    //console.log("Prepared note object:", updatedNote);

    const API_URL = process.env.NODE_ENV === 'production'
        ? process.env.REACT_APP_PRODUCTION_API_URL
        : process.env.REACT_APP_API_URL;

    try {
        let response, result;

        if (currentNote) {
            // Update existing note
            response = await fetch(`${API_URL}/update-note`, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${session.access_token}`
                },
                body: JSON.stringify(updatedNote),
            });
        } else {
            // Insert new note
            response = await fetch(`${API_URL}/insert-note`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${session.access_token}`
                },
                body: JSON.stringify(updatedNote),
            });
        }

        result = await response.json();

        if (response.ok) {
            //console.log('Note operation successful:', result.data);
            saveNoteToState(updatedNote);
            closeNoteModal();
        } else {
            console.error('Error during note operation:', result.error);
        }
    } catch (error) {
        console.error('Unexpected error during note save:', error);
    } finally {
        //console.log("Setting isLoading to false");
        setIsLoading(false);
    }
};




    const getTitle = () => {
        if (!currentNote) return 'New Note';
        return isEditing ? 'Edit Note' : 'View Note';
    };

    const handleOutsideClick = (e) => {
        if (e.target.classList.contains('note-modal-overlay')) {
            closeNoteModal();
        }
    };

    const enableEditing = () => {
        setIsEditing(true);
    };

    return (
        <div className="note-modal-overlay" onClick={handleOutsideClick}>
            <div className="note-modal" onClick={(e) => e.stopPropagation()}>
                <div className="note-modal-content">
                    <span className="close-modal" onClick={() => !isLoading && closeNoteModal()}>X</span>
                    <h2>{getTitle()}</h2>
                    {quotedText && quotedText.length > 0 ? (
                        <div className="quoted-text">
                            <blockquote>{quotedText}</blockquote>
                        </div>
                    ) : (
                        <div className="quoted-text">
                            <blockquote>No quoted text available</blockquote> 
                        </div>
                    )}
                    <textarea
                        className="note-input"
                        value={noteContent}
                        onFocus={enableEditing}  // Trigger editing when input is focused
                        onChange={(e) => setNoteContent(e.target.value)}
                        placeholder="Enter your notes here..."
                        readOnly={!isEditing}
                    />
                    <div className="note-timestamp">
                        {timestamp && (
                            <span>{isEdited ? `Edited at: ${new Date(timestamp).toLocaleString()}` : `Created at: ${new Date(timestamp).toLocaleString()}`}</span>
                        )}
                    </div>
                    {!isEditing && (
                        <div className="edit-note">
                            <button onClick={enableEditing}>Edit</button>
                        </div>
                    )}
                    {isEditing && (
                        <div className="note-modal-buttons">
                            <Button text={isLoading ? "Saving..." : "Save Changes"} color="black" onClick={handleSave} disabled={isLoading} />
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};

export default NoteModal;
