import React, { useState, useEffect, useContext } from 'react';
import { ReactComponent as UploadCompletedTag } from './UploadCompletedTag.svg';
import GlobeExploreIcon from '../../ReusableComponents/Icons/GlobeExploreIcon.svg';
import SidePanel from '../../Components/SidePanel/SidePanel';
import HeaderPanel from '../../Components/HeaderMenu/HeaderPanel';
import { ReactComponent as UploadPDFIcon } from '../../ReusableComponents/Icons/UploadPDFIcon.svg';
import { ReactComponent as UploadCSVIcon } from './UploadCSVIcon.svg';
import { ReactComponent as UploadPNGIcon } from '../../ReusableComponents/Icons/UploadPNGIcon.svg';
import { ReactComponent as UploadJPEGIcon } from '../../ReusableComponents/Icons/UploadJPEGIcon.svg';
import { ReactComponent as UserAvatarSVG } from './SessionsUserAvatar.svg';
import { ReactComponent as UploadXLSXIcon } from '../../ReusableComponents/Icons/UploadXLSXIcon.svg';

import { ReactComponent as UploadDateIcon } from './UploadDateIcon.svg';
import { ReactComponent as DeleteSessionIcon } from './DeleteSessionIcon.svg';
import { ReactComponent as DownloadSessionDocument } from './DownloadSessionDocument.svg';
import { ReactComponent as BOLTag } from '../../ReusableComponents/Icons/BOLTag.svg';
import { ReactComponent as GeneralDocTag } from '../../ReusableComponents/Icons/GeneralDocTag.svg';
import { ReactComponent as InvoiceTag } from '../../ReusableComponents/Icons/InvoiceTag.svg';
import { ReactComponent as ContractTag } from '../../ReusableComponents/Icons/ContractTag.svg';
import { ReactComponent as CertificateTag } from '../../ReusableComponents/Icons/CertificateTag.svg';

import { ReactComponent as ActiveNoteIcon } from '../../ReusableComponents/Icons/ActiveNoteIcon.svg'; // Importing the active note icon
import { ReactComponent as NoActiveNotes } from '../../ReusableComponents/Icons/NoActiveNotes.svg'; // Importing the no notes icon
import Button from '../../ReusableComponents/Buttons/Button';
import useFetchSessions from './UseFetchSessions';
import { supabase } from '../../Auth/SupabaseAuth/SupabaseClient';
import { UserContext } from '../../Auth/UserAuth/UserContext'; 
import { useDocument } from '../../Auth/UserAuth/DocumentContext';
import { SessionContext } from '../../Auth/UserAuth/SessionContext';
import LoadSession from './LoadSessions';
import { ReactComponent as SearchSessionsIcon } from './SearchSessionsIcon.svg';
import { ReactComponent as DeleteAllSessions } from './DeleteAllSessions.svg';
import { trackEvent } from '../../Auth/UserAnalytics/MixPanelConfig';
import { SessionRestoreToast, SessionDeletedToast, AllSessionsDeletedToast } from '../../ReusableComponents/Toast/ToastMessages';
import LoadingComponent from '../../ReusableComponents/LoadingComponent';
import './UploadSessions.css';
import TooltipWrapper from '../../ReusableComponents/Tooltip/TooltipWrapper'; 










const trimText = (text, length) => {
    if (text.length > length) {
        return text.substring(0, length) + '...';
    }
    return text;
};

function UploadSessions() {
    const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);
    const [isDeleteLoading, setIsDeleteLoading] = useState(false);
    const [sessionIdToDelete, setSessionIdToDelete] = useState(null);
    const [deleteErrorMessage, setDeleteErrorMessage] = useState(null);
    const [deleteAllSessions, setDeleteAllSessions] = useState(false);
    const [isDeleteAllDialogOpen, setIsDeleteAllDialogOpen] = useState(false);
    const [sessionIdToLoad, setSessionIdToLoad] = useState(null);
    const [showRefreshButton, setShowRefreshButton] = useState(false);

    const { session, avatarURL } = useContext(UserContext);

     const { documentState, generateSignedUrl} = useDocument(); 
    
    const { searchText, setSearchText } = useContext(SessionContext);

    const fileType = documentState?.fileType;




    const handleDownloadClick = async (filePath) => {
    try {
        const signedUrl = await generateSignedUrl(filePath);
        if (signedUrl) {
            const response = await fetch(signedUrl);
            const blob = await response.blob();
            const url = window.URL.createObjectURL(blob);
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', filePath.split('/').pop());
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
            window.URL.revokeObjectURL(url); // Clean up the object URL
        } else {
            console.error('Failed to generate a signed URL for download.');
        }
    } catch (error) {
        console.error('handleDownloadClick Error:', error);
    }
};





    const extractFileTypeFromPath = (filePath) => {
        return filePath.split('.').pop();
    };

    const { sessions: fetchedSessions, loading, error } = useFetchSessions(session?.user?.id);
    const [sessions, setSessions] = useState([]);

    useEffect(() => {
        setSessions(fetchedSessions);
    }, [fetchedSessions]);

    useEffect(() => {
        const timer = setTimeout(() => {
            if (loading) {
                setShowRefreshButton(true);
            }
        }, 3000); // Show the refresh button after 10 seconds if still loading

        return () => clearTimeout(timer);
    }, [loading]);

    const handleLoadClick = (sessionId) => {
        SessionRestoreToast(); 
        setSessionIdToLoad(sessionId);
    };

    const handleDeleteClick = (sessionId) => {
        setSessionIdToDelete(sessionId); 
        setIsDeleteDialogOpen(true); 
    };

    const handleDeleteAllClick = () => {
        setIsDeleteAllDialogOpen(true); 
    };

    async function deleteDocumentFiles(documentId) {
        const { data, error } = await supabase
            .from('documents')
            .select('file_path')
            .eq('id', documentId);
        if (error) throw new Error(`Fetch document failed: ${error.message}`);

        const filePath = data[0].file_path;
        const fileName = filePath.split('/')[1];

        const { error: fileError } = await supabase.storage
            .from('documents')
            .remove([fileName]);
        if (fileError) throw new Error(`Delete file failed: ${fileError.message}`);

        const { error: docError } = await supabase
            .from('documents')
            .delete()
            .eq('id', documentId);
        if (docError) throw new Error(`Delete document failed: ${docError.message}`);
    }

    async function deleteSessionMessages(sessionId) {
        const { error } = await supabase
            .from('messages')
            .delete()
            .eq('session_id', sessionId);
        if (error) throw new Error(`Delete messages failed: ${error.message}`);
    }

    async function deleteSession(sessionId) {
        const { error } = await supabase
            .from('sessions')
            .delete()
            .eq('id', sessionId);
        if (error) throw new Error(`Delete session failed: ${error.message}`);
    }

    const handleConfirmDelete = async () => {
        const sessionsToDelete = isDeleteAllDialogOpen ? sessions : [sessions.find(session => session.id === sessionIdToDelete)];
        setIsDeleteLoading(true);

        try {
            for (const sessionToDelete of sessionsToDelete) {
                if (sessionToDelete.document_id) {
                    await deleteDocumentFiles(sessionToDelete.document_id);
                }

                await deleteSessionMessages(sessionToDelete.id);
                await deleteSession(sessionToDelete.id);

                console.log('Session deletion successful:', sessionToDelete.id);
            }

            setSessions(sessions.filter(session => !sessionsToDelete.includes(session)));

            if (isDeleteAllDialogOpen) {
                trackEvent("Deleted All Sessions", {
                    userId: session?.user?.id
                });
                AllSessionsDeletedToast();
            } else {
                trackEvent("Deleted a Session", {
                    sessionId: sessionIdToDelete,
                    userId: session?.user?.id
                });
                SessionDeletedToast();
            }
        } catch (error) {
            console.error('Deletion failed:', error);
            setDeleteErrorMessage('Deletion failed. Please try again later.');
        } finally {
            setIsDeleteLoading(false);
            setIsDeleteDialogOpen(false);
            setSessionIdToDelete(null);
            setIsDeleteAllDialogOpen(false);
        }
    };

    const handleCancelDelete = () => {
        setIsDeleteDialogOpen(false);
    };

    const handleCancelDeleteAll = () => {
        setIsDeleteAllDialogOpen(false);
    };

    
    function formatDate(dateStr) {
        if (!dateStr) return 'Unknown date';

        const inputDate = new Date(dateStr);
        const utcInputDate = Date.UTC(inputDate.getUTCFullYear(), inputDate.getUTCMonth(), inputDate.getUTCDate());

        const now = new Date();
        const utcNow = Date.UTC(now.getUTCFullYear(), now.getUTCMonth(), now.getUTCDate());

        const diffTime = Math.abs(utcNow - utcInputDate);
        const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));

        if (diffDays === 0) {
            return 'Today';
        } else if (diffDays === 1) {
            return 'Yesterday';
        } else if (diffDays <= 7) {
            return `${diffDays} days ago`;
        } else {
            return inputDate.toLocaleDateString("en-US", { day: 'numeric', month: 'long', year: 'numeric' });
        }
    }

    useEffect(() => {
        const sortedSessions = [...fetchedSessions].sort((a, b) => new Date(b.created_at) - new Date(a.created_at));
        setSessions(sortedSessions);
    }, [fetchedSessions]);

    if (loading && !showRefreshButton) {
        return (
            <div className="loading-screen">
                <LoadingComponent loading={true} />
            </div>
        );
    }

    if (loading && showRefreshButton) {
        return (
            <div className="loading-screen">
               <Button className="button-black" text="Refresh" onClick={() => window.location.reload()} />
            </div>
        );
    }

    if (error) return <div>Error: {error.message}</div>;

    const filteredSessions = sessions.filter(session =>
        session.session_title &&
        session.session_title.toLowerCase().includes(searchText.trim().toLowerCase())
    );

    function UserAvatar({ className, avatarURL }) {
        return (
            avatarURL ?
                <img src={avatarURL} alt="User Avatar" className={className} />
                :
                <UserAvatarSVG className={className} />
        );
    }

 


return (
        <div className="main-container">
            <SidePanel />
            <div className="header-container">
                <h1>Sessions</h1>
                <div className="session-search-container">
                    <SearchSessionsIcon className="session-search-icon" />
                    <input
                        className="session-search-input"
                        type="text"
                        placeholder="Search..."
                        value={searchText}
                        onChange={(e) => setSearchText(e.target.value)}
                    />
                </div>
            </div>
            <div className="uploadSessions-titles">
                <div className="title">Document Name</div>
                <div className="title">Owner</div>
                <div className="title">Date</div>
                <div className="title">Type</div>
                <div className="title">Processing Status</div> 
                <div className="title">More</div>
            </div>
            <div className="uploadSessions-container">
                <div className="uploadSessions-toolbar">
                    <DeleteAllSessions className="uploadSession-delete-all-icon" onClick={handleDeleteAllClick} />
                </div>

                {filteredSessions.length === 0 ? (
                    <div className="no-sessions-message">No sessions available.</div>
                ) : (
                    filteredSessions.map((session, index) => (
                        <div className="uploadSession-item" key={index}>
                            <div className="uploadSession-docname">
                               {session.session_type === 'doc-intel' ? (
                                    extractFileTypeFromPath(session.document?.file_name) === 'pdf' ? (
                                        <UploadPDFIcon className="uploadSession-pdf-icon" />
                                    ) : extractFileTypeFromPath(session.document?.file_name) === 'csv' ? (
                                        <UploadCSVIcon className="uploadSession-csv-icon" />
                                    ) : extractFileTypeFromPath(session.document?.file_name) === 'xlsx' ? (
                                        <UploadXLSXIcon className="uploadSession-xlsx-icon" />
                                    ) : extractFileTypeFromPath(session.document?.file_name) === 'png' ? (
                                        <UploadPNGIcon className="uploadSession-png-icon" />
                                    ) : extractFileTypeFromPath(session.document?.file_name) === 'jpg' || extractFileTypeFromPath(session.document?.file_name) === 'jpeg' ? (
                                        <UploadJPEGIcon className="uploadSession-jpeg-icon" />
                                    ) : null
                                ) : (
                                    <img src={GlobeExploreIcon} alt="Map" className="uploadSession-map-icon" />
                                )}

                                <TooltipWrapper
                                    id={`tooltip-title-${session.id}`}
                                    content={session.session_title || (session.document ? session.document.file_name : 'No file name')}
                                >
                                    <span
                                        onClick={() => handleLoadClick(session.id)}
                                        style={{ cursor: 'pointer' }}
                                    >
                                        {session.session_title ? trimText(session.session_title, 18) : (session.document ? trimText(session.document.file_name, 18) : 'No file name')}
                                    </span>
                                </TooltipWrapper>
                            </div>

                            <div className="uploadSession-owner">
                                <UserAvatar className="uploadSession-owner-avatar" avatarURL={avatarURL} />
                                Me
                            </div>
                            <div className="uploadSession-date">
                                <UploadDateIcon className="uploadSession-date-icon" />
                                {formatDate(session.created_at)}
                            </div>
                            <div className="uploadSession-type">
                                {session.document?.document_type === 'BOL' ? (
                                    <BOLTag className="BOL-uploadSession-type-icon" />
                                ) : session.document?.document_type === 'General' ? (
                                    <GeneralDocTag className="uploadSession-type-icon" />
                                ) : session.document?.document_type === 'Invoice' ? (
                                    <InvoiceTag className="invoice-uploadSession-type-icon" />
                                ) : session.document?.document_type === 'Contract' ? (
                                    <ContractTag className="contract-uploadSession-type-icon" />
                                ) : session.document?.document_type === 'Certificate' ? (
                                    <CertificateTag className="certificate-uploadSession-type-icon" />
                                ) : null}
                            </div>
                            <div className="uploadSession-status">
                                {session.created_at ? <UploadCompletedTag /> : 'Processing'}
                            </div>
                            <div className="uploadSession-options">
                                <TooltipWrapper
                                    id={`tooltip-note-${session.id}`}
                                    content={session.hasNotes ? "This session has notes" : "This session has no notes"}
                                >
                                    {session.hasNotes ? (
                                        <ActiveNoteIcon className="uploadSession-note-icon" />
                                    ) : (
                                        <NoActiveNotes className="uploadSession-note-icon" />
                                    )}
                                </TooltipWrapper>

                                <TooltipWrapper
                                    id={`tooltip-download-${session.id}`}
                                    content="Download this file"
                                >
                                    <DownloadSessionDocument 
                                        className="uploadSession-download-icon"
                                        onClick={() => handleDownloadClick(session.document.file_path)}  // Trigger download
                                    />
                                </TooltipWrapper>

                                <TooltipWrapper
                                    id={`tooltip-delete-${session.id}`}
                                    content="Delete this individual session"
                                >
                                    <DeleteSessionIcon
                                        className="uploadSession-delete-icon"
                                        onClick={() => handleDeleteClick(session.id)}
                                    />
                                </TooltipWrapper>
                            </div>
                        </div>
                    ))
                )}
            </div>

            {/* Delete Dialog and Load Session Logic */}
            {isDeleteDialogOpen && (
                <div className="delete-overlay">
                    <div className="delete-dialog-container">
                        <h2>Are you sure you want to delete this session?</h2>
                        <p>Please note, all data associated with this session will also be deleted.</p>
                        <div className="delete-dialog-buttons">
                            <Button
                                text="Cancel"
                                color="black"
                                className="dialog-cancel-button-medium"
                                onClick={handleCancelDelete}
                            />
                            <Button
                                text="Delete"
                                className="dialog-delete-button"
                                onClick={handleConfirmDelete}
                                isLoading={isDeleteLoading}
                                loaderColor="#fff"
                            />
                        </div>
                    </div>
                </div>
            )}

            {isDeleteAllDialogOpen && (
                <div className="delete-overlay">
                    <div className="delete-dialog-container">
                        <h2>Are you sure you want to delete all of your sessions?</h2>
                        <p>Please note, all data associated with these sessions will also be deleted. Please don't refresh.</p>
                       <div className="delete-all-dialog-buttons">
                        <Button
                            text="Cancel"
                            color="black"
                            className="dialog-cancel-all-button button-medium"
                            onClick={handleCancelDeleteAll}
                        />
                        <Button
                            text="Delete All"
                            className="dialog-delete-all-button button-medium"
                            onClick={handleConfirmDelete}
                            isLoading={isDeleteLoading}
                            loaderColor="#fff"
                        />
                    </div>

                    </div>
                </div>
            )}

            {sessionIdToLoad && <LoadSession sessionId={sessionIdToLoad} session={session} />}
        </div>
    );
}

export default UploadSessions;