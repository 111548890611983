

import React from 'react';
import ReactLoading from 'react-loading';
import PropTypes from 'prop-types';
import './StandaloneLoader.css';

const StandaloneLoader = ({ type, color, size }) => {
    return (
        <div className="loader-spinner-container">
            <ReactLoading type={type} color={color} height={size} width={size} />
        </div>
    );
};

StandaloneLoader.propTypes = {
    type: PropTypes.string,
    color: PropTypes.string,
    size: PropTypes.number,
};

StandaloneLoader.defaultProps = {
    type: 'spin',
    color: '#000',
    size: 24,
};

export default StandaloneLoader;
