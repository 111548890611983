import React, { useEffect, useState } from 'react';
import { AgGridReact } from 'ag-grid-react';
import * as XLSX from 'xlsx';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import './ExcelViewer.css'; // Include the new CSS file

const ExcelViewer = ({ file, url }) => {
  const [rowData, setRowData] = useState([]);
  const [columnDefs, setColumnDefs] = useState([]);

  useEffect(() => {
    const loadFile = async (file) => {
      const reader = new FileReader();
      reader.onload = (e) => {
        const data = new Uint8Array(e.target.result);
        processFile(data);
      };
      reader.readAsArrayBuffer(file);
    };

    const loadFileFromURL = async (url) => {
      const response = await fetch(url);
      const blob = await response.blob();
      const reader = new FileReader();
      reader.onload = (e) => {
        const data = new Uint8Array(e.target.result);
        processFile(data);
      };
      reader.readAsArrayBuffer(blob);
    };

    const processFile = (data) => {
      const workbook = XLSX.read(data, { type: 'array' });
      const sheetName = workbook.SheetNames[0];
      const sheet = XLSX.utils.sheet_to_json(workbook.Sheets[sheetName], { header: 1 });

      const cols = sheet[0].map((col) => ({ headerName: col, field: col }));
      const rows = sheet.slice(1).map((row) =>
        row.reduce((acc, cell, i) => {
          acc[sheet[0][i]] = cell;
          return acc;
        }, {})
      );

      setColumnDefs(cols);
      setRowData(rows);
    };

    if (file) {
      loadFile(file);
    } else if (url) {
      loadFileFromURL(url);
    }
  }, [file, url]);

  return (
    <div className="excel-viewer-container">
      <div className="ag-theme-alpine">
        <AgGridReact
          columnDefs={columnDefs}
          rowData={rowData}
        />
      </div>
    </div>
  );
};

export default ExcelViewer;
