import React from 'react';
import Button from '../../ReusableComponents/Buttons/Button'; // Ensure the path is correct
import './DemoSignupModal.css';

const DemoSignupModal = ({ showModal, onClose }) => {
    const handleSignUp = () => {
        // Clear session storage and local storage
        sessionStorage.clear();
        localStorage.clear();
        // Redirect to signup page
        window.location.href = '/signup';
    };

    if (!showModal) return null;

    return (
        <div className="signup-modal-overlay">
            <div className="signup-modal-content">
                <button className="signup-modal-close" onClick={onClose}>×</button>
                <div className="signup-center-container">
                    <h2>Sign Up to Continue</h2>
                    <p>To continue using Comovis, please sign up for a full account.</p>
                    <p>You can sign up for free without any credit/debit card details and get a 7-day free, all-access trial.</p>
                    <Button 
                        text="Sign Up" 
                        color="black" 
                        className="demo-signup-button"
                        onClick={handleSignUp} 
                    />
                </div>
            </div>
        </div>
    );
};

export default DemoSignupModal;
