import React, { useMemo, useEffect } from 'react';
import PDFViewer from './DocumentProcessing/PDFViewer';
import CSVViewer from './DocumentProcessing/CSVViewer';
import DOCXViewer from './DocumentProcessing/DOCXViewer';
import TXTViewer from './DocumentProcessing/TXTViewer';
import ImageViewerComponent from './DocumentProcessing/ImageViewer'; // Ensure you import the right component
import ExcelViewer from './DocumentProcessing/ExcelViewer';
import { useDocument } from '../../Auth/UserAuth/DocumentContext';

const DocumentViewerContent = ({ handleTextSelected, containerRef }) => {
    const { documentUrl, documentState } = useDocument();

    useEffect(() => {
        // Additional debug logs if needed
        //console.log("Document State in DocumentViewerContent:", documentState);
        //console.log("Document URL in DocumentViewerContent:", documentUrl);
    }, [documentState, documentUrl]);

    const viewer = useMemo(() => {
        if (!documentState || !documentState.fileType) {
            // Skip rendering or logging if fileType is not yet set
            return null;
        }

        const { fileType } = documentState;

        if (!documentUrl) {
            //console.log("Document URL is undefined");
            return null;
        }

        //console.log("Rendering viewer for fileType:", fileType);

        if (fileType === 'pdf') {
            return <PDFViewer handleTextSelected={handleTextSelected} containerRef={containerRef} />;
        } else if (fileType === 'csv') {
            return <CSVViewer file={documentState?.file} />;
        } else if (fileType === 'docx') {
            return <DOCXViewer />;
        } else if (fileType === 'txt') {
            return <TXTViewer />;
        } else if (fileType === 'xlsx') {
            return <ExcelViewer file={documentState?.file} url={documentUrl} />;
        } else if (['jpg', 'jpeg', 'png'].includes(fileType)) {
            return <ImageViewerComponent documentState={documentState} />;
        } else {
            console.log("Unsupported file type:", fileType);
            return null;
        }
    }, [documentState, documentUrl, handleTextSelected, containerRef]);

    return <>{viewer}</>;
};

export default React.memo(DocumentViewerContent);
