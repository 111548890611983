import React, { useEffect, forwardRef, useImperativeHandle, useRef } from 'react';
import { Worker, Viewer } from '@react-pdf-viewer/core';
import { defaultLayoutPlugin } from '@react-pdf-viewer/default-layout';
import { pageNavigationPlugin } from '@react-pdf-viewer/page-navigation';
import '@react-pdf-viewer/core/lib/styles/index.css';
import '@react-pdf-viewer/default-layout/lib/styles/index.css';
import '@react-pdf-viewer/page-navigation/lib/styles/index.css';
import { useDocument } from '../../../Auth/UserAuth/DocumentContext'; // Use DocumentContext
import TextInteractionHandler from '../../TextInteraction/TextInteractionHandler';
import LoadingComponent from '../../../ReusableComponents/LoadingComponent';
import './PDFViewer.css';

const PDFViewer = forwardRef(({ handleTextSelected, containerRef }, ref) => {
    const defaultLayoutPluginInstance = defaultLayoutPlugin({
        sidebarTabs: () => [],
    });
    const pageNavigationPluginInstance = pageNavigationPlugin();

    const { jumpToPage } = pageNavigationPluginInstance;
    const { currentPage, documentUrl } = useDocument(); // Get documentUrl and currentPage from DocumentContext
    const viewerContainerRef = useRef(null); // Ref for the container of the Viewer

    useEffect(() => {
        if (currentPage !== null && jumpToPage) {
            jumpToPage(currentPage - 1);
        }
    }, [currentPage, jumpToPage]);

    useImperativeHandle(ref, () => ({
        goToPage: (pageNumber) => {
            if (jumpToPage) {
                jumpToPage(pageNumber - 1);
            }
        },
        // If you need to expose any other methods, you can do it here
    }));

    const isValidUrl = documentUrl && documentUrl !== 'undefined'; // Use documentUrl from DocumentContext

    return (
        <div className="pdf-viewer-container">
            <div className="pdf-viewer-wrapper" ref={viewerContainerRef}> {/* Assign ref to the wrapper */}
                <Worker workerUrl="https://cdnjs.cloudflare.com/ajax/libs/pdf.js/3.11.174/pdf.worker.min.js">
                    {isValidUrl ? (
                        <>
                            <div className="pdf-document-viewer no-sidebar">
                                <Viewer
                                    fileUrl={documentUrl} // Use documentUrl here
                                    plugins={[defaultLayoutPluginInstance, pageNavigationPluginInstance]}
                                    className="full-size-viewer"
                                    initialPage={currentPage ? currentPage - 1 : 0}
                                />
                            </div>
                            <TextInteractionHandler
                                onTextSelected={handleTextSelected}
                                containerRef={containerRef}
                            />
                        </>
                    ) : (
                        <LoadingComponent loading={true} />
                    )}
                </Worker>
            </div>
        </div>
    );
});

export default React.memo(PDFViewer);
