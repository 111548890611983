import React, { useContext, useEffect } from "react";
import { supabase } from '../../Auth/SupabaseAuth/SupabaseClient';
import { SignupErrorToast } from '../../ReusableComponents/Toast/ToastMessages';
import GoogleIcon from '../../ReusableComponents/Icons/Google.svg';  // Assuming you're using the same Google icon
import { UserContext } from '../../Auth/UserAuth/UserContext';
import { useNavigate } from "react-router-dom";
import { trackEvent } from '../UserAnalytics/MixPanelConfig';
import './GoogleLoginStyles.css';  // Use the new CSS file for styling

export default function SignInWithGoogle() {
    const navigate = useNavigate();
    const { setSession, setAvatarURL } = useContext(UserContext);

    const handleGoogleSignin = async () => {
        const { error } = await supabase.auth.signInWithOAuth({
            provider: 'google',
            options: {
                redirectTo: window.location.origin + '/dashboard'
            }
        });

        if (error) {
            console.error("Error during Google sign in:", error);
            SignupErrorToast('Login error');

            // Track Google sign in errors
            trackEvent("GoogleSignin_Error", {
                error: error.message
            });
        } else {
            // Track successful initiation of Google sign in process
            trackEvent("GoogleSignin_Initiated");
        }
    }

    useEffect(() => {
        supabase.auth.onAuthStateChange(async (event, session) => {
            if (event === 'SIGNED_IN') {
                setSession(session);

                // Track successful Google sign in completion
                trackEvent("GoogleSignin_Success", {
                    userId: session.user.id,
                    email: session.user.email
                });

                const user = session.user;

                const { data, error } = await supabase
                    .from('userprofiles')
                    .select('*')   // Fetch all fields, including avatar_path
                    .eq('user_id', user.id)
                    .maybeSingle();

                if (error) {
                    console.error('Error fetching user profile:', error);

                    // Track errors when fetching user profile post Google sign-in
                    trackEvent("GoogleSignin_FetchProfile_Error", {
                        userId: user.id,
                        error: error.message
                    });
                } else {
                    // Set user avatar if available
                    const baseAvatarUrl = "https://skyxbncllurrcsexskvy.supabase.co/storage/v1/object/public/user-avatars";
                    if (data?.avatar_path) {
                        const avatarUrl = `${baseAvatarUrl}/${data.avatar_path}`;
                        setAvatarURL(avatarUrl);
                    }

                    // Handle onboarding logic
                    if (data?.onboarding_complete) {
                        navigate('/dashboard', { replace: true });
                    } else {
                        navigate('/dashboard', { replace: true });
                    }
                }
            }
        });
    }, [setSession, navigate, setAvatarURL]);

    return (
        <div className="google-login-wrapper">
            <div className="divider">
                <span>or</span>
            </div>
            <div className="google-login-button" onClick={handleGoogleSignin}>
                <img src={GoogleIcon} alt="Google Icon" className="google-icon" />
                <span>Login with Google</span>
            </div>
        </div>
    );
}
