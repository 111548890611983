import React from 'react';
import { Tooltip as ReactTooltip } from 'react-tooltip';
import 'react-tooltip/dist/react-tooltip.css';

const TooltipWrapper = ({ id, children, content, ...props }) => {
    return (
        <>
            <div data-tooltip-id={id} data-tooltip-content={content} {...props}>
                {children}
            </div>
            <ReactTooltip
                id={id}
                effect="solid"
                place="bottom"
                className="custom-tooltip"
                opacity={1} // Set the opacity directly via the prop
                style={{
                    backgroundColor: '#000000',  // Solid black background
                    borderRadius: '8px',         // Smooth edges
                    padding: '8px 12px',         // Padding inside the tooltip
                    fontSize: '10px',            // Smaller font size
                    color: '#ffffff',            // White text color
                    zIndex: 9999,                // Increase z-index to ensure tooltip is on top
                }}
            />
        </>
    );
};

export default TooltipWrapper;
