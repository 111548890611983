
// ConfirmEmailPage.js

import React from 'react';
import logo from '../../ReusableComponents/Logo.svg';
import { ReactComponent as ModalVerifyEmail } from './ModalVerifyEmail.svg';
import './ConfirmEmailPage.css';

function ConfirmEmailPage() {
    return (
        <div className="confirm-email-page">
            <img src={logo} alt="Logo" className="logo" />
            <ModalVerifyEmail />
        </div>
    );
}

export default ConfirmEmailPage;
