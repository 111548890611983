const promptTemplates = {
  Shipbroker: [
    {
      id: 'vessel-position-update',
      title: 'Vessel Position Update',
      description: 'Send regular updates on the position and status of vessels to clients.',
      prompt: `Draft a vessel position update email. Include details on the vessel's current location, expected arrival times at the next port, and any delays or issues encountered. Highlight any relevant market trends that may impact the voyage.`,
    },
    {
      id: 'market-trend-analysis',
      title: 'Market Trend Analysis',
      description: 'Provide clients with an analysis of the latest market trends.',
      prompt: `Draft an email summarizing the latest market trends affecting shipping rates. Include insights from recent data, comparisons with historical trends, and predictions for the upcoming weeks. Tailor the analysis to the specific routes and cargo types relevant to the client.`,
    },
    {
      id: 'charter-party-review',
      title: 'Charter Party Review',
      description: 'Review key terms and conditions in a charter party document.',
      prompt: `Draft an email summarizing the key terms and conditions from a charter party document. Highlight any clauses that require attention or may impact the client's operations. Provide recommendations for negotiation or amendments if necessary.`,
    },
    {
      id: 'negotiation-strategy',
      title: 'Negotiation Strategy',
      description: 'Prepare a strategy for negotiating terms with a charter party.',
      prompt: `Draft a negotiation strategy email. Outline the key points to negotiate, including suggested adjustments to rates, laytime, and penalties. Provide context based on recent market data and the client's specific requirements.`,
    },
    {
      id: 'vessel-fixture-confirmation',
      title: 'Vessel Fixture Confirmation',
      description: 'Confirm the fixture of a vessel for a client.',
      prompt: `Draft a vessel fixture confirmation email. Include the vessel's name, charter party terms, laycan dates, and any special instructions. Ensure clarity on the next steps and any actions required from the client.`,
    },
    {
      id: 'contract-compliance-reminder',
      title: 'Contract Compliance Reminder',
      description: 'Remind clients of their contractual obligations in a charter party.',
      prompt: `Draft an email reminding the client of their contractual obligations under a charter party agreement. Highlight any approaching deadlines, penalties for non-compliance, and any required actions to ensure smooth operations.`,
    },
  ],

  FreightForwarder: [
    {
      id: 'shipment-status-update',
      title: 'Shipment Status Update',
      description: 'Provide clients with updates on the status of their shipments.',
      prompt: `Draft a shipment status update email. Include the current status of the shipment, any delays, and the expected delivery date. Ensure clarity on any actions required from the client.`,
    },
    {
      id: 'document-request',
      title: 'Document Request',
      description: 'Request necessary documents from clients to process a shipment.',
      prompt: `Draft an email requesting the necessary documents to process a shipment. Specify the documents needed, the deadline for submission, and any implications for delays.`,
    },
    {
      id: 'customs-clearance-notification',
      title: 'Customs Clearance Notification',
      description: 'Notify clients about the customs clearance status of their shipment.',
      prompt: `Draft an email notifying the client about the customs clearance status of their shipment. Include any actions required to facilitate clearance and any potential delays.`,
    },
    {
      id: 'billing-reminder',
      title: 'Billing Reminder',
      description: 'Send a reminder to clients about pending payments for services.',
      prompt: `Draft a billing reminder email for pending payments related to freight forwarding services. Include the invoice details, due date, and payment instructions.`,
    },
    {
      id: 'delay-notification',
      title: 'Delay Notification',
      description: 'Inform clients about delays in their shipment.',
      prompt: `Draft an email informing the client about a delay in their shipment. Explain the reason for the delay, the expected new delivery date, and any actions being taken to mitigate the delay.`,
    },
    {
      id: 'shipment-documentation-review',
      title: 'Shipment Documentation Review',
      description: 'Review shipment documents for accuracy and completeness.',
      prompt: `Draft an email summarizing the review of shipment documents. Highlight any discrepancies or missing information that need to be addressed before the shipment can proceed.`,
    },
  ],

  Analyst: [
    {
      id: 'data-analysis-report',
      title: 'Data Analysis Report',
      description: 'Generate a report summarizing key data points from multiple documents.',
      prompt: `Draft a report summarizing the key data points from the attached documents. Include insights, trends, and any anomalies detected. Tailor the analysis to the client's specific industry or focus area.`,
    },
    {
      id: 'market-trend-summary',
      title: 'Market Trend Summary',
      description: 'Provide a summary of market trends based on historical data.',
      prompt: `Draft a market trend summary email. Include analysis of historical data, recent trends, and predictions for the future. Highlight how these trends may impact the client's business operations.`,
    },
    {
      id: 'risk-assessment-report',
      title: 'Risk Assessment Report',
      description: 'Generate a report assessing risks associated with a particular operation or investment.',
      prompt: `Draft a risk assessment report. Analyze potential risks associated with the client's operation or investment, including financial, operational, and market risks. Provide recommendations for mitigating these risks.`,
    },
    {
      id: 'performance-analysis',
      title: 'Performance Analysis',
      description: 'Analyze the performance of a particular strategy or operation.',
      prompt: `Draft a performance analysis report. Evaluate the effectiveness of the client's current strategy or operation, using key performance indicators (KPIs) and relevant data. Provide suggestions for improvement.`,
    },
    {
      id: 'competitive-analysis',
      title: 'Competitive Analysis',
      description: 'Compare the client\'s performance against industry competitors.',
      prompt: `Draft a competitive analysis report. Compare the client's performance with industry competitors, using relevant data points. Highlight areas where the client is excelling and areas where improvement is needed.`,
    },
    {
      id: 'forecast-report',
      title: 'Forecast Report',
      description: 'Provide a forecast based on current data trends.',
      prompt: `Draft a forecast report. Use current data trends to predict future performance, market conditions, or other relevant factors. Include potential scenarios and their implications for the client's business.`,
    },
  ],

  Trader: [
    {
      id: 'market-analysis-report',
      title: 'Market Analysis Report',
      description: 'Provide an in-depth analysis of current market conditions.',
      prompt: `Draft a market analysis report. Include details on current market conditions, recent price movements, and factors influencing the market. Provide insights on how these conditions may impact trading decisions.`,
    },
    {
      id: 'trade-confirmation',
      title: 'Trade Confirmation',
      description: 'Confirm the details of a recently executed trade.',
      prompt: `Draft a trade confirmation email. Include details of the executed trade, including the commodity, quantity, price, and delivery terms. Ensure accuracy in all details to avoid any disputes.`,
    },
    {
      id: 'negotiation-proposal',
      title: 'Negotiation Proposal',
      description: 'Propose terms for a new trade or contract.',
      prompt: `Draft a negotiation proposal email. Outline the proposed terms for a new trade or contract, including pricing, delivery timelines, and any special conditions. Use market data to support the proposal.`,
    },
    {
      id: 'risk-management-update',
      title: 'Risk Management Update',
      description: 'Provide an update on risk management strategies and their performance.',
      prompt: `Draft an email updating the client on the performance of current risk management strategies. Include any adjustments made based on recent market changes and suggest further actions if needed.`,
    },
    {
      id: 'contract-review',
      title: 'Contract Review',
      description: 'Review the terms of a trading contract for potential issues or improvements.',
      prompt: `Draft an email summarizing the review of a trading contract. Highlight any potential issues, risks, or areas for improvement. Provide recommendations for amendments to ensure the client's interests are protected.`,
    },
    {
      id: 'market-alert-notification',
      title: 'Market Alert Notification',
      description: 'Notify clients of sudden market changes that may require immediate action.',
      prompt: `Draft a market alert notification email. Inform the client of sudden market changes, such as price spikes or regulatory changes, that may require immediate action. Provide suggestions for how the client should respond.`,
    },
  ],

  ComplianceOfficer: [
    {
      id: 'regulatory-compliance-review',
      title: 'Regulatory Compliance Review',
      description: 'Review a document or process for compliance with relevant regulations.',
      prompt: `Draft an email summarizing the compliance review of the attached document or process. Highlight any areas of concern, potential violations, and recommended corrective actions.`,
    },
    {
      id: 'audit-preparation-checklist',
      title: 'Audit Preparation Checklist',
      description: 'Provide a checklist for preparing for an upcoming audit.',
      prompt: `Draft an email with a checklist for audit preparation. Include all necessary documents, processes, and steps required to ensure a smooth and successful audit. Provide deadlines for each task to ensure timely completion.`,
    },
    {
      id: 'compliance-risk-assessment',
      title: 'Compliance Risk Assessment',
      description: 'Assess potential compliance risks associated with a new operation or policy.',
      prompt: `Draft a compliance risk assessment report. Analyze potential risks related to regulatory compliance for the new operation or policy, including financial, operational, and legal risks. Provide recommendations for mitigating these risks.`,
    },
    {
      id: 'policy-update-notification',
      title: 'Policy Update Notification',
      description: 'Notify relevant parties of changes to compliance policies.',
      prompt: `Draft an email notifying relevant parties of updates to compliance policies. Include details of the changes, the reasons behind them, and the actions required to comply with the new policies.`,
    },
    {
      id: 'incident-report',
      title: 'Incident Report',
      description: 'Report a compliance incident and outline corrective actions.',
      prompt: `Draft an incident report email. Detail the compliance incident, including when and how it occurred, its impact, and the corrective actions taken or recommended. Provide suggestions for preventing similar incidents in the future.`,
    },
    {
      id: 'regulatory-change-alert',
      title: 'Regulatory Change Alert',
      description: 'Notify clients or internal teams of changes in regulations that may affect operations.',
      prompt: `Draft a regulatory change alert email. Inform the client or internal teams about recent changes in regulations that may affect their operations. Include details on how the changes impact compliance and any necessary adjustments that need to be made.`,
    },
  ],

  FleetManager: [
    {
      id: 'port-authority-communication',
      title: 'Port Authority Communication',
      description: 'Draft communication with port authorities regarding vessel arrival or departure.',
      prompt: `Draft an email to a port authority regarding the arrival or departure of a vessel. Include details such as vessel name, ETA, cargo type, and any special instructions. Ensure the communication is clear and adheres to port regulations.`,
    },
    {
      id: 'crew-briefing',
      title: 'Crew Briefing',
      description: 'Provide a briefing for the crew before a voyage begins.',
      prompt: `Draft a crew briefing email. Include key details about the upcoming voyage, safety protocols, and any special instructions related to the cargo or route. Ensure that the briefing is comprehensive and easy to understand.`,
    },
    {
      id: 'maintenance-schedule-notification',
      title: 'Maintenance Schedule Notification',
      description: 'Notify relevant parties of upcoming maintenance schedules for vessels.',
      prompt: `Draft an email notifying relevant parties of the upcoming maintenance schedule for vessels. Include details such as the dates, scope of work, and any impact on operations. Provide instructions for contingency plans if needed.`,
    },
    {
      id: 'emergency-response-plan',
      title: 'Emergency Response Plan',
      description: 'Share the emergency response plan with relevant stakeholders.',
      prompt: `Draft an email sharing the emergency response plan. Include key procedures to follow in case of an emergency, contact details for emergency services, and any specific roles assigned to team members.`,
    },
    {
      id: 'fleet-performance-report',
      title: 'Fleet Performance Report',
      description: 'Generate a report on the performance of the fleet over a specified period.',
      prompt: `Draft a fleet performance report email. Include metrics such as fuel efficiency, downtime, maintenance issues, and overall operational efficiency. Provide recommendations for improving performance based on the data.`,
    },
    {
      id: 'client-shipment-confirmation',
      title: 'Client Shipment Confirmation',
      description: 'Confirm the details of a shipment with a client.',
      prompt: `Draft a shipment confirmation email to a client. Include details such as the vessel name, cargo type, departure and arrival dates, and any special instructions. Ensure that all information is accurate and that the client is aware of any actions they need to take.`,
    },
  ],
};

export default promptTemplates;
