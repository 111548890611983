// ContextMenu.js
import React, { forwardRef, useContext } from 'react';
import { ReactComponent as MenuIconMore } from './MenuIconMore.svg';
import { ReactComponent as MenuIconInsights } from './MenuIconInsights.svg';
import { ReactComponent as MenuIconReferences } from './MenuIconReferences.svg';
import { ReactComponent as MenuIconAddNotes } from '../../ReusableComponents/Icons/MenuIconAddNotes.svg';
import { UserContext } from '../../Auth/UserAuth/UserContext';
import { NotesContext } from '../../Auth/UserAuth/NotesContext';
import './ContextMenu.css';

const ContextMenu = forwardRef(({ text, position, options, onSelect }, ref) => {
    const { showUpgradeModal, setShowUpgradeModal, trialEndDate, planName } = useContext(UserContext);
    const { openNoteModal } = useContext(NotesContext);
    const isTrialOver = planName === 'Trial' && new Date() > new Date(trialEndDate);


    const handleOptionClick = (option, text) => {
        if (isTrialOver) {
            setShowUpgradeModal(true);
        } else {
            if (option === "Add notes") {
                openNoteModal(text, position, text); // Pass the highlighted text as quote
                onSelect(null, null); // Hide context menu by calling onSelect with null values
            } else {
                onSelect(option, text);
            }
        }
    };

    return (
        <div
            ref={ref}
            className="context-menu"
            style={{ position: 'fixed', top: position.top, left: position.left }}
        >
            {options.map((option) => (
                <div
                    className="context-menu-option"
                    onClick={() => handleOptionClick(option, text)}
                    key={option}
                >
                    {option === "Tell me more" && <MenuIconMore />}
                    {option === "Give me insights" && <MenuIconInsights />}
                    {option === "Give me references" && <MenuIconReferences />}
                    {option === "Add notes" && <MenuIconAddNotes className="add-notes-icon" />}
                    {option}
                </div>
            ))}
        </div>
    );
});

export default ContextMenu;
