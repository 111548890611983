import React from 'react';
import { Map, Clock } from 'lucide-react';
import './VesselMapWidget.css';

const VesselMapComingSoon = () => {
  return (
    <div className="coming-soon-widget">
      <div className="coming-soon-badge">
        <Clock size={16} />
        <span>Coming Soon</span>
      </div>
    </div>
  );
};

export default VesselMapComingSoon;



//ahh