import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Sparkles } from 'lucide-react'; // Import the Sparkles icon
import './FileUploadWidget.css';
import Button from '../../../ReusableComponents/Buttons/Button';

function FileUploadWidget() {
    const navigate = useNavigate();

    const handleBrowseClick = () => {
        navigate('/doc-intel');
    };

    return (
        <div className="file-upload-widget" onClick={handleBrowseClick}>
            <div className="file-upload-area">
                <div className="upload-icon">
                </div>
                <Button
                    text={
                        <>
                            <Sparkles className="dhh-sparkles-icon" size={16} /> Start
                        </>
                    }
                    color="black"
                    size="medium"
                    className="browse-button"
                    type="button"
                />
                <p className="drop-text">Get started on Doc Intel</p>
                <p className="file-support-text">*Files supported .pdf, .csv, .png, .jpg</p>
            </div>
        </div>
    );
}

export default FileUploadWidget;