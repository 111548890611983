import React, { useState, useEffect, useRef, useContext } from "react";
import { ReactComponent as ChatSendIcon } from '../../ReusableComponents/Icons/ChatSendIcon.svg';
import { ReactComponent as StopChatStreamIcon } from '../../ReusableComponents/Icons/StopChatStream.svg';
import { supabase } from '../../Auth/SupabaseAuth/SupabaseClient';
import { UserContext } from '../../Auth/UserAuth/UserContext';
import { SessionContext } from '../../Auth/UserAuth/SessionContext';
import { ChatContext } from '../../Auth/UserAuth/ChatContext';
import { useDocument } from '../../Auth/UserAuth/DocumentContext';
import StandaloneLoader from '../../ReusableComponents/StandaloneLoader';
import SubtleModal from '../ChatPanel/SubtleModal/SubtleModal'; 
import { trackEvent } from '../../Auth/UserAnalytics/MixPanelConfig';
import './ChatInputBox.css';

const ChatInputBox = ({ onHideQuestionSuggestions, className }) => {
    const textAreaRef = useRef(null);
    const { session, messageCount, setMessageCount, lastMessageDate, setLastMessageDate, showUpgradeModal, setShowUpgradeModal, userPersona, trialEndDate, planName } = useContext(UserContext);
    const { sessionId, selectedMenuText, setSelectedMenuText, selectedMenuOption, setSelectedMenuOption, isContextMenuSubmission, setIsContextMenuSubmission, setShowQuestions } = useContext(SessionContext);
    const { documentId } = useDocument(); // Use documentId from DocumentContext
    const { inputText, setInputText, setMessages, isEmailPrompt, setIsEmailPrompt } = useContext(ChatContext); // Use isEmailPrompt from ChatContext

    const [isLoading, setIsLoading] = useState(false);
    const [isOverLimit, setIsOverLimit] = useState(false);
    const [isStreaming, setIsStreaming] = useState(false);
    const [isStreamingHighlight, setIsStreamingHighlight] = useState(false);
    const [isModalVisible, setIsModalVisible] = useState(false); // Modal visibility state
    let eventSourceRef = useRef(null);

    useEffect(() => {
        if (selectedMenuText) {
            let formattedText;
            if (selectedMenuOption === "Tell me more") {
                formattedText = `Can you elaborate further on the following: ${selectedMenuText}?`;
            } else if (selectedMenuOption === "Give me insights") {
                formattedText = `I would like more insights about the following: ${selectedMenuText}. What else can you tell me to uncover deeper insights?`;
            } else if (selectedMenuOption === "Give me references") {
                formattedText = `Could you provide references and page numbers related to the following: ${selectedMenuText}?`;
            }
            setInputText(formattedText);
            setIsContextMenuSubmission(true);
        }
    }, [selectedMenuText, selectedMenuOption, setInputText, setIsContextMenuSubmission]);

    useEffect(() => {
        if (inputText && isContextMenuSubmission) {
            handleSendClick();
            setIsContextMenuSubmission(false);
            setSelectedMenuText('');
        }
    }, [inputText, isContextMenuSubmission, setIsContextMenuSubmission, setSelectedMenuText]);

    useEffect(() => {
        if (textAreaRef.current) {
            textAreaRef.current.style.height = "auto";
            textAreaRef.current.style.height = textAreaRef.current.scrollHeight + "px";
        }
    }, [inputText]);

    useEffect(() => {
        if (inputText && isEmailPrompt) {
            setIsModalVisible(true);  // Show modal if it's an email prompt
        }
    }, [inputText, isEmailPrompt]);

    const handleKeyDown = (event) => {
        if (event.key === 'Enter' && !event.shiftKey) {
            event.preventDefault();
            handleSendClick();
        }
    };

    const handleTextChange = (e) => {
        setInputText(e.target.value);
    };

    const closeSubtleModal = () => {
        setIsModalVisible(false);
        setIsEmailPrompt(false); // Reset the email prompt flag after showing the modal
    };

    const checkMessageLimit = () => {
        if (lastMessageDate === null || messageCount === null) {
            setIsOverLimit(false);
            return;
        }
        const today = new Date();
        const sevenDaysAgo = new Date(today);
        sevenDaysAgo.setDate(today.getDate() - 7);
        const limitReached = (new Date(lastMessageDate) > sevenDaysAgo && messageCount >= 7);
        setIsOverLimit(limitReached);
    };

    const isTrialOver = planName === 'Trial' && new Date() > new Date(trialEndDate);
    const isTextAreaDisabled = isLoading || (isTrialOver && isOverLimit) || !sessionId;
    const isButtonDisabled = isLoading || (isTrialOver && isOverLimit) || !sessionId;

    useEffect(() => {
        checkMessageLimit();
    }, [messageCount, lastMessageDate]);

    useEffect(() => {
        if (isOverLimit && isTrialOver) {
            setShowUpgradeModal(true);
        } else {
            setShowUpgradeModal(false);
        }
    }, [isOverLimit, isTrialOver, setShowUpgradeModal]);

    useEffect(() => {
        if (isStreaming) {
            setIsStreamingHighlight(true);
        } else if (!isStreaming && isStreamingHighlight) {
            const timer = setTimeout(() => {
                setIsStreamingHighlight(false);
            }, 2000); // Keep the highlight for 2 seconds after streaming ends
            return () => clearTimeout(timer);
        }
    }, [isStreaming]);

    const apiUrl = process.env.NODE_ENV === 'development' 
        ? process.env.REACT_APP_API_URL 
        : process.env.REACT_APP_PRODUCTION_API_URL;

    const handleSendClick = async () => {
        setIsLoading(true);
        trackEvent("User Message Interaction", { type: "Message Sent Initiated" });

        const messageData = {
            user_id: session.user.id,
            session_id: sessionId,
            message: inputText,
            message_type: 'question',
            document_id: documentId
        };

        try {
            const messageId = await sendMessageToServer(messageData);
            setMessages(prevMessages => [...prevMessages, { type: 'user', text: inputText, id: messageId }]);
            setInputText("");
            onHideQuestionSuggestions();
            await streamAssistantResponse();
            await updateMessageMetrics();
        } catch (error) {
            console.error(error.message);
            trackEvent("Message Interaction Error", { error: error.message });
        }
    };

   

    const streamAssistantResponse = async () => {
    const queryParams = new URLSearchParams({
        documentId,
        userMessage: inputText,
        userPersona,
        sessionId,
        accessToken: session.access_token
    });

    eventSourceRef.current = new EventSource(`${apiUrl}/query-sse?${queryParams}`);
    let assistantResponse = '';

    eventSourceRef.current.onmessage = (event) => {
        if (event.data === '[DONE]') {
            eventSourceRef.current.close();
            setIsStreaming(false); // Set streaming to false when the streaming ends
            logAssistantResponse(assistantResponse);
        } else {
            setIsLoading(false); // Hide the loading indicator when streaming starts
            setIsStreaming(true); // Set streaming to true when the streaming starts
            assistantResponse += event.data;
            setMessages(prevMessages => {
                const lastMessage = prevMessages[prevMessages.length - 1];
                if (lastMessage && lastMessage.type === 'assistant') {
                    return [...prevMessages.slice(0, -1), { ...lastMessage, text: assistantResponse }];
                } else {
                    return [...prevMessages, { type: 'assistant', text: assistantResponse }];
                }
            });
        }
    };

    eventSourceRef.current.onerror = (error) => {
        console.error('EventSource failed:', error);
        eventSourceRef.current.close();
        setIsStreaming(false); // Handle error and stop streaming
        setIsLoading(false); // Ensure loading state is reset on error
    };
};


    

    const handleStopClick = () => {
        if (eventSourceRef.current) {
            eventSourceRef.current.close(); // Close the EventSource
            setIsStreaming(false); // Update the streaming state
        }
    };

    async function sendMessageToServer(messageData) {
        const response = await fetch(`${apiUrl}/api/messages`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${session.access_token}`
            },
            body: JSON.stringify(messageData)
        });

        if (!response.ok) {
            const errorText = await response.text(); // Capture the response text for debugging
            console.error('Response from server:', errorText);
            throw new Error('Failed to send message');
        }

        const responseData = await response.json();
        return responseData.data; // Ensure this is the message_id
    }

    async function logAssistantResponse(assistantResponse) {
        const logResponse = await fetch(`${apiUrl}/api/messages`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${session.access_token}`
            },
            body: JSON.stringify({
                user_id: session.user.id,
                session_id: sessionId,
                message: assistantResponse,
                message_type: 'answer',
                document_id: documentId // Log the response with documentId
            })
        });
        if (!logResponse.ok) {
            throw new Error('Failed to log assistant response');
        }
    }

    async function updateMessageMetrics() {
        const newMessageCount = messageCount + 1;
        const newLastMessageDate = new Date();
        setMessageCount(newMessageCount);
        setLastMessageDate(newLastMessageDate);

        try {
            const response = await fetch(`${apiUrl}/api/update-message-metrics`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${session.access_token}`
                },
                body: JSON.stringify({
                    userId: session.user.id,
                    sessionId: sessionId,
                    newMessageCount: newMessageCount,
                    newLastMessageDate: newLastMessageDate
                })
            });

            if (!response.ok) {
                const errorText = await response.text();
                throw new Error('Failed to update message metrics: ' + errorText);
            }
        } catch (error) {
            console.error('Error in updateMessageMetrics:', error.message);
        }
    }

    return (
        <div className={`chat-input-container ${className || ''}`}>
            <textarea
                ref={textAreaRef}
                className={`chat-input ${isStreamingHighlight ? 'is-streaming' : ''}`}
                placeholder={isLoading || isStreaming ? "" : "Type your message here..."}
                value={inputText}
                onChange={handleTextChange}
                onKeyDown={handleKeyDown}
                disabled={isTextAreaDisabled}
                title={isTextAreaDisabled ? "Upload to get started" : ""}
            ></textarea>
            {isLoading && !isStreaming && (
                <div className="loader-container">
                    <StandaloneLoader size={16} color={'#000'} />
                </div>
            )}
            <button
                className={`chat-send-button ${isStreaming ? 'stop-streaming-icon' : ''}`}
                onClick={isStreaming ? handleStopClick : handleSendClick}
                disabled={isButtonDisabled}
            >
                {isStreaming ? <StopChatStreamIcon /> : <ChatSendIcon />}
            </button>

            {isModalVisible && (
                <SubtleModal
                    isVisible={isModalVisible}
                    title="Ready to generate?"
                    content="Your email prompt is ready! Press send to generate the message or edit as needed."
                    onClose={closeSubtleModal}
                />
            )}
        </div>
    );
};

export default ChatInputBox;
