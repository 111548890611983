

//ChatMessageList.js to render the message items --manage the order of the messages and handle rendering of different message types.

import React, { useEffect, useRef, useState } from 'react';
import AIChatMessageItem from './AIChatMessageItem';
import UserChatMessageItem from './UserChatMessageItem';
import './ChatMessageList.css';

const ChatMessageList = ({ messages }) => {
    const chatContainerRef = useRef(null);
    const [isAutoScrolling, setIsAutoScrolling] = useState(true);

    useEffect(() => {
        const container = chatContainerRef.current;

        const handleScroll = () => {
            const isAtBottom = container.scrollHeight - container.scrollTop === container.clientHeight;
            setIsAutoScrolling(isAtBottom);
        };

        if (container) {
            container.addEventListener('scroll', handleScroll);
        }

        return () => {
            if (container) {
                container.removeEventListener('scroll', handleScroll);
            }
        };
    }, []);

    useEffect(() => {
        const container = chatContainerRef.current;

        if (container && isAutoScrolling) {
            container.scrollTo({
                top: container.scrollHeight,
                behavior: 'smooth',
            });
        }
    }, [messages, isAutoScrolling]);

    return (
        <div ref={chatContainerRef} className="chat-message-list-container">
            {messages.map((message, index) => (
                message.type === 'user'
                    ? <UserChatMessageItem key={index} text={message.text} />
                    : <AIChatMessageItem key={`${index}-${message.text.slice(0, 10)}`} text={message.text} />
            ))}
        </div>
    );
};

export default ChatMessageList;
