import React, { useContext, useEffect, useState } from 'react';
import { SessionContext } from '../../Auth/UserAuth/SessionContext';
import { UserContext } from '../../Auth/UserAuth/UserContext';
import { ChatContext } from '../../Auth/UserAuth/ChatContext';
import { useDocument } from '../../Auth/UserAuth/DocumentContext';
import ChatMessageList from './ChatMessageList';
import ChatInputBox from './ChatInputBox';
import QuestionSuggestions from './QuestionSuggestions';
import ChatPanelHeaderBar from './ChatPanelHeaderBar';
import IntroChatModal from '../ChatPanel/ChatUtils/IntroChatModal';
import { trackEvent } from '../../Auth/UserAnalytics/MixPanelConfig';
import './ChatPanel.css';

const ChatPanel = ({ className, onNewMessage }) => {
    const { clearSession, setClearSession, sessionId, isRestoringSession, justCleared, showQuestions, setShowQuestions } = useContext(SessionContext);
    const { userPersona, accessToken, setAccessToken, session } = useContext(UserContext);
    const { messages, setMessages, showIntro, setShowIntro, inputText, setInputText } = useContext(ChatContext);  // Get inputText and setInputText from ChatContext
    const { showProcessedDocPanel, bolDetails, documentId } = useDocument();

    useEffect(() => {
        if (session) {
            setAccessToken(session.access_token);
        }
    }, [session, setAccessToken]);

    const handleSuggestedQuestionClick = (question) => {
        setInputText(question);  // Use setInputText from context
        setShowQuestions(false);
        sessionStorage.setItem('showQuestions', 'false');
        trackEvent("Suggested Question Click", { question: question });
    };

    useEffect(() => {
        const storedShowQuestions = sessionStorage.getItem('showQuestions');
        if (storedShowQuestions) {
            setShowQuestions(storedShowQuestions === 'true');
        } else {
            setShowQuestions(true);
        }
    }, [sessionId, isRestoringSession, setShowQuestions]);

    useEffect(() => {
        if (clearSession) {
            setMessages([]);
            sessionStorage.removeItem('messages');
            setClearSession(false);
        }
    }, [clearSession, setClearSession, setMessages]);

    useEffect(() => {
        const showIntroModal = localStorage.getItem('showIntroModal') !== 'false';
        setShowIntro(showIntroModal);
    }, [setShowIntro]);

    const hideQuestionSuggestions = () => {
        setShowQuestions(false);
        sessionStorage.setItem('showQuestions', 'false');
    };

    const addMessage = (message) => {
        setMessages(prevMessages => {
            const updatedMessages = [...prevMessages, message];
            sessionStorage.setItem('messages', JSON.stringify(updatedMessages));
            if (showIntro) {
                setShowIntro(false);
                localStorage.setItem('showIntroModal', 'false');
            }
            return updatedMessages;
        });
        onNewMessage();
    };

    const handleIntroDismiss = () => {
        setShowIntro(false);
        localStorage.setItem('showIntroModal', 'false');
    };

    useEffect(() => {
        const cleanup = () => {
            // Cleanup logic
        };
        window.addEventListener('beforeunload', cleanup);
        return () => {
            window.removeEventListener('beforeunload', cleanup);
        };
    }, []);

    return (
        <div className="chat-panel">
            <ChatPanelHeaderBar />
            {showProcessedDocPanel && (
                <div className="document-data">
                    {/* Render document data here */}
                    <pre>{JSON.stringify(bolDetails, null, 2)}</pre>
                </div>
            )}
            {showIntro && <IntroChatModal onDismiss={handleIntroDismiss} />}
            <ChatMessageList messages={messages} />
            {!isRestoringSession && !justCleared && showQuestions && (
                <QuestionSuggestions
                    onQuestionClick={handleSuggestedQuestionClick}
                    documentId={documentId}
                    userPersona={userPersona}
                />
            )}
            <ChatInputBox
                addMessage={addMessage}
                onHideQuestionSuggestions={hideQuestionSuggestions}
                className={className}
            />
            <div className="mistake-style">
                <p>Comovis can occasionally make mistakes. Please double check info.</p>
            </div>
        </div>
    );
};

export default ChatPanel;
