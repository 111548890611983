import React from 'react';
import { BarChart2, Mail, FileText, MessageSquare } from 'lucide-react';
import './StatsInsights.css';

const StatsInsightsWidget = ({ sessions, emails, exports, aiMessages = 26 }) => { // Default aiMessages to 26
  const StatItem = ({ icon: Icon, label, value }) => (
    <div className="stat-item">
      <div className="stat-icon">
        <Icon size={16} />
      </div>
      <div className="stat-content">
        <div className="stat-value">{value}</div>
        <div className="stat-label">{label}</div>
      </div>
    </div>
  );

  return (
    <div className="stats-insights-widget">
      <div className="stats-container">
        <div className="stats-row">
          <StatItem icon={BarChart2} label="Sessions" value={sessions} />
          <StatItem icon={FileText} label="Exports" value={exports} />
        </div>
        <div className="stats-row">
          <StatItem icon={Mail} label="Emails" value={emails} />
          <StatItem icon={MessageSquare} label="AI Messages" value={aiMessages} />
        </div>
      </div>
    </div>
  );
};

export default StatsInsightsWidget;
