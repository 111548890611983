import React, { useState, useContext, useEffect } from 'react';
import PropTypes from 'prop-types';
import './DataBOLExtract.css';
import { useDocument } from '../../Auth/UserAuth/DocumentContext';
import { UserContext } from '../../Auth/UserAuth/UserContext';
import { ReactComponent as WarningSign } from '../../ReusableComponents/Icons/WarningSign.svg'; 
import { ReactComponent as DeleteField } from '../../ReusableComponents/Icons/DeleteField.svg'; // Importing the DeleteField SVG
import TooltipWrapper from '../../ReusableComponents/Tooltip/TooltipWrapper';
import Button from '../../ReusableComponents/Buttons/Button'; // Assuming you have the Button component
import BOL_TEMPLATE from './BOLTemplate';

const ConfirmationModal = ({ onConfirm, onCancel }) => {
    return (
        <div className="BOL-confirmation-modal">
            <p>Are you sure you want to delete? This will delete the entire field and value upon saving.</p>
            <div className="button-container">
                <Button text="Delete" color="red" size="small" onClick={onConfirm} />
                <Button text="Cancel" color="black" size="small" onClick={onCancel} />
            </div>
        </div>
    );
};



const mergeWithTemplate = (data, template) => {
    const mergedData = {};

    // Loop over the sections in the template to ensure order
    Object.keys(template).forEach(section => {
        mergedData[section] = { ...template[section], ...data[section] };
    });

    // Add any sections/fields from the data that are not in the template
    Object.keys(data).forEach(section => {
        if (!mergedData[section]) {
            mergedData[section] = data[section];
        } else {
            Object.keys(data[section]).forEach(field => {
                if (!mergedData[section][field]) {
                    mergedData[section][field] = data[section][field];
                }
            });
        }
    });

    return mergedData;
};


const DataBOLExtract = ({ data }) => {
    const { documentId, setBolDetails } = useDocument();
    const { session } = useContext(UserContext);
    const [editMode, setEditMode] = useState({});
    const [editedValues, setEditedValues] = useState({});
    const [fieldsToDelete, setFieldsToDelete] = useState({});
    const [newField, setNewField] = useState({});
    const [showNewFieldInputs, setShowNewFieldInputs] = useState({});
    const [activeModal, setActiveModal] = useState(null); // Track which field's modal is active
    const [newSection, setNewSection] = useState(''); // State to handle new section title input
    const [addingSection, setAddingSection] = useState(false); // State to toggle section addition

    const handleEditClick = (sectionKey, fieldKey) => {
        setEditMode(prevState => ({
            ...prevState,
            [sectionKey]: {
                ...prevState[sectionKey],
                [fieldKey]: true
            }
        }));
    };
    

       useEffect(() => {
        // Reorder the data according to the template during load
        const mergedData = mergeWithTemplate(data, BOL_TEMPLATE);
        setBolDetails(mergedData);
    }, [data, setBolDetails]);


    const handleEditSectionClick = (sectionKey) => {
        const isEditing = Object.values(editMode[sectionKey] || {}).some(value => value);

        if (!isEditing) {
            const newEditMode = Object.keys(data[sectionKey]).reduce((acc, fieldKey) => {
                acc[fieldKey] = true; // Set all fields in the section to edit mode
                return acc;
            }, {});
            
            setEditMode(prevState => ({
                ...prevState,
                [sectionKey]: newEditMode
            }));
    
            // Merge any individual edits with current section values
            setEditedValues(prevState => ({
                ...prevState,
                [sectionKey]: { ...data[sectionKey], ...editedValues[sectionKey] }
            }));
        }
    };

    const handleInputChange = (sectionKey, fieldKey, value) => {
        setEditedValues(prevState => ({
            ...prevState,
            [sectionKey]: {
                ...prevState[sectionKey],
                [fieldKey]: value
            }
        }));
    };

    const handleDeleteField = (sectionKey, fieldKey) => {
        setActiveModal({ sectionKey, fieldKey });
    };

    const confirmDeleteField = () => {
        const { sectionKey, fieldKey } = activeModal;

        // Mark the field for deletion in the fieldsToDelete state
        setFieldsToDelete(prevState => ({
            ...prevState,
            [sectionKey]: {
                ...prevState[sectionKey],
                [fieldKey]: true
            }
        }));

        // Immediately remove the field from the editedValues state
        setEditedValues(prevState => {
            const updatedSection = { ...prevState[sectionKey] };
            delete updatedSection[fieldKey]; // Remove the entire field from editedValues
            return {
                ...prevState,
                [sectionKey]: updatedSection
            };
        });

        setActiveModal(null); // Close the modal
    };

    const cancelDeleteField = () => {
        setActiveModal(null); // Close the modal without making any changes
    };

    const handleNewFieldChange = (sectionKey, fieldKey, value) => {
        setNewField(prevState => ({
            ...prevState,
            [sectionKey]: {
                ...prevState[sectionKey],
                [fieldKey]: value
            }
        }));
    };

    const handleAddNewField = (sectionKey) => {
        const fieldName = newField[sectionKey]?.name;
        const fieldValue = newField[sectionKey]?.value;

        if (fieldName && fieldValue) {
            const updatedSection = {
                ...data[sectionKey],
                [fieldName]: fieldValue
            };

            handleSaveClick(sectionKey, updatedSection);
            setNewField(prevState => ({
                ...prevState,
                [sectionKey]: {}
            }));
            setShowNewFieldInputs(prevState => ({
                ...prevState,
                [sectionKey]: false
            }));
        } else {
            setShowNewFieldInputs(prevState => ({
                ...prevState,
                [sectionKey]: false
            }));
        }
    };

    const handleCancelNewField = (sectionKey) => {
        setNewField(prevState => ({
            ...prevState,
            [sectionKey]: {}
        }));
        setShowNewFieldInputs(prevState => ({
            ...prevState,
            [sectionKey]: false
        }));
    };

    const handleSaveClick = async (sectionKey, updatedSection = null) => {
        // Prepare the updated data, removing fields marked for deletion
        const updatedData = {
            ...data,
            [sectionKey]: updatedSection || {
                ...data[sectionKey],
                ...editedValues[sectionKey]
            }
        };

        // Remove fields that are marked for deletion from the final data
        if (fieldsToDelete[sectionKey]) {
            Object.keys(fieldsToDelete[sectionKey]).forEach(fieldKey => {
                if (fieldsToDelete[sectionKey][fieldKey]) {
                    delete updatedData[sectionKey][fieldKey];
                }
            });
        }

        setBolDetails(updatedData);

        try {
            const apiURL = process.env.NODE_ENV === 'development' ? process.env.REACT_APP_API_URL : process.env.REACT_APP_PRODUCTION_API_URL;

            const response = await fetch(`${apiURL}/save-extracted-data`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${session.access_token}`,
                },
                body: JSON.stringify({ documentId, data: updatedData }),
            });

            if (!response.ok) {
                const errorText = await response.text();
                throw new Error(`Failed to save updated data: ${errorText}`);
            }

            const result = await response.json();
            console.log('Data updated successfully:', result);
        } catch (error) {
            console.error('Error updating data:', error.message);
        }

        // Exit edit mode after saving
        setEditMode(prevState => ({
            ...prevState,
            [sectionKey]: Object.keys(prevState[sectionKey] || {}).reduce((acc, fieldKey) => {
                acc[fieldKey] = false;
                return acc;
            }, {})
        }));
        setFieldsToDelete(prevState => ({
            ...prevState,
            [sectionKey]: {}
        }));
    };

    const handleAddNewSection = () => {
        if (newSection.trim()) {
            const cleanTitle = newSection.trim();
            if (!data[cleanTitle]) {
                const updatedData = {
                    ...data,
                    [cleanTitle]: {} // Add a new empty section
                };

                setBolDetails(updatedData); // Update the UI with the new section
                setNewSection(''); // Clear the input field
                setAddingSection(false); // Hide the input box after adding the section

                // Save the new section to the database
                handleSaveClick(cleanTitle, {}); 
            } else {
                alert('Section already exists!');
            }
        }
    };

    const renderSection = (title, content) => {
        const cleanTitle = title.replace(/^\*+|\*+$/g, '').trim();
        const isSectionInEditMode = Object.values(editMode[cleanTitle] || {}).some(value => value);
        const showInputs = showNewFieldInputs[cleanTitle];

        return (
            <div className="section" key={cleanTitle}>
                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    <h3>{cleanTitle}</h3>
                    {isSectionInEditMode ? (
                        <button
                            className="BOL-edit-button"
                            onClick={() => handleSaveClick(cleanTitle)}
                        >
                            Save Section
                        </button>
                    ) : (
                        <button
                            className="BOL-edit-button"
                            onClick={() => handleEditSectionClick(cleanTitle)}
                        >
                            Edit Section
                        </button>
                    )}
                </div>
                {Object.entries(content).map(([key, value]) => (
                    <div key={key} style={{ position: 'relative' }}>
                        <p style={{ display: 'flex', alignItems: 'center', opacity: fieldsToDelete[cleanTitle]?.[key] ? 0.5 : 1 }}>
                            {editMode[cleanTitle]?.[key] && (
                                <TooltipWrapper 
                                    id={`${cleanTitle}-${key}-delete-tooltip`} 
                                    content="Delete entire field."
                                >
                                    <DeleteField
                                        className="delete-field-icon"
                                        onClick={() => handleDeleteField(cleanTitle, key)}
                                        style={{ cursor: 'pointer', marginRight: '8px' }}
                                    />
                                </TooltipWrapper>
                            )}
                            <strong>{key.replace(/^\*+|\*+$/g, '').trim()}:</strong>&nbsp;
                            {editMode[cleanTitle]?.[key] ? (
                                <input
                                    type="text"
                                    value={editedValues[cleanTitle]?.[key] || ''}
                                    onChange={(e) => handleInputChange(cleanTitle, key, e.target.value)}
                                    style={{ marginRight: '8px' }}
                                    disabled={fieldsToDelete[cleanTitle]?.[key]} // Disable input if marked for deletion
                                />
                            ) : (
                                value || ' ' // Display an empty space if value is deleted
                            )}
                            {(value === 'Not Initially Found' || editMode[cleanTitle]?.[key]) && !fieldsToDelete[cleanTitle]?.[key] && (
                                <>
                                    {value === 'Not Initially Found' && (
                                        <TooltipWrapper 
                                            id={`${cleanTitle}-${key}-tooltip`}
                                            content="Please review and update."
                                            style={{ marginLeft: '8px', display: 'inline-flex', alignItems: 'center' }}
                                        >
                                            <WarningSign className="BOL-warning-icon" />
                                        </TooltipWrapper>
                                    )}
                                    <button
                                        className="BOL-edit-button"
                                        onClick={() => editMode[cleanTitle]?.[key] ? handleSaveClick(cleanTitle) : handleEditClick(cleanTitle, key)}
                                    >
                                        {editMode[cleanTitle]?.[key] ? 'Save' : 'Edit'}
                                    </button>
                                </>
                            )}
                        </p>
                        {activeModal?.sectionKey === cleanTitle && activeModal?.fieldKey === key && (
                            <ConfirmationModal
                                onConfirm={confirmDeleteField}
                                onCancel={cancelDeleteField}
                            />
                        )}
                    </div>
                ))}
                {/* Add New Field Section */}
                {showInputs ? (
                    <div className="new-field">
                        <input
                            type="text"
                            placeholder="New Field Name"
                            value={newField[cleanTitle]?.name || ''}
                            onChange={(e) => handleNewFieldChange(cleanTitle, 'name', e.target.value)}
                        />
                        <input
                            type="text"
                            placeholder="New Field Value"
                            value={newField[cleanTitle]?.value || ''}
                            onChange={(e) => handleNewFieldChange(cleanTitle, 'value', e.target.value)}
                        />
                        <button
                            className="new-field-button"
                            onClick={() => handleAddNewField(cleanTitle)}
                        >
                            {newField[cleanTitle]?.name || newField[cleanTitle]?.value ? 'Save Field' : 'Cancel'}
                        </button>
                    </div>
                ) : (
                    <button
                        className="new-field-button"
                        onClick={() => setShowNewFieldInputs(prevState => ({
                            ...prevState,
                            [cleanTitle]: true
                        }))}
                    >
                        + Add New Field
                    </button>
                )}
            </div>
        );
    };

    return (
        <div className="bol-extract">
            {Object.entries(data).map(([title, content]) => renderSection(title, content))}
            {/* Add New Section */}
            <div className="add-section-container">
                {addingSection ? (
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <input
                            type="text"
                            placeholder="Section Title"
                            value={newSection}
                            onChange={(e) => setNewSection(e.target.value)}
                            style={{ marginRight: '8px', flexGrow: 1 }}
                        />

                        
                        <button
                            className="new-field-button"
                            onClick={handleAddNewSection}
                        >
                            Save Section
                        </button>


                    </div>
                ) : (
                    <Button
                        text="+ Add New Section"
                        color="black"
                        size="medium"
                        onClick={() => setAddingSection(true)}
                        className="BOL-add-section-button"
                    />
                )}
            </div>
        </div>
    );
};

DataBOLExtract.propTypes = {
    data: PropTypes.object.isRequired
};

export default DataBOLExtract;
