import * as React from 'react';
import { Body, Column, Container, Head, Heading, Hr, Html, Link, Preview, Section, Text } from '@react-email/components';

export function UserUpgradedEmail(props) {
  const previewText = "Welcome to Comovis Premium! Explore your new benefits.";

  return (
    <Html>
      <Head>
        <style>
          {"@import url('https://fonts.googleapis.com/css2?family=Inter&display=swap');"}
        </style>
      </Head>
      <Preview>{previewText}</Preview>
      <Body style={{ backgroundColor: 'white', fontFamily: "'Inter', sans-serif" }}>
        <Container style={{ border: '1px solid #eaeaea', borderRadius: '8px', margin: '40px auto', padding: '20px', width: '465px' }}>
          <Section style={{ textAlign: 'center', marginTop: '32px' }}>
            <img
              src="https://skyxbncllurrcsexskvy.supabase.co/storage/v1/object/public/comovis-images/EmailLogoBlackPNG.png"
              width="140"
              height="130"
              alt="Comovis"
              style={{ margin: 'auto' }}
            />
          </Section>
          <Heading style={{ color: 'black', fontSize: '24px', textAlign: 'center', margin: '30px auto 0 auto' }}>
            Welcome to Comovis Premium!
          </Heading>
          <Text style={{ color: 'black', fontSize: '16px', lineHeight: '24px', textAlign: 'center' }}>
            You've unlocked a universe of possibilities. We're thrilled to have you aboard!
          </Text>
          <Section style={{ marginTop: '20px' }}>
            <Text style={{ color: 'black', fontSize: '16px', lineHeight: '24px' }}>
              🎉 Enjoy your Premium Benefits:
            </Text>
            <Text style={{ color: 'black', fontSize: '16px', lineHeight: '24px' }}>
              🚀 Unlimited Insights: Real-time, contextually accurate answers to supercharge your analysis.
            </Text>
            <Text style={{ color: 'black', fontSize: '16px', lineHeight: '24px' }}>
              📂 Unlimited Document Uploads: Pre-processed to align with your specific user-persona.
            </Text>
            <Text style={{ color: 'black', fontSize: '16px', lineHeight: '24px' }}>
              🛡️ Security & Privacy: Rest easy with EU data centres and full GDPR compliance.
            </Text>
            <Text style={{ color: 'black', fontSize: '16px', lineHeight: '24px' }}>
              📞 Priority Support: Get fast and dedicated assistance whenever you need it.
            </Text>
          </Section>
          <Hr style={{ border: '1px solid #eaeaea', margin: '26px 0', width: '100%' }} />
          <Text style={{ color: '#666666', fontSize: '12px', lineHeight: '24px' }}>
            Need help? Reach out at{' '}
            <Link href={`mailto:support@comovis.ai`} style={{ color: 'blue', textDecoration: 'none' }}>
              support@comovis.ai
            </Link>
          </Text>
          <Text style={{ color: '#666666', fontSize: '12px', lineHeight: '24px' }}>
            Please do not reply back to this email as it is not monitored.
          </Text>
          <Text style={{ color: '#666666', fontSize: '12px', lineHeight: '24px' }}>
            Comovis Analytics, London, United Kingdom
          </Text>
        </Container>
      </Body>
    </Html>
  );
};
