

// Resposnible for collecting user input and triggering a send message action in the explore page map

// ChatInputBox.js
import React, { useState, useEffect, useRef, useContext } from "react";
import { ReactComponent as ChatSendIcon } from '../../../ReusableComponents/Icons/ChatSendIcon.svg';
import { UserContext } from '../../../Auth/UserAuth/UserContext';
import { MapSessionContext } from '../../../Auth/UserAuth/MapSessionContext'; 
import { SessionContext } from '../../../Auth/UserAuth/SessionContext';
import { supabase } from '../../../Auth/SupabaseAuth/SupabaseClient';
import Loading from 'react-loading';
import { trackEvent } from '../../../Auth/UserAnalytics/MixPanelConfig';
import './MapChatInputBox.css';



const MapChatInputBox = ({ addMessage, inputText, setInputText, className }) => { // Change here
    const textAreaRef = useRef(null);
    const { session, messageCount, setMessageCount, lastMessageDate, setLastMessageDate, showUpgradeModal, setShowUpgradeModal } = useContext(UserContext);
    
    const { sessionId, selectedMenuText,
        setSelectedMenuText, selectedMenuOption, setSelectedMenuOption, isContextMenuSubmission, setIsContextMenuSubmission, setShowQuestions } = useContext(SessionContext);

         const { mapSessionId, startMapSession, imo, mmsi, setIMO, setMMSI, updateLat, updateLon, updateName, updateTypeSpecific, updateDepPortCoords, updateDestPortCoords, updateTrackHistory, updateCourse, updateHeading, updateMMSI } = useContext(MapSessionContext);

    const [isLoading, setIsLoading] = useState(false);
    const { userPersona, trialEndDate, planName } = useContext(UserContext);
    const [isOverLimit, setIsOverLimit] = useState(false);










    
    // Responsible for constructing the highlighted text based on the user's selection
    useEffect(() => {
        if (selectedMenuText) {
            //console.log("Selected Menu Text:", selectedMenuText);
            //console.log("Selected Menu Option:", selectedMenuOption);

            let formattedText; // This will hold the formatted JSX

            if (selectedMenuOption === "Tell me more") {
                formattedText = `Can you elaborate further on the following: ${selectedMenuText}?`;
            } else if (selectedMenuOption === "Give me insights") {
                formattedText = `I would like more insights about the following: ${selectedMenuText}. What else can you tell me to uncover deeper insights?`;
            } else if (selectedMenuOption === "Give me references") {
                formattedText = `Could you provide references and page numbers related to the following: ${selectedMenuText}?`;
            }

            setInputText(formattedText); // This will set the input text field in your UI
            setIsContextMenuSubmission(true);

            //console.log("Setting inputText to:", formattedText);
        }
    }, [selectedMenuText, selectedMenuOption]);




    // Responsible for sending the message when inputText is updated
    useEffect(() => {
        if (inputText && isContextMenuSubmission) {
            //console.log("Current inputText state:", inputText);

            handleSendClick();  // Auto-submit the message

            // Important: reset 'isContextMenuSubmission' before 'inputText' to prevent loop
            setIsContextMenuSubmission(false);
            setSelectedMenuText('');  // Clear the selected text after submission

            //console.log("Resetting selectedMenuText to:", '');
        }
    }, [inputText, isContextMenuSubmission]);






    // Auto-resize the text area when the text changes
    useEffect(() => {
        if (textAreaRef.current) {
            textAreaRef.current.style.height = "auto";
            textAreaRef.current.style.height = textAreaRef.current.scrollHeight + "px";
        }
    }, [inputText]); // Change here





    // Allows user to submit using enter
    const handleKeyDown = (event) => {
        if (event.key === 'Enter' && !event.shiftKey) {
            event.preventDefault(); // Prevents the newline from being added to the textarea
            handleSendClick();
        }
    };




    // Handle changes to the text in the input box
    const handleTextChange = (e) => {
        setInputText(e.target.value); // Change here
    };







    const checkMessageLimit = () => {
        if (lastMessageDate === null || messageCount === null) {
            setIsOverLimit(false); // Allow the user to send messages if these fields are null
            return;
        }

        const today = new Date();
        const sevenDaysAgo = new Date(today);
        sevenDaysAgo.setDate(sevenDaysAgo.getDate() - 7);

        const limitReached = (new Date(lastMessageDate) > sevenDaysAgo && messageCount >= 7);
        setIsOverLimit(limitReached); // Set whether the user has reached the message limit or not
    };






    // Check if the trial has ended
    const isTrialOver = planName === 'Trial' && new Date() > new Date(trialEndDate);

   
    // Calculate the disabled conditions
    const isTextAreaDisabled = isLoading || (isTrialOver && isOverLimit);
    const isButtonDisabled = isLoading || (isTrialOver && isOverLimit);




    useEffect(() => {
        checkMessageLimit(); // this function should also set `isOverLimit`
    }, [messageCount, lastMessageDate]);


    //Displays the upgrade modal
    useEffect(() => {
        if (isOverLimit && isTrialOver) {
            setShowUpgradeModal(true);
        } else {
            setShowUpgradeModal(false);
        }
    }, [isOverLimit, isTrialOver]);




    // Define API URL at component level
    const apiUrl = process.env.NODE_ENV === 'development' 
        ? process.env.REACT_APP_API_URL 
        : process.env.REACT_APP_PRODUCTION_API_URL;






    
// Function to ensure a session exists or start a new one
const ensureSession = async () => {
    let sessionId = sessionStorage.getItem('mapSessionId');
    //console.log("Retrieved Session ID:", sessionId); // Debug: Check retrieved ID

    if (!sessionId) {
        //console.log("No Session ID found, creating new session."); // Debug: Log when new session is created
        sessionId = await startMapSession(); // Assume this function returns a new session ID
        sessionStorage.setItem('mapSessionId', sessionId);
        //console.log("New Session ID set:", sessionId); // Debug: Verify new ID is set
    }
    return sessionId;
};


const handleSendClick = async () => {
    setIsLoading(true);
    const sessionId = await ensureSession();

    if (!sessionId) {
        console.error('No valid session ID, cannot send message');
        setIsLoading(false);
        return;
    }

    const messageData = {
        user_id: session.user.id,
        session_id: sessionId,
        message: inputText,
        message_type: 'question'
    };

    try {
        const response = await fetch(`${apiUrl}/api/map-explore-messages`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${session.access_token}`
            },
            body: JSON.stringify(messageData)
        });

        if (!response.ok) {
            throw new Error('Failed to send message');
        }

        addMessage({ type: 'user', text: inputText });
        setInputText("");

        const isGeneralQuery = inputText.trim().startsWith('*');
        let userMessageWithID = inputText.trim();
        if (isGeneralQuery) {
            userMessageWithID = inputText.slice(1).trim();
        } else {
            // Enhanced regex to detect IMO and MMSI numbers separately
            const imoRegex = /\b(\d{7})\b/;
            const mmsiRegex = /\b(\d{9})\b/;
            const imoMatch = inputText.match(imoRegex);
            const mmsiMatch = inputText.match(mmsiRegex);

            if (imoMatch && imoMatch[1]) {
                const detectedIMO = imoMatch[1];
                sessionStorage.setItem('imo', detectedIMO);
                setIMO(detectedIMO);
                userMessageWithID = userMessageWithID.replace(imoRegex, `IMO: ${detectedIMO}`);
            } else if (mmsiMatch && mmsiMatch[1]) {
                const detectedMMSI = mmsiMatch[1];
                sessionStorage.setItem('mmsi', detectedMMSI);
                setMMSI(detectedMMSI);
                userMessageWithID = userMessageWithID.replace(mmsiRegex, `MMSI: ${detectedMMSI}`);
            }

            // Append stored IMO/MMSI to the user's message if not explicitly provided
            if (!imoMatch && !mmsiMatch) {
                if (imo) {
                    userMessageWithID += ` IMO: ${imo}`;
                } else if (mmsi) {
                    userMessageWithID += ` MMSI: ${mmsi}`;
                }
            }
        }

        const assistantResponse = await fetch(`${apiUrl}/map-query`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${session.access_token}`
            },
            body: JSON.stringify({
                userMessage: userMessageWithID,
                userPersona: userPersona,
                sessionId: sessionId,
                isGeneralQuery: isGeneralQuery
            })
        });

        if (!assistantResponse.ok) {
            throw new Error('Failed to get response from assistant');
        }

        const assistantResponseData = await assistantResponse.json();
        if (assistantResponseData && assistantResponseData.response) {
            let processedResponse = processResponse(assistantResponseData.response);
            addMessage({ type: 'assistant', text: processedResponse });

            if (assistantResponseData.lat && assistantResponseData.lon) {
                updateLat(assistantResponseData.lat);
                updateLon(assistantResponseData.lon);
            }
            if (assistantResponseData.name) {
                updateName(assistantResponseData.name);
            }
            if (assistantResponseData.type_specific) {
                updateTypeSpecific(assistantResponseData.type_specific);
            }
            if (assistantResponseData.depPortCoords) {   
                updateDepPortCoords(assistantResponseData.depPortCoords);
            }
            if (assistantResponseData.destPortCoords) {
                updateDestPortCoords(assistantResponseData.destPortCoords);
            }
            if (assistantResponseData.trackHistory) {
                updateTrackHistory(assistantResponseData.trackHistory);
            }
            if (assistantResponseData.course) {
                updateCourse(assistantResponseData.course);
            }
            if (assistantResponseData.heading) {
                updateHeading(assistantResponseData.heading);
            }
            if (assistantResponseData.mmsi) {
                updateMMSI(assistantResponseData.mmsi);
            }

            await logAssistantResponse(processedResponse, sessionId);
            updateProfileMetrics();
        } else {
            console.error('No valid response data received:', assistantResponseData);
        }
    } catch (error) {
        console.error('Error in handleSendClick:', error.message);
    } finally {
        setIsLoading(false);
    }
};





// Logs the AI response in the DB, now properly receiving sessionId
async function logAssistantResponse(processedResponse, sessionId) {
    const logResponse = await fetch(`${apiUrl}/api/map-explore-messages`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${session.access_token}`
        },
        body: JSON.stringify({
            user_id: session.user.id,
            session_id: sessionId,
            message: processedResponse,
            message_type: 'answer'
        })
    });

    if (!logResponse.ok) {
        throw new Error('Failed to log assistant response');
    }
}




function processResponse(rawResponse) {
    // Apply bold formatting for vessel name, destination, departure, and estimated time of arrival
    let processedResponse = rawResponse
        .replace(/(\b[A-Z\s]+(?:\s+[A-Z\s]+)*)\b(?![^<]*>|[^<>]*<\/)/g, '<strong>$1</strong>') // Vessel name and other capitalized words
        .replace(/\b(heading towards|from|with an estimated time of arrival on)\b/gi, '<strong>$1</strong>') // Key phrases
        .replace(/(?:\r\n|\r|\n)/g, '<br>') // Replace line breaks with <br>
        .split('\n\n') // Split by double newlines to create paragraphs
        .map(paragraph => `<p>${paragraph}</p>`) // Wrap each line in <p> tags
        .join(''); // Join all paragraphs into a single string

    return processedResponse;
}




//updates the number of messages in db for upgrade UI
async function updateProfileMetrics() {
    await supabase
        .from('userprofiles')
        .update({
            last_message_date: new Date(),
            message_count: messageCount + 1
        })
        .eq('user_id', session.user.id);
}






    // Render the input box and send button
    return (
        <div className={`map-chat-input-container ${className || ''}`}> 
            <textarea
                ref={textAreaRef}
                className="map-chat-input"
                placeholder={isLoading ? "" : "Type your message here..."}
                value={inputText}
                onChange={handleTextChange}
                onKeyDown={handleKeyDown}
                disabled={isTextAreaDisabled} 
            ></textarea>
            {isLoading &&
                <div className="map-loader-container">
                    <Loading type={'bubbles'} color={'#000'} height={'40px'} width={'40px'} />
                </div>
            }
            <button className="map-chat-send-button" onClick={handleSendClick} disabled={isButtonDisabled} >
                {!isLoading && <ChatSendIcon />}
            </button>
        </div>
    );
};

export default MapChatInputBox;