import React, { useEffect, useState, useContext, useRef, useCallback, useMemo } from 'react';
import { useDropzone } from 'react-dropzone';
import { ReactComponent as UploadDocumentViewerSVG } from '../../ReusableComponents/Icons/UploadDocumentViewer.svg';
import { UserContext } from '../../Auth/UserAuth/UserContext';
import { SessionContext } from '../../Auth/UserAuth/SessionContext';
import { useDocument } from '../../Auth/UserAuth/DocumentContext';
import ContextMenu from '../TextInteraction/ContextMenu';
import { ReactComponent as UploadProcessingGreenTick } from './UploadProcessingGreenTick.svg';
import { ReactComponent as UpgradeTick } from '../../ReusableComponents/Icons/UpgradeTick.svg';
import { ReactComponent as UpgradeTeaser } from './UploadProcessingGreenTick.svg';
import { trackEvent } from '../../Auth/UserAnalytics/MixPanelConfig';
import { AutoSaveSessionToast } from '../../ReusableComponents/Toast/ToastMessages';
import DocumentViewerContent from './DocumentViewerContent';
import LoadingComponent from '../../ReusableComponents/LoadingComponent';
import StandaloneLoader from '../../ReusableComponents/StandaloneLoader';
import Button from '../../ReusableComponents/Buttons/Button';
import './DocumentViewer.css';

const apiUrl = process.env.NODE_ENV === 'production'
    ? process.env.REACT_APP_PRODUCTION_API_URL
    : process.env.REACT_APP_API_URL;

const DocumentViewer = React.memo(({ clearSession }) => {
    const { session, trialEndDate, planName, showUpgradeModal, setShowUpgradeModal, lastUploadDate, setLastUploadDate } = useContext(UserContext);

    const {
        sessionId, setSessionId, filePath, setFilePath,
        isRestoringSession, setIsRestoringSession, documentUploaded, setDocumentUploaded,
        questionSuggestions, setQuestionSuggestions, justCleared, setJustCleared,
        setSelectedMenuText, setSelectedMenuOption, setIsContextMenuSubmission,
        clearDocIntelSession
    } = useContext(SessionContext);

    const { setBolDetails, setDocumentId, documentUrl, setDocumentUrl, documentState, setDocumentState } = useDocument();

    const [loading, setLoading] = useState(false);
    const [backendResponse, setBackendResponse] = useState(null);
    const [uploadStatus, setUploadStatus] = useState('idle');
  

    // Memoized function to extract file type from file path
    const extractFileTypeFromPath = useCallback((filePath) => {
        if (!filePath) return null;
        const segments = filePath.split('.');
        return segments.length > 1 ? segments.pop() : null;
    }, []);


      // Function to fetch the signed URL from the backend only for session restorations
    const fetchDocumentUrlFromBackend = useCallback(async (sessionId) => {
        try {
            const response = await fetch(`${apiUrl}/generate-document-url`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${session.accessToken}` // Assuming accessToken is available in session
                },
                body: JSON.stringify({ sessionId })
            });
            if (!response.ok) throw new Error('Failed to fetch document URL from backend.');

            const data = await response.json();
            return data.url;
        } catch (error) {
            console.error('Error fetching document URL from backend:', error);
            return null;
        }
    }, [session]);

    // Effect to create or restore document state
    useEffect(() => {
        const createOrRestoreDocumentState = async () => {
            try {
                const storedFilePath = sessionStorage.getItem('filePath');
                if (!storedFilePath) throw new Error('No file path available in session storage.');

                const fileType = extractFileTypeFromPath(storedFilePath);

                let url = sessionStorage.getItem('documentUrl');
                if (!url) {
                    url = await fetchDocumentUrlFromBackend(sessionId);
                    if (url) {
                        setDocumentUrl(url);
                        sessionStorage.setItem('documentUrl', url);
                    } else {
                        throw new Error('Failed to retrieve document URL.');
                    }
                }

                if (fileType === 'csv') {
                    const response = await fetch(url);
                    const blob = await response.blob();
                    const file = new File([blob], 'filename.csv', { type: 'text/csv' });
                    setDocumentState({ file, url, fileType });
                } else {
                    setDocumentState({ file: null, url, fileType });
                }

                setIsRestoringSession(false);
                setDocumentUploaded(true);
            } catch (error) {
                console.error('createOrRestoreDocumentState Error:', error);
            }
        };

        if (isRestoringSession) {
            createOrRestoreDocumentState();
        }
    }, [isRestoringSession, extractFileTypeFromPath, documentUrl, setDocumentUrl, setDocumentState, setDocumentUploaded, fetchDocumentUrlFromBackend, sessionId]);



    const handleBackendResponse = useCallback(async (data) => {
    try {
        console.log(`[${new Date().toISOString()}] Setting session storage...`);
        sessionStorage.setItem('sessionId', data.session.id);
        sessionStorage.setItem('filePath', data.filePath);
        sessionStorage.setItem('documentId', data.documentId);

        setSessionId(data.session.id);
        setFilePath(data.filePath);
        setDocumentId(data.documentId);
        setQuestionSuggestions(data.questionSuggestions);

        if (data.documentType === 'BOL') {
            sessionStorage.setItem('bolDetails', JSON.stringify(data.bolDetails));
            setBolDetails(data.bolDetails);
        }

        const signedUrl = data.signedUrl; // Use the signed URL directly from the backend
        if (signedUrl) {
            setDocumentUrl(signedUrl);
            sessionStorage.setItem('documentUrl', signedUrl);
            setDocumentState(prevState => ({
                ...prevState,
                file: null,
                url: signedUrl,
                fileType: data.documentType
            }));
        }

        setLastUploadDate(new Date());
        setDocumentUploaded(true);
        setUploadStatus('success');

        AutoSaveSessionToast();

        // Trigger a full page reload after all state and session updates
        console.log("Document is ready, triggering full page reload.");
        window.location.reload(); // This will refresh the entire page

    } catch (error) {
        console.error(`[${new Date().toISOString()}] handleBackendResponse Error:`, error);
        setUploadStatus('error');
    } finally {
        setLoading(false);
    }
}, [setSessionId, setFilePath, setDocumentId, setQuestionSuggestions, setBolDetails, setDocumentUrl, setDocumentState, setLastUploadDate, setDocumentUploaded, setLoading]);


    const handleFileUpload = useCallback(async (file) => {
        try {
            console.log(`[${new Date().toISOString()}] Starting file upload...`);
            setUploadStatus('uploading');
            setLoading(true);

            const formData = new FormData();
            formData.append('file', file);

            if (!session || !session.access_token) throw new Error('Invalid session or access token.');

            const env = process.env.NODE_ENV;
            const uploadURL = env === 'development' ? 'http://localhost:5001/upload' : 'https://api.comovis.ai/upload';

            //console.log(`[${new Date().toISOString()}] Sending request to: ${uploadURL}`);
            const response = await fetch(uploadURL, {
                method: 'POST',
                headers: {
                    'Authorization': `Bearer ${session.access_token}`,
                },
                body: formData
            });

            if (!response.ok) throw new Error('Network response was not ok.');

            //console.log(`[${new Date().toISOString()}] Fetch succeeded, processing response...`);

            const data = await response.json();
            await handleBackendResponse(data);
            //console.log(`[${new Date().toISOString()}] Backend response handled successfully.`);
            setUploadStatus('success');
        } catch (error) {
            console.error(`[${new Date().toISOString()}] handleFileUpload Error:`, error);
            setUploadStatus('error');
        } finally {
            setLoading(false);
        }
    }, [session, handleBackendResponse]);






    const { getRootProps, getInputProps } = useDropzone({
        onDrop: acceptedFiles => handleFileUpload(acceptedFiles[0]),
        accept: {
            'application/pdf': [],
            'text/csv': [],
            'application/vnd.openxmlformats-officedocument.wordprocessingml.document': [],
            'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': [],
            'text/plain': [],
            'image/jpeg': [],
            'image/png': []
        }
    });

    const handleUpgradeClick = () => {
        clearDocIntelSession();
        window.location.href = "/dashboard/upgrade-plan";
    };

    const handleCancelClick = () => {
        setShowUpgradeModal(false);
    };

    const contextMenuRef = useRef(null);
    const containerRef = useRef(null);
    const [isMenuVisible, setMenuVisibility] = useState(false);
    const [selectedTextInfo, setSelectedTextInfo] = useState({
        text: '',
        position: { top: 0, left: 0 }
    });

    const handleTextSelected = useCallback((selectedText, rect) => {
        setSelectedTextInfo({
            text: selectedText,
            position: { top: rect.top + window.scrollY, left: rect.left + window.scrollX }
        });
        setMenuVisibility(true);
    }, []);

    const handleMenuOptionSelect = useCallback((option, selectedText) => {
        setMenuVisibility(false);
        setSelectedMenuText(selectedText);
        setSelectedMenuOption(option);
        setIsContextMenuSubmission(true);
        trackEvent("Context Menu Click - Doc Viewer", {
            action: option,
            selectedText: selectedText
        });
    }, [setSelectedMenuText, setSelectedMenuOption, setIsContextMenuSubmission]);

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (contextMenuRef.current && !contextMenuRef.current.contains(event.target)) {
                setMenuVisibility(false);
            }
        };
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    const documentViewerContent = useMemo(() => (
        <DocumentViewerContent
            documentState={documentState}
            handleTextSelected={handleTextSelected}
            containerRef={containerRef}
        />
    ), [documentState, handleTextSelected]);

    return (
        <div className="document-viewer" ref={containerRef}>
            {!documentState?.file && !documentUploaded && (
                <div {...getRootProps({ className: 'dropzone' })}>
                    <input {...getInputProps()} />
                    <UploadDocumentViewerSVG className="document-upload-svg" />
                    <p className="upload-instructions">Drag and drop a file here, or click to select your file.</p>
                    <p className="upload-instructions-2">Uploaded documents are automatically saved and can be restored via Sessions. </p>
                </div>
            )}

            <input
                type="file"
                id="file-upload"
                style={{ display: 'none' }}
                accept=".pdf,.csv,.docx,.txt,.jpg,.jpeg,.png"
                onChange={(e) => handleFileUpload(e.target.files[0])}
            />

            {documentViewerContent}

            {isMenuVisible && (
                <ContextMenu
                    ref={contextMenuRef}
                    text={selectedTextInfo.text}
                    position={selectedTextInfo.position}
                    options={["Tell me more", "Give me insights", "Give me references"]}
                    onSelect={(option) => handleMenuOptionSelect(option, selectedTextInfo.text)}
                />
            )}

     

    {loading && (
    <div className="document-viewer-overlay">
        <div className="document-viewer-dialog-container">
            <UploadProcessingGreenTick className="document-viewer-green-tick" />
            <h2>We're processing your document...</h2>
            <div className="document-viewer-loading">
                <StandaloneLoader type="spin" size={20} color={'#000'} />
            </div>
            <p className="document-viewer-processing-time">Avg. processing time: 6-40 seconds</p>
            <p className="document-viewer-refresh-warning"><strong>Please do not refresh during processing.</strong></p>
        </div>
    </div>
)}



            {showUpgradeModal && (
                <div className="document-viewer-upgrade-overlay">
                    <div className="upgrade-plan-dialog-container">
                        <div className="upgrade-dialog-title-container">
                            <UpgradeTick className="upgrade-tick-icon" />
                            <h2 className="upgrade-dialog-title">Upgrade your plan</h2>
                        </div>
                        <p className="upgrade-dialog-paragraph">
                            You've used all your free allowance this week. Upgrade now to unlock unlimited features and usage. <strong>No limits.</strong>
                        </p>
                        <UpgradeTeaser />
                        <div className="unique-upgrade-buttons-container">
                            <Button 
                                text="Upgrade" 
                                color="green" 
                                className="unique-upgrade-button" 
                                onClick={handleUpgradeClick} 
                            />
                            <Button 
                                text="Cancel" 
                                color="black" 
                                className="unique-cancel-button" 
                                onClick={handleCancelClick} 
                            />
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
});

export default DocumentViewer;