import React from 'react';
import ReactLoading from 'react-loading';
import './Button.css';

const Button = ({ text, color, className, type, isLoading, loaderColor = '#000', loadingText, size = 'medium', ...props }) => {
    const colorClass = color ? `button-${color}` : '';
    const sizeClass = size ? `button-${size}` : 'button-medium'; // Default to medium if no size provided

    return (
        <button
            type={type}
            className={`button ${colorClass} ${sizeClass} ${className}`}
            disabled={isLoading}
            {...props}
        >
            {isLoading ? (
                loadingText ? (
                    <span className="loading-text">{loadingText}</span>
                ) : (
                    <ReactLoading type="spin" height={18} width={18} color={loaderColor} />
                )
            ) : (
                <span className="text">{text}</span>
            )}
        </button>
    );
}

export default Button;
