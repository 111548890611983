import React, { useEffect, useState, useContext } from 'react';
import axios from 'axios';
import { useNavigate, useSearchParams } from 'react-router-dom';
import './UpgradeSuccessPage.css';
import { UserContext } from '../../../Auth/UserAuth/UserContext';
import logo from '../../../ReusableComponents/Logo.svg'; // Import the logo
import Button from '../../../ReusableComponents/Buttons/Button'; // Import the reusable Button component
import LoadingComponent from '../../../ReusableComponents/LoadingComponent'; // Import the reusable LoadingComponent

const UpgradeSuccessPage = () => {
  // Context and navigation setup
  const { session, setPlanName } = useContext(UserContext);
  const navigate = useNavigate();

  // Initialize state variables
  const [loading, setLoading] = useState(true);
  const [paymentSuccess, setPaymentSuccess] = useState(null); // Set to null initially
  const [invalidAccess, setInvalidAccess] = useState(false); // Track if the user accessed the page manually

  // To retrieve session ID from URL
  const [searchParams] = useSearchParams();
  const urlSessionId = searchParams.get("session_id");

  useEffect(() => {
    const verifyPaymentURL = `${process.env.REACT_APP_API_URL}/verify-payment`;

    const verifyPayment = async () => {
      try {
        if (!urlSessionId) {
          setInvalidAccess(true); // No session ID found, user accessed manually
        } else {
          const response = await axios.post(verifyPaymentURL, {
            sessionId: urlSessionId,
          }, {
            headers: {
              'Authorization': `Bearer ${session.access_token || session.user.access_token}`,
            }
          });

          if (response.data.status === 'success') {
            console.log("Payment verification successful");
            setPlanName('Premium');
            setPaymentSuccess(true); // Set paymentSuccess to true on successful payment verification
          } else {
            console.log("Payment verification failed");
            setPaymentSuccess(false); // Set to false if payment verification fails
          }
        }
      } catch (err) {
        console.log("An error occurred during payment verification:", err);
        setPaymentSuccess(false); // Set to false on error
      } finally {
        setLoading(false); // Stop loading after all checks are complete
      }
    };

    verifyPayment();
  }, [urlSessionId, navigate, session, setPlanName]);

  // Function to navigate to the dashboard
  const navigateToDashboard = () => {
    window.location.href = '/dashboard'; // Reload and navigate to the dashboard
  };

  // Function to navigate back to the upgrade plan page
  const navigateToUpgradePlan = () => {
    window.location.href = '/dashboard/upgrade-plan'; // Redirect and refresh the page
  };

  // Conditional rendering based on different scenarios
  if (loading) {
    return <LoadingComponent loading={loading} size={20} />; // Show loading state until all checks are complete
  }

  if (invalidAccess) {
    // Show the "Oops" message and Go Back button if the user accessed the page manually
    return (
      <div className="upgrade-success-page"> {/* Apply the specific page style here */}
        <div className="comovis-upgrade-page-wrapper">
          <h2 className="comovis-error-title">Oops. Looks like you are lost.</h2>
          <div className="comovis-button-container">
            <Button 
              text="Go Back"
              color="button"
              className="comovis-start-button"
              onClick={navigateToDashboard}
            />
          </div>
        </div>
      </div>
    );
  }



  return (
    <div className="upgrade-success-page"> {/* Apply the specific page style here */}
      <div className="comovis-upgrade-page-wrapper">
        <img src={logo} alt="Comovis Logo" className="upgrade-comovis-logo" /> {/* Logo above the container */}
        <div className="comovis-upgrade-success-container">
          <h2 className="comovis-success-title">
            <span role="img" aria-label="party">🎉</span> Welcome to Comovis Business Premium
          </h2>
          <p className="comovis-success-text">Your subscription was successful, and you now have access to all Premium features.</p>
          <p className="comovis-success-text">Your chosen payment method will be billed on or around the same time each month until you cancel.</p>
          <div className="comovis-button-container">
            {/* Use the reusable Button component here */}
            <Button 
              text="Get Started"
              color="button"
              className="comovis-start-button"
              onClick={navigateToDashboard}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default UpgradeSuccessPage;
