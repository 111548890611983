/* global Canny */
import './FeedbackPage.css';
import React, { useEffect, useState } from 'react';
import { MoonLoader } from 'react-spinners';

const BoardToken = 'ad271d44-3e0c-f03b-11b2-676671d86b23';

const FeedbackPage = () => {
  const [isCannyLoaded, setIsCannyLoaded] = useState(false);

  useEffect(() => {
    // Initialize Canny
    void (function(w, d, i, s) {
      function l() {
        if (!d.getElementById(i)) {
          var f = d.getElementsByTagName(s)[0],
              e = d.createElement(s);
          e.type = "text/javascript";
          e.async = true;
          e.src = "https://canny.io/sdk.js";
          f.parentNode.insertBefore(e, f);
        }
      }
      if (typeof w.Canny !== 'function') {
        var c = function() {
          c.q.push(arguments);
        };
        c.q = [];
        w.Canny = c;
        if (d.readyState === 'complete') {
          l();
        } else {
          w.addEventListener ? w.addEventListener('load', l, false) : w.attachEvent('onload', l);
        }
      }
    })(window, document, 'canny-jssdk', 'script');

    // Render the Canny board
    Canny('render', {
      boardToken: BoardToken,
      basePath: '/dashboard/feedback',
      ssoToken: null,
      theme: 'light',
    });

    // Polling to check if Canny has been loaded into the DOM
    const checkCannyLoaded = setInterval(() => {
      const cannyElement = document.querySelector('[data-canny]');
      if (cannyElement && cannyElement.childNodes.length > 0) {
        setIsCannyLoaded(true);
        clearInterval(checkCannyLoaded);
      }
    }, 500);

    return () => {
      // Cleanup interval on component unmount
      clearInterval(checkCannyLoaded);
    };
  }, []);

  return (
    <div className="feedback-main-container">
      <div className="feedback-header-container">
        <h1>Feedback</h1>
      </div>
      <div className="feedback-content-container">
        {!isCannyLoaded && (
          <div className="feedback-loading-icon">
            <MoonLoader color="#000000" loading={!isCannyLoaded} size={20} />
          </div>
        )}
        <div data-canny />
      </div>
    </div>
  );
};

export default FeedbackPage;
