import React, { useContext, useEffect, useState } from 'react';
import { UserContext } from '../../Auth/UserAuth/UserContext';
import { SessionContext } from '../../Auth/UserAuth/SessionContext';
import { MapSessionContext } from '../../Auth/UserAuth/MapSessionContext'; 
import { supabase } from '../../Auth/SupabaseAuth/SupabaseClient';
import { useNavigate, useLocation } from 'react-router-dom';
import { ReactComponent as ClearSessionIcon } from './ClearSession.svg';
import { ReactComponent as ClearSessionDashboardIcon } from '../../ReusableComponents/Icons/ClearSessionDashboard.svg';
import { ReactComponent as PDFIconHeaderMenu } from './PDFIconHeaderMenu.svg';
import { ReactComponent as CSVIconHeaderMenu } from './CSVIconHeaderMenu.svg';
import { ReactComponent as UploadXLSXIcon } from '../../ReusableComponents/Icons/UploadXLSXIcon.svg';
import { ReactComponent as UploadJPEGIcon } from '../../ReusableComponents/Icons/UploadJPEGIcon.svg';
import { ReactComponent as UploadPNGIcon } from '../../ReusableComponents/Icons/UploadPNGIcon.svg';

import { SessionRestoreToast } from '../../ReusableComponents/Toast/ToastMessages';
import LoadSession from '../../MainApp/UserAppSessions/LoadSessions';
import { ReactComponent as UpgradeButton } from './UpgradeButton.svg';
import { ReactComponent as BusinessPremium } from './BusinessPremium.svg';
import GlobeExploreIcon from '../../ReusableComponents/Icons/GlobeExploreIcon.svg';
import './HeaderPanel.css';
import { ResetContext } from '../../Auth/UserAuth/ResetContext'; 
import { useDemo } from '../../Auth/UserAuth/DemoContext'; 
import { useDocument } from '../../Auth/UserAuth/DocumentContext';
import PersonaIcon from './PersonaIcon';

const HeaderPanel = ({ handleMenuItemClick, setSessionIdFromHeader }) => {
    const navigate = useNavigate();
    const location = useLocation();
    const { userPersona, session, trialEndDate, planId, planName } = useContext(UserContext);
    const { reset } = useContext(ResetContext); // Use reset from ResetContext
    const { isDemo } = useDemo(); // Use isDemo from DemoContext

    const { sessionId, setSessionId,  documentTitle, setDocumentTitle, clearDocIntelSession } = useContext(SessionContext);
    const { documentState } = useDocument(); // Updated to use DocumentContext
    const { clearMapSession, mapSessionId } = useContext(MapSessionContext);

    const isSessionsRoute = location.pathname === "/dashboard/sessions";
    const isDashboard = location.pathname === "/dashboard";

    const handleClearSessionClick = () => {
        if (isDemo) return; // Disable functionality if in demo mode

        if (location.pathname.includes('/explore')) {
            clearMapSession();
        } else {
            clearDocIntelSession();
        }
    };

    const fileType = documentState?.fileType;

    const currentSessionId = location.pathname.includes('/explore') ? mapSessionId : sessionId;

    const fetchSessionTitle = async () => {
        if (!currentSessionId) {
            setDocumentTitle("No current session in progress.");
            return;
        }

        const checkSessionTitle = async () => {
            const { data: sessionData, error: sessionError } = await supabase
                .from('sessions')
                .select('session_title, document_id, session_type')
                .eq('id', currentSessionId)
                .single();

            if (sessionError || !sessionData) {
                setDocumentTitle("Error fetching title");
                return;
            }

            if (sessionData.session_type === 'doc-intel' && sessionData.document_id) {
                const { data: documentData, error: documentError } = await supabase
                    .from('documents')
                    .select('file_name')
                    .eq('id', sessionData.document_id)
                    .single();

                if (documentData && !documentError) {
                    setDocumentTitle(documentData.file_name.length <= 30 ? documentData.file_name : documentData.file_name.slice(0, 45) + '...');
                } else {
                    setDocumentTitle("New Session...");
                }
            } else if (sessionData.session_title) {
                setDocumentTitle(sessionData.session_title.length <= 30 ? sessionData.session_title : sessionData.session_title.slice(0, 45) + '...');
            } else {
                setDocumentTitle("New Session...");
            }
        };

        checkSessionTitle();
    };

    useEffect(() => {
        fetchSessionTitle();
    }, [currentSessionId]);

    function formatDate(dateStr) {
        if (!dateStr) return 'Unknown date';
        const inputDate = new Date(dateStr);
        const utcInputDate = Date.UTC(inputDate.getUTCFullYear(), inputDate.getUTCMonth(), inputDate.getUTCDate());
        const now = new Date();
        const utcNow = Date.UTC(now.getUTCFullYear(), now.getUTCMonth(), now.getUTCDate());
        const diffTime = Math.abs(utcNow - utcInputDate);
        const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
        if (diffDays === 0) {
            return 'Today';
        } else if (diffDays === 1) {
            return 'Yesterday';
        } else if (diffDays <= 7) {
            return `${diffDays} days ago`;
        } else {
            return inputDate.toLocaleDateString("en-US", { day: 'numeric', month: 'long', year: 'numeric' });
        }
    }

    const handleUpgradeClick = () => {
        navigate("/dashboard/upgrade-plan");
    };

    function trimName(name, maxLength) {
        if (name.length > maxLength) {
            return name.slice(0, maxLength) + '...';
        } else {
            return name;
        }
    }

    const calculateTrialDaysRemaining = (trialEndDate) => {
        const trialEnd = new Date(trialEndDate);
        const now = new Date();
        const diffInMilliseconds = trialEnd - now;
        const diffInDays = Math.ceil(diffInMilliseconds / (1000 * 60 * 60 * 24));
        return diffInDays;
    };

    return (
        <div className={`headerpanel-container ${reset ? 'resetting' : ''}`}>
            <div className="clear-session-icon-container">
                {isDemo ? (
                    <ClearSessionDashboardIcon
                        className="clear-session-icon"
                        style={{ cursor: 'not-allowed' }}
                    />
                ) : isDashboard ? (
                    <ClearSessionDashboardIcon
                        className="clear-session-icon"
                        style={{ cursor: 'not-allowed' }}
                    />
                ) : (
                    <ClearSessionIcon
                        className="clear-session-icon"
                        onClick={handleClearSessionClick}
                        style={{ cursor: 'pointer' }}
                    />
                )}
            </div>

            <div className="document-title-container">
                <div className="document-title-content">
                    {fileType === 'pdf' ? (
                        <PDFIconHeaderMenu className="pdf-icon" />
                    ) : fileType === 'csv' ? (
                        <CSVIconHeaderMenu className="csv-icon" />
                    ) : fileType === 'xlsx' ? (
                        <UploadXLSXIcon className="xlsx-icon" />
                    ) : fileType === 'png' ? (
                        <UploadPNGIcon className="png-icon" />
                    ) : fileType === 'jpg' || fileType === 'jpeg' ? (
                        <UploadJPEGIcon className="jpeg-icon" />
                    ) : (
                        <img src={GlobeExploreIcon} alt="Map" className="header-map-icon" />
                    )}
                    <span className="document-title">{documentTitle}</span>
                </div>
            </div>

            {planName === 'Premium' ? (
                <div className="premium-status-container">
                    <BusinessPremium className="business-premium-icon" />
                </div>
            ) : (
                <div className="trial-status-container">
                    {calculateTrialDaysRemaining(trialEndDate) <= 0 ? (
                        <span className="trial-ended">Your free trial has ended</span>
                    ) : (
                        <span className="trial-countdown"> {`${calculateTrialDaysRemaining(trialEndDate)} day${calculateTrialDaysRemaining(trialEndDate) > 1 ? 's' : ''} left of free trial`}</span>
                    )}
                    {planName === 'Trial' && <UpgradeButton className="upgrade-button" onClick={handleUpgradeClick} />}
                </div>
            )}

            <div className="persona-icon-container">
                {userPersona && <PersonaIcon persona={userPersona} />}
            </div>

            {sessionId && <LoadSession sessionId={sessionId} session={session} />}
        </div>
    );
};

export default HeaderPanel;
