import React, { useContext, useEffect } from 'react';
import { MapContainer as LeafletMap, TileLayer, Marker, Popup, useMapEvents } from 'react-leaflet';
import { MapSessionContext } from '../../../Auth/UserAuth/MapSessionContext';
import L from 'leaflet';
import 'leaflet/dist/leaflet.css';
import './MapContainer.css';
import RotatingMarker from './RotatingMarkerIcons';

// Define custom icons for the ports
const departurePortIcon = new L.Icon({
    iconUrl: 'https://cdn.rawgit.com/pointhi/leaflet-color-markers/master/img/marker-icon-grey.png',
    iconSize: [15, 23], // Default size for the marker icon
    iconAnchor: [12, 41],
    popupAnchor: [1, -34]
});

const destinationPortIcon = new L.Icon({
    iconUrl: 'https://unpkg.com/leaflet@1.7.1/dist/images/marker-icon.png',
    iconSize: [15, 23], // Default size for the marker icon
    iconAnchor: [12, 41],
    popupAnchor: [1, -34]
});

const MapUpdater = ({ lat, lon, zoomLevel }) => {
    const map = useMapEvents({
        zoomend: () => {}
    });

    useEffect(() => {
        if (lat && lon) {
            // Update map center without changing the zoom
            map.panTo([lat, lon], { animate: true, duration: 0.5 });
        }
        // Smooth and slow zoom transition
        if (zoomLevel) {
            map.setZoom(zoomLevel, {
                animate: true,
                duration: 3 // Longer duration for a smoother transition
            });
        }
    }, [lat, lon, zoomLevel, map]);

    return null;
};

const MapContainer = () => {
    const { lat, lon, name, typeSpecific, depPortCoords, destPortCoords, course, heading, mapSessionId } = useContext(MapSessionContext);

    const bounds = [
        [-90, -180], // Southwest coordinates
        [90, 180]    // Northeast coordinates
    ];

    useEffect(() => {}, [lat, lon, name, typeSpecific, depPortCoords, destPortCoords, course, heading]);

    return (
        <div className="map-container">
            <LeafletMap
                key={mapSessionId} // Key change forces re-render of the map
                center={[51.505, -0.09]}
                zoom={3}
                minZoom={2}
                maxBounds={bounds}
                style={{ height: "100%", width: "100%" }}
            >
                <TileLayer
                    url="https://{s}.basemaps.cartocdn.com/rastertiles/voyager/{z}/{x}/{y}{r}.png"
                    attribution='&copy; <a href="https://carto.com/attributions">CARTO</a>'
                />
                {lat && lon && <MapUpdater lat={lat} lon={lon} />}
                {lat && lon ? (
                    <RotatingMarker
                        position={[lat, lon]}
                        angle={course || heading || 0}
                        popupContent={
                            `<strong>${name}</strong><br />${typeSpecific}<br />Vessel Position: ${lat}, ${lon}`
                        }
                    />
                ) : null}
                {depPortCoords && (
                    <Marker position={[depPortCoords.lat, depPortCoords.lon]} icon={departurePortIcon}>
                        <Popup>
                            Departure Port: {depPortCoords.port_name}
                        </Popup>
                    </Marker>
                )}
                {destPortCoords && (
                    <Marker position={[destPortCoords.lat, destPortCoords.lon]} icon={destinationPortIcon}>
                        <Popup>
                            Destination Port: {destPortCoords.port_name}
                        </Popup>
                    </Marker>
                )}
            </LeafletMap>
        </div>
    );
};

export default MapContainer;
