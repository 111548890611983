import React, { useContext, useState } from 'react';
import { MapSessionContext } from '../../Auth/UserAuth/MapSessionContext';
import MapContainer from './MapExploreComponents/MapContainer';
import MapChatPanel from './MapExploreChatComponents/MapChatPanel';
import SidePanel from '../../Components/SidePanel/SidePanel';
import HeaderPanel from '../../Components/HeaderMenu/HeaderPanel';
import './MainMapExplorePage.css';

const MainMapExplorePage = () => {
    const [isChatVisible, setIsChatVisible] = useState(true);
    const { startMapSession, clearMapSession } = useContext(MapSessionContext);

    const toggleChatPanel = () => {
        setIsChatVisible(!isChatVisible);
    };

    return (
        <div className="map-explore-page-full">
            <SidePanel />
            <div className="map-explore-main">
                <HeaderPanel />
                <div className="map-explore-content">
                    <div className="map-container" style={{ flex: isChatVisible ? '70%' : '100%' }}>
                        <MapContainer />
                    </div>
                    {isChatVisible && (
                        <div className="map-chat-panel" style={{ flex: '30%' }}>
                            <MapChatPanel />
                        </div>
                    )}
                </div>
            
            </div>
        </div>
    );
};

export default MainMapExplorePage;
