import React, { useEffect, useState } from 'react';
import mammoth from 'mammoth';

const DOCXViewer = ({ documentState }) => {
    const [docContent, setDocContent] = useState('');

    useEffect(() => {
        const loadDocxFile = async () => {
            try {
                const response = await fetch(documentState.url);
                const arrayBuffer = await response.arrayBuffer();
                const { value } = await mammoth.convertToHtml({ arrayBuffer });
                setDocContent(value);
            } catch (error) {
                console.error('Error loading DOCX file:', error);
            }
        };

        loadDocxFile();
    }, [documentState.url]);

    return <div dangerouslySetInnerHTML={{ __html: docContent }} />;
};

export default DOCXViewer;
