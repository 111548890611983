import React, { createContext, useState, useContext, useEffect, useCallback, useRef } from 'react';
import { supabase } from '../../Auth/SupabaseAuth/SupabaseClient';

const DocumentContext = createContext();

export const DocumentProvider = ({ children }) => {
    const [showProcessedDocPanel, setShowProcessedDocPanel] = useState(false);
    const [bolDetailsInternal, setBolDetailsInternal] = useState(() => {
        const storedBolDetails = sessionStorage.getItem('bolDetails');
        return storedBolDetails ? JSON.parse(storedBolDetails) : null;
    });
    const [questionSuggestions, setQuestionSuggestions] = useState([]);
    const [documentText, setDocumentText] = useState("");
    const [currentPage, setCurrentPage] = useState(null);
    const [documentId, setDocumentId] = useState(() => sessionStorage.getItem('documentId'));
    const [documentUrl, setDocumentUrl] = useState(() => sessionStorage.getItem('documentUrl'));
    const [documentState, setDocumentStateInternal] = useState({ file: null, url: null, fileType: null });
    const documentStateRef = useRef(documentState);

    // Function to update BOL details and sync with session storage
    const setBolDetails = useCallback((newBolDetails) => {
        setBolDetailsInternal(newBolDetails);
        sessionStorage.setItem('bolDetails', JSON.stringify(newBolDetails));
    }, []);

    // Load BOL details from session storage on initial load
    useEffect(() => {
        const storedBolDetails = sessionStorage.getItem('bolDetails');
        if (storedBolDetails) {
            setBolDetailsInternal(JSON.parse(storedBolDetails));
        }
    }, []);

    // Sync documentId with session storage
    useEffect(() => {
        if (documentId) {
            sessionStorage.setItem('documentId', documentId);
        }
    }, [documentId]);

    // Sync documentUrl with session storage
    useEffect(() => {
        if (documentUrl) {
            sessionStorage.setItem('documentUrl', documentUrl);
        }
    }, [documentUrl]);

    // Function to update document state
    const setDocumentState = useCallback((newState) => {
        if (
            newState.url &&
            (newState.url !== documentStateRef.current.url ||
            newState.file !== documentStateRef.current.file ||
            newState.fileType !== documentStateRef.current.fileType)
        ) {
            setDocumentStateInternal(newState);
            documentStateRef.current = newState;
        }
    }, []);

    // Function to generate a signed URL for downloading files
    const generateSignedUrl = async (filePath) => {
        const [bucket, ...rest] = filePath.split('/');
        const relativePath = rest.join('/');
        const { data, error } = await supabase.storage.from(bucket).createSignedUrl(relativePath, 3600);

        if (error) {
            console.error('Failed to create a signed URL:', error);
            return null;
        }

        return data?.signedUrl || null;
    };

    const contextValue = {
        showProcessedDocPanel,
        setShowProcessedDocPanel,
        bolDetails: bolDetailsInternal,
        setBolDetails,
        questionSuggestions,
        setQuestionSuggestions,
        documentText,
        setDocumentText,
        currentPage,
        setCurrentPage,
        documentId,
        setDocumentId,
        documentUrl,
        setDocumentUrl,
        documentState,            // Provide document state
        setDocumentState,         // Provide method to update document state
        generateSignedUrl,        // Provide the generateSignedUrl function
    };

    return (
        <DocumentContext.Provider value={contextValue}>
            {children}
        </DocumentContext.Provider>
    );
};

export const useDocument = () => useContext(DocumentContext);
