

//ChatMessageList.js to render the message items --manage the order of the messages and handle rendering of different message types.

// ChatMessageList.js
import React, {  useEffect, useRef } from 'react';
import MapAIChatMessageItem from './MapAIChatMessageItem';
import MapUserChatMessageItem from './MapUserChatMessageItem';
import './MapChatMessageList.css';




const ChatMessageList = ({ messages }) => {
    const chatContainerRef = useRef(null);

    useEffect(() => {
        if (chatContainerRef.current) {
            const container = chatContainerRef.current;
            container.scrollTop = container.scrollHeight;
        }
    }, [messages]);



    return (
        <div ref={chatContainerRef} className="map-chat-message-list-container">
            {messages.map((message, index) => (
                message.type === 'user'
                    ? <MapUserChatMessageItem key={index} text={message.text} />
                    : <MapAIChatMessageItem key={index} text={message.text} />
            ))}
        </div>
    );

};

export default ChatMessageList;
