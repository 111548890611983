// Dashboard.js

import React, { useContext, useState, useEffect } from 'react';
import { Routes, Route } from 'react-router-dom';
import SidePanel from '../Components/SidePanel/SidePanel';
import HeaderPanel from '../Components/HeaderMenu/HeaderPanel';
import AccountPage from './UserAccount/UserAccount';
import UpgradePlanPage from './UpgradePlan/UpgradePlanPage';
import FeedbackPage from './UserFeedback/FeedbackPage';
import UpgradeCancelPage from './UpgradePlan/PaymentsLogic/UpgradeCancelPage';
import DashboardHome from './DashbaordHome/DashboardHome';
import { SessionContext } from '../Auth/UserAuth/SessionContext';
import { UserContext } from '../Auth/UserAuth/UserContext';
import { supabase } from '../Auth/SupabaseAuth/SupabaseClient';
import LoadingComponent from '../ReusableComponents/LoadingComponent';
import { ResetContext } from '../Auth/UserAuth/ResetContext';
import './Dashboard.css';

function Dashboard() {
    const { handleClearSessionClick, handleMenuItemClick, activeMenuItem } = useContext(SessionContext);
    const { setOnboardingComplete, avatarURL } = useContext(UserContext);
    const { reset, triggerReset } = useContext(ResetContext);
    const [isLoading, setIsLoading] = useState(true);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [avatarKey, setAvatarKey] = useState(Date.now());

    useEffect(() => {
        const checkOnboardingStatus = async () => {
            setIsLoading(true);

            try {
                const userId = localStorage.getItem('sessionId');
                if (!userId) {
                    throw new Error('User ID not found in local storage.');
                }

                const localOnboardingComplete = localStorage.getItem('onboardingComplete');
                if (localOnboardingComplete !== null) {
                    const onboardingComplete = localOnboardingComplete === 'true';
                    setOnboardingComplete(onboardingComplete);
                    setIsLoading(false);
                    if (!onboardingComplete) {
                        setIsModalOpen(true);
                    }
                    return;
                }

                const { data: profiles, error } = await supabase
                    .from('userprofiles')
                    .select('onboarding_complete')
                    .eq('user_id', userId);

                if (error) {
                    throw error;
                }

                if (profiles.length > 0) {
                    const profile = profiles[0];
                    const onboardingComplete = profile.onboarding_complete ?? false;
                    setOnboardingComplete(onboardingComplete);
                    localStorage.setItem('onboardingComplete', onboardingComplete.toString());
                    if (!onboardingComplete) {
                        setIsModalOpen(true);
                    }
                } else {
                    throw new Error('Profile not found.');
                }
            } catch (error) {
                console.error('Error fetching onboarding status:', error);
                setOnboardingComplete(false);
                localStorage.setItem('onboardingComplete', 'false');
                setIsModalOpen(true);
            } finally {
                setIsLoading(false);
            }
        };

        checkOnboardingStatus();
    }, [setOnboardingComplete]);

    useEffect(() => {
        setAvatarKey(Date.now());
    }, [avatarURL]);

    const closeModal = () => {
        setIsModalOpen(false);
        localStorage.setItem('onboardingComplete', 'true');
        setOnboardingComplete(true);
        triggerReset(); // Trigger reset to refresh all states
    };

    if (isLoading || reset) {
        return <LoadingComponent loading={isLoading || reset} />;
    }

    return (
        <div className="dashboard-container">
            <SidePanel activeMenuItem={activeMenuItem} handleMenuItemClick={handleMenuItemClick} />
            <div className="dashboard-main">
                <HeaderPanel
                    activeMenuItem={activeMenuItem}
                    handleMenuItemClick={handleMenuItemClick}
                    handleClearSessionClick={handleClearSessionClick}
                />
                <div className="dashboard-content">
                    <Routes>
                        <Route path="/" element={<DashboardHome isModalOpen={isModalOpen} closeModal={closeModal} />} />
                        <Route path="account" element={<AccountPage key={avatarKey} />} />
                        <Route path="upgrade-plan" element={<UpgradePlanPage />} />
                        <Route path="feedback/*" element={<FeedbackPage />} />
                        <Route path="upgrade-cancel" element={<UpgradeCancelPage />} />
                    </Routes>
                </div>
            </div>
        </div>
    );
}

export default Dashboard;
