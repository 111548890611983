import * as React from 'react';
import { Body, Column, Container, Head, Heading, Hr, Html, Link, Preview, Section, Text } from '@react-email/components';

export function WelcomeEmail(props) {
  const previewText = "We're thrilled to have you onboard! ";
  
  return (
    <Html>
      <Head>
        <style>
          {"@import url('https://fonts.googleapis.com/css2?family=Inter&display=swap');"}
        </style>
      </Head>
      <Preview>{previewText}</Preview>
      <Body style={{ backgroundColor: 'white', fontFamily: "'Inter', sans-serif" }}>
        <Container style={{ border: '1px solid #eaeaea', borderRadius: '8px', margin: '40px auto', padding: '20px', width: '465px' }}>
          <Section style={{ textAlign: 'center', marginTop: '32px' }}>
            <img
              src="https://skyxbncllurrcsexskvy.supabase.co/storage/v1/object/public/comovis-images/EmailLogoBlackPNG.png" 
              width="140"
              height="130"
              alt="Comovis"
              style={{ margin: 'auto' }}
            />
          </Section>
          <Heading style={{ color: 'black', fontSize: '24px', textAlign: 'center', margin: '30px auto 0 auto' }}>
            Welcome to <strong>Comovis</strong>
          </Heading>
          <Text style={{ color: 'black', fontSize: '16px', lineHeight: '24px', textAlign: 'center' }}>
            We're thrilled to have you onboard, join thousands of professionals already leveraging Comovis in their daily workflows.
          </Text>
          <Section style={{ marginTop: '20px' }}>
            <Text style={{ color: 'black', fontSize: '16px', lineHeight: '24px' }}>
              <strong>Ask Anything:</strong> Get answers directly from your documents and data in seconds. Simply upload, and start generating isnights.
            </Text>
            <Text style={{ color: 'black', fontSize: '16px', lineHeight: '24px' }}>
              <strong>Insightful Analytics:</strong> Unlock insights with the power of AI and your own fine-tuned user persona tailored to your role and workflow.
            </Text>
            <Text style={{ color: 'black', fontSize: '16px', lineHeight: '24px' }}>
              <strong>Secure & Compliant:</strong> Your data is our top priority. All data is housed within EU data centers and secured with the latest end-to-end encryption protocols. We're also GDPR compiant.
            </Text>
            <Text style={{ color: 'black', fontSize: '16px', lineHeight: '24px' }}>
              <strong>Free Trial:</strong> As a courtesy we have enrolled you into a 14-day free trial to explore all premium features - no credit card required. You can upgrade or stop using Comovis at any time.
            </Text>
            <Text style={{ color: 'black', fontSize: '16px', lineHeight: '24px' }}>
              <strong>Small Ask:</strong> We kindly ask that you share your feedback frequently, we take feedback seriously as we strive to create a truly useful and impactful product, with your direct input. Let's build Comovis together.
            </Text>
          </Section>
          <Section style={{ marginTop: '20px', marginBottom: '32px', textAlign: 'center' }}>
            <a 
              href="https://example.com/dashboard" 
              style={{
                backgroundColor: '#000000', 
                borderRadius: '5px', 
                color: 'white', 
                fontSize: '12px', 
                fontWeight: 'bold', 
                padding: '12px 20px',
                textDecoration: 'none',
                margin: '10px auto',
                display: 'inline-block'
              }}
            >
              Get started
            </a>
          </Section>
          <Hr style={{ border: '1px solid #eaeaea', margin: '26px 0', width: '100%' }} />
          <Text style={{ color: '#666666', fontSize: '12px', lineHeight: '24px' }}>
            For any support or questions, reach out to us at{' '}
            <Link href={`mailto:support@comovis.ai`} style={{ color: 'blue', textDecoration: 'none' }}>
              support@comovis.ai
            </Link>
          </Text>
          <Text style={{ color: '#666666', fontSize: '12px', lineHeight: '24px' }}>
            Please do not reply back to this email as it is not monitored.
          </Text>
          <Text style={{ color: '#666666', fontSize: '12px', lineHeight: '24px' }}>
            Comovis Analytics, London, United Kingdom
          </Text>
        </Container>
      </Body>
    </Html>
  );
};
