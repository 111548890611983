import React, { createContext, useState, useContext, useRef, useEffect } from 'react';
import { UserContext } from './UserContext';
import { SessionClearedToast } from '../../ReusableComponents/Toast/ToastMessages';

export const MapSessionContext = createContext();

export function MapSessionProvider({ children }) {
    const storedSessionId = sessionStorage.getItem('mapSessionId');
    const storedIMO = sessionStorage.getItem('imo');
    const storedMMSI = sessionStorage.getItem('mmsi');
    const storedLat = sessionStorage.getItem('lat');
    const storedLon = sessionStorage.getItem('lon');
    const storedName = sessionStorage.getItem('name');
    const storedTypeSpecific = sessionStorage.getItem('type_specific');
    const storedDepPortCoords = JSON.parse(sessionStorage.getItem('depPortCoords'));
    const storedDestPortCoords = JSON.parse(sessionStorage.getItem('destPortCoords'));
    const storedTrackHistory = JSON.parse(sessionStorage.getItem('trackHistory'));
    const storedCourse = sessionStorage.getItem('course');
    const storedHeading = sessionStorage.getItem('heading');

    const [mapSessionId, setMapSessionId] = useState(storedSessionId);
    const [imo, setIMO] = useState(storedIMO);
    const [mmsi, setMMSI] = useState(storedMMSI);
    const [lat, setLat] = useState(storedLat ? parseFloat(storedLat) : null);
    const [lon, setLon] = useState(storedLon ? parseFloat(storedLon) : null);
    const [name, setName] = useState(storedName || null);
    const [typeSpecific, setTypeSpecific] = useState(storedTypeSpecific || null);
    const [depPortCoords, setDepPortCoords] = useState(storedDepPortCoords || null);
    const [destPortCoords, setDestPortCoords] = useState(storedDestPortCoords || null);
    const [trackHistory, setTrackHistory] = useState(storedTrackHistory || []);
    const [course, setCourse] = useState(storedCourse ? parseFloat(storedCourse) : null);
    const [heading, setHeading] = useState(storedHeading ? parseFloat(storedHeading) : null);
    const [messages, setMessages] = useState(() => {
        const storedMessages = sessionStorage.getItem('mapMessages');
        return storedMessages ? JSON.parse(storedMessages) : [];
    });

    const setMapMessages = (newMessages) => {
        sessionStorage.setItem('mapMessages', JSON.stringify(newMessages));
        setMessages(newMessages);
    };

    const { accessToken, session } = useContext(UserContext);
    const isSessionCreating = useRef(false);
    const apiUrl = process.env.REACT_APP_API_URL;

    const startMapSession = async () => {
        if (isSessionCreating.current) {
            return null;
        }

        if (!accessToken || !session || !session.user || !session.user.id) {
            console.error("Access token or user ID is missing.");
            return null;
        }

        const userId = session.user.id;
        isSessionCreating.current = true;

        try {
            const response = await fetch(`${apiUrl}/start-map-session`, {
                method: 'POST',
                headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${accessToken}` },
                body: JSON.stringify({ userId })
            });
            const data = await response.json();

            if (response.ok && data.session && data.session.id) {
                setMapSessionId(data.session.id);
                sessionStorage.setItem('mapSessionId', data.session.id);
                //console.log("Map session created successfully with ID:", data.session.id);
                return data.session.id;
            } else {
                throw new Error(data.error || 'Failed to start map session');
            }
        } catch (error) {
            console.error("Error starting map session:", error);
            return null;
        } finally {
            isSessionCreating.current = false;
        }
    };


    const updateMapSession = async (sessionId) => {
    // Check if the accessToken and necessary user details are available
    if (!accessToken || !session || !session.user || !session.user.id) {
        console.error("Access token or user ID is missing.");
        return; // Exiting the function if the check fails
    }

    // Prepare the data to be sent to the server
    const requestData = {
        sessionId,
        vessel_name: name, // Ensure this matches the backend field name
        imo,
        mmsi
    };

    //console.log("Sending update request with data:", requestData);

    try {
        // Sending the HTTP request to the server with the accessToken
        const response = await fetch(`${apiUrl}/update-map-session`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${accessToken}`
            },
            body: JSON.stringify(requestData)
        });
        const data = await response.json();

        //console.log("Received response from backend for update:", data);

        // Check if the server responded with a success status and session ID
        if (response.ok && data.session && data.session.id) {
          
            return data.session.id;
        } else {
            // If the server response is not ok, throw an error with the server provided message
            throw new Error(data.error || 'Failed to update map session');
        }
    } catch (error) {
        console.error("Error updating map session:", error);
    }
};


    const clearMapSession = () => {
        sessionStorage.removeItem('mapSessionId');
        sessionStorage.removeItem('mapMessages');
        sessionStorage.removeItem('imo');
        sessionStorage.removeItem('mmsi');
        sessionStorage.removeItem('lat');
        sessionStorage.removeItem('lon');
        sessionStorage.removeItem('name');
        sessionStorage.removeItem('type_specific');
        sessionStorage.removeItem('depPortCoords');
        sessionStorage.removeItem('destPortCoords');
        sessionStorage.removeItem('trackHistory');
        sessionStorage.removeItem('course');
        sessionStorage.removeItem('heading');

        setMapSessionId(null);
        setMessages([]);
        setIMO(null);
        setMMSI(null);
        setLat(null);
        setLon(null);
        setName(null);
        setTypeSpecific(null);
        setDepPortCoords(null);
        setDestPortCoords(null);
        setTrackHistory([]);
        setCourse(null);
        setHeading(null);
        SessionClearedToast();
    };

    const updateLat = (latitude) => {
        sessionStorage.setItem('lat', latitude);
        setLat(latitude);
    };

    const updateLon = (longitude) => {
        sessionStorage.setItem('lon', longitude);
        setLon(longitude);
    };

    const updateName = (vesselName) => {
        sessionStorage.setItem('name', vesselName);
        setName(vesselName);
    };

    const updateTypeSpecific = (vesselTypeSpecific) => {
        sessionStorage.setItem('type_specific', vesselTypeSpecific);
        setTypeSpecific(vesselTypeSpecific);
    };

    const updateDepPortCoords = (coords) => {
        sessionStorage.setItem('depPortCoords', JSON.stringify(coords));
        setDepPortCoords(coords);
    };

    const updateDestPortCoords = (coords) => {
        sessionStorage.setItem('destPortCoords', JSON.stringify(coords));
        setDestPortCoords(coords);
    };

    const updateTrackHistory = (history) => {
        sessionStorage.setItem('trackHistory', JSON.stringify(history));
        setTrackHistory(history);
    };

    const updateCourse = (course) => {
        sessionStorage.setItem('course', course);
        setCourse(course);
    };

    const updateHeading = (heading) => {
        sessionStorage.setItem('heading', heading);
        setHeading(heading);
    };

    const updateIMO = (imo) => {
        sessionStorage.setItem('imo', imo);
        setIMO(imo);
    };

    const updateMMSI = (mmsi) => {
        sessionStorage.setItem('mmsi', mmsi);
        setMMSI(mmsi);
    };

    useEffect(() => {
        if (mapSessionId) {
            updateMapSession(mapSessionId);
        }
    }, [lat, lon, typeSpecific, depPortCoords, destPortCoords, course, heading, imo, mmsi, name]);

    return (
        <MapSessionContext.Provider value={{
            mapSessionId,
            messages,
            imo,
            mmsi,
            lat,
            lon,
            name,
            typeSpecific,
            depPortCoords,
            destPortCoords,
            trackHistory,
            course,
            heading,
            startMapSession,
            clearMapSession,
            setMessages,
            setMapSessionId,
            setMapMessages,
            setIMO,
            setMMSI,
            updateLat,
            updateLon,
            updateName,
            updateTypeSpecific,
            updateDepPortCoords,
            updateDestPortCoords,
            updateTrackHistory,
            updateCourse,
            updateHeading,
            updateIMO,
            updateMMSI
        }}>
            {children}
        </MapSessionContext.Provider>
    );
}
