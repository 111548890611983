import mixpanel from 'mixpanel-browser';

mixpanel.init('72a153aac50ae13f873ba52341eaf097');

export const trackEvent = (eventName, properties) => {
    try {
        mixpanel.track(eventName, properties);
    } catch (error) {
        console.error('Failed to track event in Mixpanel:', error);
    }
};

export const identifyUser = (userId) => {
    mixpanel.identify(userId);
};

export const setUserProfile = (properties) => {
    mixpanel.people.set(properties);
};

export default mixpanel;
