import React, { useEffect, useState } from 'react';
import './AccountStatusWidget.css';

const AccountStatusFields = ({ lastPayment, dateJoined }) => {
  const [accountType, setAccountType] = useState('');
  const [onboardingComplete, setOnboardingComplete] = useState(false);

  useEffect(() => {
    // Fetch account type and onboarding status from local storage
    const storedAccountType = localStorage.getItem('userType');
    const storedOnboardingComplete = localStorage.getItem('onboardingComplete') === 'true';

    if (storedAccountType) {
      // Capitalize the first letter of the accountType
      const capitalizedAccountType = storedAccountType.charAt(0).toUpperCase() + storedAccountType.slice(1).toLowerCase();
      setAccountType(capitalizedAccountType);
    }

    setOnboardingComplete(storedOnboardingComplete);
  }, []);

  return (
    <div className="account-status-fields">
      <div className="status-field">
        <label>Account Type:</label>
        <span>{accountType}</span>
      </div>
      <div className="status-field">
        <label>Onboarding Status:</label>
        <span>{onboardingComplete ? 'Complete' : 'Incomplete'}</span>
      </div>
      <div className="status-field">
        <label>Last Payment:</label>
        <span>{lastPayment}</span>
      </div>
    
    </div>
  );
};

export default AccountStatusFields;
