import React from 'react';
import ReactLoading from 'react-loading';

const LoadingComponent = ({ loading, size = 20 }) => {
    return (
        loading ? (
            <div className="loading-container" style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                height: '100vh', // Full page height
                position: 'fixed',
                width: '100%',
                top: 0,
                left: 0,
                backgroundColor: 'rgba(255, 255, 255)', 
                zIndex: 990099 // Ensure it's on top of everything
            }}>
                <ReactLoading type="spin" color="#000000" height={size} width={size} />
            </div>
        ) : null
    );
};

export default LoadingComponent;
