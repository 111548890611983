import React, { useContext } from "react";
import { supabase } from '../../Auth/SupabaseAuth/SupabaseClient';
import { SignupErrorToast } from '../../ReusableComponents/Toast/ToastMessages';
import { UserContext } from '../../Auth/UserAuth/UserContext';
import GoogleIcon from '../../ReusableComponents/Icons/Google.svg';  // Import the Google icon
import './GoogleSignUpStyles.css';

export default function GoogleSignUpButton() {
    const { setSession } = useContext(UserContext);

    const handleGoogleSignin = async () => {
        const { error } = await supabase.auth.signInWithOAuth({
            provider: 'google',
            options: {
                redirectTo: window.location.origin + '/dashboard'
            }
        });

        if (error) {
            console.error("Error during Google sign in:", error);
            SignupErrorToast('Signup error');
        }
    };

    return (
        <div className="google-signup-wrapper">
            <div className="divider">
                <span>or</span>
            </div>
            <div className="google-signup-button" onClick={handleGoogleSignin}>
                <img src={GoogleIcon} alt="Google Icon" className="google-icon" />
                <span>Sign up with Google</span>
            </div>
        </div>
    );
}
