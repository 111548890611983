import React from 'react';
import ReactPanZoom from 'react-image-pan-zoom-rotate';
import './ImageViewer.css';

const ImageViewerComponent = ({ documentState }) => {
    // Check if documentState and documentState.url are defined
    const imageUrl = documentState?.url;

    if (!imageUrl) {
        return (
            <div className="image-viewer">
                <p>No document available to display.</p>
            </div>
        );
    }

    return (
        <div className="image-viewer">
            <ReactPanZoom
                image={imageUrl}
                alt="Document"
                width="100%"
                height="auto"
                enablePan={true}
                enableZoom={true}
                enableRotate={true}
            />
        </div>
    );
};

export default ImageViewerComponent;
